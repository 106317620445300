import { loadTranslations } from 'react-redux-i18n'
import { config } from './config'
import { push } from 'react-router-redux'
import { fetchJson, fetchFullJson, _moment, IEServiceError } from '@optum-wvie/dynamic-ui-shared/src/utils'
import { CATEGORIES, ENTITLEMENTS } from '@optum-wvie/dynamic-ui-shared/src/entitlements'
import { get, pick, isNil, find, merge, throttle, some, isPlainObject } from 'lodash'
import SessionStorage from '@optum-wvie/dynamic-ui-framework/src/sessionStorage'
import { trackAPICall } from './utils'
import {sanitizeInput} from './utils/GenericUtils'

const jwt_decode = require('jwt-decode')
const _ = { get, pick, isNil, find, merge, throttle, some, isPlainObject }

const authorizationTokenUrl = config['authorizationTokenEndpoint']
const authorizationUserinfoUrl = config['authorizationUserinfoEndpoint']
const refreshTokenUrl = config['refreshTokenEndpoint']
const revokeTokenUrl = config['revokeTokenEndpoint']
const fetchRolesAndEntitlements = config['rolesAndEntitlements']
const defaultUuid = config['defaultUuid']

//Call refresh API no more than once per five minutes.
const REFRESH_AUTH_FREQUENCY_MS = 5 * 60000

const Sfdc = (global as any).Sfdc
let accessToken
export function getStaticTranslations(locales): any {
  return dispatch => {
    const endpoints = [
      ...locales.map(locale => {
        return (config['SharedStaticTranslation'] + '.' + locale).replace(
          '{version}',
          '1.0'
        )
      })
    ]
    const apiRequests = endpoints.map(endpoint => {
      return fetchJson(endpoint)
        .then(json => {
          return json
        })
        .catch(error => {
          console.error(
            'getTranslations failed at endpoint %s due to error:',endpoint,
            error
          )
        })
    })
    Promise.all(apiRequests).then(results => {
      const translationsObject = {}
      for (let i = 0; i < locales.length; ++i) {
        translationsObject[locales[i]] = _.merge({}, results[i])
      }
      dispatch(loadTranslations(translationsObject))
    })
  }
}

export function loginUser(code, state, redirectUri) {
  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    if (process.env.NODE_ENV === 'production') {
      return validateToken(dispatch, code, state, redirectUri)
    } else {
      if (config.useGovId) {
        return validateToken(dispatch, code, state, redirectUri)
      } else {
        //fakeLoginUser()(dispatch)
        fakeLoginUser('CCSupervisor')(dispatch)
      }
    }
  }
}

function validateToken(dispatch: any, code: any, state: any, redirectUri: any) {
  dispatch(requestLogin(code))
  const tokenEndpoint =
    authorizationTokenUrl.replace('{authorizationCode}', code) +
    `&state=${state}&redirectUri=${encodeURIComponent(redirectUri)}`
  const tokenRequest = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      portalName: config.portalName,
      tenantCode: config.tenant.code
    }
  }
  let beforeMillis = new Date().valueOf()
  return fetchFullJson(tokenEndpoint, tokenRequest, 0, 60000)
    .then(tokenResponse => {
      let durationMillis = new Date().valueOf() - beforeMillis
      if (_.isPlainObject(tokenResponse) && tokenResponse.businessCorrelationId) {
        trackAPICall(tokenEndpoint, tokenRequest, tokenResponse.businessCorrelationId, true, durationMillis)
      } else {
        trackAPICall(tokenEndpoint, tokenRequest, null, true, durationMillis)
      }

      //Decode and parse the JWT
      const idToken = tokenResponse.data.id_token
      const accessToken = tokenResponse.data.access_token
      const jwt = jwt_decode(idToken)
      let userAccount = {
        uuid: jwt['sub'] || null,
        userId: jwt['preferred_username'] || null,
        firstName: jwt['given_name'] || null,
        lastName: jwt['family_name'] || null,
        dob: jwt['birthdate'] || null,
        email: jwt['email'] || null,
        defaultUserRoleId: null
      }

      const timeout = (tokenResponse.data.expires_in * 1000) - 5000 //Subtracting 5 seconds to account for delay since Redis cache TTL was set
      const expiry = _moment(config).valueOf() + timeout

      // Calling Update User Account
      const userInfoEndpoint = authorizationUserinfoUrl
      const userInfoRequest = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: jwt['sub'],
          portalName: config.portalName,
          Authorization: 'Bearer ' + accessToken,
          tenantCode: config.tenant.code
        },
        mode: 'cors'
      }
      beforeMillis = new Date().valueOf()
      fetchFullJson(userInfoEndpoint, userInfoRequest, 0, 60000)
        .then(userInfoResponse => {
          durationMillis = new Date().valueOf() - beforeMillis            
          if (_.isPlainObject(userInfoResponse) && userInfoResponse.businessCorrelationId) {
            trackAPICall(userInfoEndpoint, userInfoRequest, userInfoResponse.businessCorrelationId, true, durationMillis)
          } else {
            trackAPICall(userInfoEndpoint, userInfoRequest, null, true, durationMillis)
          }
          
          userAccount = _.get(userInfoResponse, 'data.userInformation', {})
          dispatch(
            setupUserAccess(
              _.get(userInfoResponse, 'data.userRoles', []),
              _.get(userInfoResponse, 'data.inactiveUserRoles', []),
              userAccount
            )
          )
          return true
        })
        .catch(ex => {
          durationMillis = new Date().valueOf() - beforeMillis      
          console.error('loginUser update account action failed with ex', ex)
          if (ex instanceof IEServiceError) {
            const { businessCorrelationId } = ex as any
            trackAPICall(userInfoEndpoint, userInfoRequest, businessCorrelationId, false, durationMillis)
          } else {
            trackAPICall(userInfoEndpoint, userInfoRequest, null, false, durationMillis)
          }
        })
      let body = {
        fName: userAccount.firstName,
        lName: userAccount.lastName,
        userId: userAccount.userId,
        uuid: userAccount.uuid
      }
      //Dispatch the success action
      dispatch(receiveLogin(userAccount, accessToken, expiry, timeout))
      return true
    })
    .catch(err => {
      console.error('loginUser action failed with ex', err)
      dispatch(loginError(err.message))
      let durationMillis = new Date().valueOf() - beforeMillis
      if (err instanceof IEServiceError) {
        const { businessCorrelationId } = err as any
        trackAPICall(tokenEndpoint, tokenRequest, businessCorrelationId, false, durationMillis)
      } else {
        trackAPICall(tokenEndpoint, tokenRequest, null, false, durationMillis)
      }
      return Promise.reject(err.message)
    })
}


export function setCanvasRequest(canvasRequest) {
  return dispatch => {
    dispatch({
      type: 'SET_CANVAS_REQUEST',
      context: canvasRequest.context,
      client: canvasRequest.client
    })
  } 
}
 

export function fakeLoginUser(activeUserRole?) {

  return dispatch => {
    const userAccount = config.openId_localhostAccount
    dispatch(fakeLogin())
    dispatch(
      setupUserAccess(
        fakeUserRoles[activeUserRole ? activeUserRole : 'administrator'],
        fakeInactiveRoles['administrator'],
        userAccount
      )
    )
  }
}

export function showErrorModal(errorDesc) {
  return {
    type: 'SHOW_ERROR_MODAL',
    errorDesc
  }
}

export function requestLogin(code) {
  return {
    type: 'LOGIN_REQUEST',
    isFetching: true,
    isAuthenticated: false,
    code
  }
}

export function receiveLogin(userAccount, accessToken, expiry, timeout) {
  return {
    type: 'LOGIN_SUCCESS',
    isFetching: false,
    isAuthenticated: true,
    userAccount,
    accessToken,
    expiry,
    timeout
  }
}

const refreshAuthService = _.throttle(
  (dispatch, uuid, accessToken, skipNextRefresh, fetcher, isInitial) => {
    if (skipNextRefresh) {
      dispatch({
        type: 'CLEAR_SKIP_NEXT_REFRESH'
      })
    } else {
      if (isInitial) {
        dispatch({
          type: 'LOGIN_REFRESH_INITIAL'
        })
      }
      fetcher(refreshTokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + accessToken,
          portalName: config.portalName,
          uuid,
          tenantCode: config.tenant.code
        },
        body: '{}'
      })
        .then(json => {
          console.log('refreshAuthService response:', json)
          const idToken = json.id_token
          const accessToken = json.access_token
          const timeout = (json.expires_in * 1000) - 5000 //Subtracting 5 seconds to account for delay since Redis cache TTL was set
          const expiry = _moment(config).valueOf() + timeout
          dispatch({
            type: 'LOGIN_REFRESH',
            idToken,
            accessToken,
            expiry,
            timeout
          })
          if (
            Sfdc &&
            Sfdc.canvas &&
            Sfdc.canvas.client &&
            Sfdc.canvas.client.refreshSignedRequest
          ) {
            Sfdc.canvas.client.refreshSignedRequest(data => {
              if (data.status === 200) {
                const signedRequest = data.payload.response
                const part = signedRequest.split('.')[1]
                const obj = JSON.parse(Sfdc.canvas.decode(part))
                console.log(
                  'Sfdc refreshSignedRequest received signedRequest:',
                  signedRequest,
                  'part',
                  part,
                  'obj',
                  obj
                )
              } else {
                console.error(
                  'Sfdc refreshSignedRequest received error response:',
                  data
                )
              }
            })
          } else {
            console.error('Sfdc refreshSignedRequest method not found')
          }
        })
        .catch(err => console.error('refreshAuthService failed with ex', err))
    }
  },
  REFRESH_AUTH_FREQUENCY_MS
)

const refreshLocalhost = _.throttle((dispatch, skipNextRefresh, isInitial) => {
  if (skipNextRefresh) {
    dispatch({
      type: 'CLEAR_SKIP_NEXT_REFRESH'
    })
  } else {
    if (isInitial) {
      dispatch({
        type: 'LOGIN_REFRESH_INITIAL'
      })
    }
    dispatch({
      type: 'LOGIN_REFRESH_LOCAL',
      expiry: _moment(config).valueOf() + config.defaultExpiryMs,
      timeout: config.defaultExpiryMs
    })
  }
}, REFRESH_AUTH_FREQUENCY_MS)

export function loginRefresh(uuid, accessToken, skipNextRefresh, fetcher, isInitial) {
  return dispatch => {
    if (process.env.NODE_ENV === 'production') {
      refreshAuthService(
        dispatch,
        uuid,
        accessToken,
        skipNextRefresh,
        fetcher,
        isInitial
      )
    } else {
      refreshLocalhost(dispatch, skipNextRefresh, isInitial)
    }
  }
}

export function loginError(message) {
  return {
    type: 'LOGIN_FAILURE',
    message
  }
}

export function logoutSuccess() {
  return {
    type: 'LOGOUT_SUCCESS'
  }
}

export function doLogoutRedirect() {
  if (process.env.NODE_ENV === 'production' && typeof config.logoutRedirectUrl === 'string') {
    let sanitizedInput = sanitizeInput(config.logoutRedirectUrl)
    window.location.href = sanitizedInput
  }
}

export function logoutUser(environment, tenantCode, skipRedirect) {
  return dispatch => {
    //TODO: The endpoint should only revoke if the user is not active in Salesforce.
    const stateStorageKey = 'FA-' + environment + '-BR-WV-STATE';
    SessionStorage.removeItem(stateStorageKey);
    dispatch(logoutSuccess())
    if (!skipRedirect) {
      doLogoutRedirect()
    }
    /*
    fetchJson(revokeTokenUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken,
        portalName: config.portalName,
        uuid,
        tenantCode: config.tenant.code
      },
      body: '{}'
    }).then(json => {})
    */
  }
}

export function redirect(path) {
  return dispatch => {
    dispatch(push(path))
  }
}

export function fakeLogin() {
  return {
    type: 'LOGIN_SUCCESS',
    isFetching: false,
    isAuthenticated: true,
    userAccount: config.openId_localhostAccount,
    expiry: _moment(config).valueOf() + config.defaultExpiryMs,
    timeout: config.defaultExpiryMs
  }
}

export function updateUserRoles(userRoles, inactiveUserRoles, userAccount) {
  return {
    type: 'UPDATE_ACCESS_ROLES',
    userRoles,
    inactiveUserRoles,
    userAccount
  }
}

export function setupUserAccess(
  activeUserRoles = [],
  inactiveUserRoles = [],
  userAccount
) {
  return dispatch => {
    if (Array.isArray(activeUserRoles) && activeUserRoles.length > 0) {
      // setup UserRoles

      const userRoleMapper = rawRole => {
        const entitlementPaths = []
        for (let i = 0; i < _.get(rawRole, 'entitlements', []).length; ++i) {
          entitlementPaths.push('entitlements[' + i + '].entitlementId')
          entitlementPaths.push('entitlements[' + i + '].entitlementName')
          entitlementPaths.push(
            'entitlements[' + i + '].entitlementDescription'
          )
        }
        return _.pick(
          rawRole,
          'userRoleId',
          'uuid',
          'dismissInactiveNotifyFlag',
          'adjustMethod',
          'role.roleId',
          'role.roleName',
          'role.multilingualDescription',
          'role.multilingualDisplayName',
          'role.status',
          'organization.orgId',
          'organization.orgNm',
          'organization.orgType',
          'organization.countyId',
          'organization.multilingualDisplayName',
          'organization.startDate',
          'organization.endDate',
          'organization.benefitProgramGroupId',
          ...entitlementPaths
        )
      }

      let mappedUserRoles = activeUserRoles.map(userRoleMapper)
      let mappedInactiveUserRoles = inactiveUserRoles.map(userRoleMapper)
      dispatch(updateUserRoles(mappedUserRoles, mappedInactiveUserRoles, userAccount))
    } else {
      dispatch(updateUserRoles([], [], userAccount))
    }
  }
}

export function workPrograms_Actvities(activities) {
  return {
    type: 'WP_Activites',
    activities
  }
}

export function showLoader(val: boolean){
  return {
    type: 'SHOW_LOADER',
    val
  }
}
export function setEnrollmentId(enrollmentId) {
  return {
    type: 'ENROLLMENT_ID',
    enrollmentId
  }
}

export function setEnrollmentDateProgramCode(enrollmentDateProgramCode) {
  return {
    type: 'EnrollmentDateProgramCode',
    enrollmentDateProgramCode
  }
}
export function setEntitlements(entitlement) {
  return {
    type: "GetEntitlement",
    entitlement
  }
}

export function countiesList(counties) {
  return {
    type: 'counties_List',
    counties
  }
}

export function wpRoleValues(roles) {
  return {
    type: 'wp_Roles',
    roles
  }
}

export function setCountyId(countyId) {
  return {
    type: 'wp_SetCountyID',
    countyId
  }
}
export function setOrgId(orgId) {
  return {
    type: 'wp_SetOrgId',
    orgId
  }
}

export function setRoleName(roleName) {
  return {
    type: 'WP_ROLE_NAME',
    roleName
  }
}

export function saveMangeEmployerSites(formData, formContext) {
  return {
    type: 'saveMangeEmployerSites',
    formData,
    formContext
  }
}

export function clientAddress(address) {
  return {
    type: 'Address',
    address
  }
}

export function setUuidCanvasTest(uuid: string) {
  if (!config.isProd) {
    return {
      type: 'SET_UUID_CANVAS_TEST',
      uuid
    }
  }
}

export function updatedActivitesRequest(requestBody) {
  return {
    type: 'UPDATE_ACTIVITES_REQUEST',
    activitesRequest: requestBody
  }
}

const caseMangerRole = [
  {
      "userRoleId": 3587,
      "uuid": "63e5f372-2aa9-4394-81c6-8b814bd0ec70",
      "dismissInactiveNotifyFlag": "N",
      "adjustMethod": null,
      "role": {
          "roleId": 89,
          "roleName": "Case Manager",
          "multilingualDescription": {
              "en": "WV Works worker manage the cases of TANF recipients. They guide customers towards self sufficiency through access to job skills, education, other resources that aid customers in finding employment. Works worker also determine eligibility and complete case maintenance activities for SNAP, medicaid, emergency assistance, LIEAP and SCA."
          },
          "multilingualDisplayName": {
              "en": "Case Manager"
          },
          "status": "Active"
      },
      "organization": {
          "orgId": 3215,
          "orgNm": "WPWVWorksWayneWorkerUnit",
          "orgType": {
              "category": "ORGANIZATION_TYPE",
              "subCategory": "FA",
              "languageCode": "en",
              "key": "ORFAWP92206",
              "value": "Worker Unit WP FA",
              "rulesEngineCode": "",
              "sortOrder": 35
          },
          "countyId": [
              48050
          ],
          "multilingualDisplayName": {
              "en": "WPWVWorksWayneWorkerUnit"
          },
          "startDate": "2021-05-20T15:47:35.147Z",
          "endDate": null
      },
      "entitlements": [
          {
              "entitlementId": 114,
              "entitlementName": "AP_SF_APP",
              "entitlementDescription": "Allows a role entry into IE Agency Portal within Salesforce"
          },
          {
              "entitlementId": 102,
              "entitlementName": "AP_SF_VIEW_WORK_PROGRAMS",
              "entitlementDescription": "Allows the role to Work Programs tabs"
          },
          {
              "entitlementId": 74,
              "entitlementName": "AP_SF_VIEW_STATIC_OBJECTS",
              "entitlementDescription": "Allows the user to see the MCR values in the drop down in Salesforce AP"
          },
          {
              "entitlementId": 126,
              "entitlementName": "AP_SF_VIEW_WP_INDIVIDUAL_NOTE",
              "entitlementDescription": "Allows the role to access Individual Note"
          },
          {
              "entitlementId": 319,
              "entitlementName": "AP_WP_CORR_PYMT_R",
              "entitlementDescription": "Allows the role to view the corrective payment screen."
          },
          {
              "entitlementId": 320,
              "entitlementName": "AP_WP_CORR_PYMT_RW",
              "entitlementDescription": "Allows the role to view and edit the corrective payment screen."
          },
          {
              "entitlementId": 313,
              "entitlementName": "AP_WP_MNG_PYMT_R",
              "entitlementDescription": "Allows the role to view the manage payment screen."
          },
          {
              "entitlementId": 314,
              "entitlementName": "AP_WP_MNG_PYMT_RW",
              "entitlementDescription": "Allows the role to view and edit the manage payment screen."
          },
          {
              "entitlementId": 317,
              "entitlementName": "AP_WP_SUP_SRV_PYMT_R",
              "entitlementDescription": "Allows the role to view the support service payment screen."
          },
          {
              "entitlementId": 318,
              "entitlementName": "AP_WP_SUP_SRV_PYMT_RW",
              "entitlementDescription": "Allows the role to view and edit the support service payment screen."
          },
          {
              "entitlementId": 81,
              "entitlementName": "AP_WRITE_CL_WORKASSIGNMENTRULESANDTRANSFERS",
              "entitlementDescription": "Allows the role to receive workload from assignment rules and transfers that target the user's organization"
          },
          {
              "entitlementId": 988,
              "entitlementName": "AP_SF_READ_WP_WVWORKS_WORKER",
              "entitlementDescription": "Allows the user to access WV Works functionality within work programs"
          },
          {
              "entitlementId": 984,
              "entitlementName": "AP_SF_WRITE_WP_WVWORKS_WORKER",
              "entitlementDescription": "Allows the user to access WV Works functionality within work programs"
          },
          {
              "entitlementId": 996,
              "entitlementName": "AP_CL_PINTRANSFER_TAB_R",
              "entitlementDescription": "Allows the role to access and read pin transfer"
          },
          {
              "entitlementId": 342,
              "entitlementName": "AP_CL_PINTRANSFER_TAB_RW",
              "entitlementDescription": "Allows the role to access and read pin transfer"
          },
          {
              "entitlementId": 125,
              "entitlementName": "AP_SF_VIEW_WP_SNAP_NOTE",
              "entitlementDescription": "Allows the role to access Log SNAP E&T Note"
          },
          {
              "entitlementId": 1028,
              "entitlementName": "AP_WP_DOC_RW",
              "entitlementDescription": "Allows the role to view and edit the Document Management tab"
          }
      ]
  }
]

const fakeUserRoles = {
  administrator: [
    {
      userRoleId: 1001,
      startDate: '2018-05-04T19:13:57.860Z',
      endDate: null,
      createdDate: '2018-05-04T19:13:57.860Z',
      createdUser: null,
      lastUpdateDate: null,
      lastUpdateUser: null,
      uuid: '98887fa6-0009-4166-85c4-43c93d91bf91', // tester6mntst: 50129b61-3217-4d62-9a56-44dec9863677
      role: {
        roleId: 1,
        roleName: 'Administrator',
        roleDescription: 'This is the description for Administrator',
        displayName: 'Administrator',
        lastUpdateUser: null,
        tenantId: config.tenant.id,
        status: 'Active',
        startDate: '2018-05-03T15:19:41.320Z',
        endDate: null,
        createdDate: null,
        lastUpdateDate: null
      },
      organization: {
        countyId: [23002, 23017], // 23002 for Anoka county, 23017 for Cottonwood
        orgId: 3092,
        orgNm: 'MN Supervisor Unit FA',
        orgType: {
          category: 'ORGANIZATION_TYPE',
          subCategory: 'FA',
          languageCode: 'en',
          key: 'ORFASU92194',
          value: 'Supervisor Unit FA',
          rulesEngineCode: '',
          sortOrder: 9999
        }
      },
      entitlements: [
        {
          entitlementId: 1,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_USERS,
          entitlementDescription: null
        },
        {
          entitlementId: 2,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_USERS,
          entitlementDescription: null
        },
        {
          entitlementId: 3,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ROLES,
          entitlementDescription: null
        },
        {
          entitlementId: 4,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ROLES,
          entitlementDescription: null
        },
        {
          entitlementId: 5,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ENTITLEMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 6,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ENTITLEMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 7,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ORGANIZATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 8,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ORGANIZATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 9,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_INVITATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 10,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_INVITATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 11,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_EXPORT,
          entitlementDescription: null
        },
        {
          entitlementId: 12,
          entitlementName:
            ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESELECTCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 13,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESKIPCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 14,
          entitlementName: CATEGORIES.ADMIN_PORTAL_ACCESS_MANAGEMENT,
          entitlementDescription: null
        },
        {
          entitlementId: 15,
          entitlementName: ENTITLEMENTS.EDIT_CONFIDENTIAL_FLAG,
          entitlementDescription: null
        },
        {
          entitlementId: 16,
          entitlementName: ENTITLEMENTS.ADMIN_READ_CL_WORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 17,
          entitlementName:
            ENTITLEMENTS.ADMIN_WRITE_CL_SUPERVISEWORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 18,
          entitlementName:
            ENTITLEMENTS.AP_WRITE_CL_WORKASSIGNMENTRULESANDTRANSFERS,
          entitlementDescription: null
        },
        {
          entitlementId: 19,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_WORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 20,
          entitlementName:
            ENTITLEMENTS.ADMIN_WRITE_CL_COUNTYWORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 21,
          entitlementName: ENTITLEMENTS.AP_SF_VIEW_CL_ORGASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 22,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_OUT_OF_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 22,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_IN_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 23,
          entitlementName: ENTITLEMENTS.AP_READ_ALL_ORGS,
          entitlementDescription: null
        },
        {
          entitlementId: 24,
          entitlementName: ENTITLEMENTS.AP_EDIT_MY_ORGS,
          entitlementDescription: null
        },
        {
          entitlementId: 14,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_INQ,
          entitlementDescription: null
        },
        {
          entitlementId: 15,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_CREATE,
          entitlementDescription: null
        },
        {
          entitlementId: 16,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_APPRV,
          entitlementDescription: null
        },
        {
          entitlementId: 25,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_IN_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 26,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_OUT_OF_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 27,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_APPRV,
          entitlementDescription: null
        },
        {
          entitlementId: 28,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_CREATE,
          entitlementDescription: null
        },
        {
          entitlementId: 29,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_INQ,
          entitlementDescription: null
        },
        {
          entitlementId: 30,
          entitlementName: ENTITLEMENTS.AP_SF_VIEW_CL_TRANSFERS,
          entitlementDescription: null
        },
        {
          entitlementId: 31,
          entitlementName: ENTITLEMENTS.AP_WRITE_CL_OUTOFCOUNTYMASSTRANSFER,
          entitlementDescription: null
        },
        {
          entitlementId: 31,
          entitlementName: ENTITLEMENTS.AP_CHILD_CARE_ADDITIONAL_INFO_WRITE,
          entitlementDescription: null
        },
        {
          entitlementId: 32,
          entitlementName: ENTITLEMENTS.AP_CHILD_CARE_ADDITIONAL_INFO_SUPERVISE,
          entitlementDescription: null
        },
        {
          entitlementId: 33,
          entitlementName: ENTITLEMENTS.AP_READ_BI_ISSINQ_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 34,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_MANISS_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 35,
          entitlementName: ENTITLEMENTS.AP_MANAGE_VOUCHERS_RW,
          entitlementDescription: null
        },
        {
          entitlementId: 36,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_PYMTCORR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 37,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_PYMTCORR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 38,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_PYMTINF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 39,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_REFF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 40,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_INVFNDG_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 41,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_PROSINF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 42,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_CLMDTL_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 43,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_REFFSRCH_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 44,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_RCVROVR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 45,
          entitlementName: ENTITLEMENTS.AP_READ_BR_CLMPYMT_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 46,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_PYMTAGR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 47,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_REVPYMT_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 48,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_TOPINF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 49,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_TOPTRCK_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 50,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_CLMSRCH_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 51,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_RCVROVR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 52,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_CNCLISS_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 53,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_SUPVIEW_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 54,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_ELIGINFO_CONFIRMELIG_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 56,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_ELIGINFO_OVERRIDE_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 57,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_CCA_ELIGINFO_CONFIRMELIG_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 58,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_CCA_ELIGINFO_CLOSURE_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 59,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_CCA_WRK_FLD,
          entitlementDescription: null
        },
        {
          entitlementId: 60,
          entitlementName: ENTITLEMENTS.AP_READ_BI_CCA_SUPVIEW_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 71,
          entitlementName: ENTITLEMENTS.AP_READ_BI_MANISS_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 72,
          entitlementName: ENTITLEMENTS.AP_WRITE_QC_WORKSHEET_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 73,
          entitlementName: ENTITLEMENTS.AP_WRITE_QC_FACESHEET_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 74,
          entitlementName: ENTITLEMENTS.AP_WRITE_QC_CMPTNSHEET_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 75,
          entitlementName: ENTITLEMENTS.AP_WRITE_QC_DASHBOARD_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 76,
          entitlementName: ENTITLEMENTS.AP_WRITE_QC_MNGCSEREV_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 77,
          entitlementName: ENTITLEMENTS.AP_WRITE_QC_CSEVIEWSMRY_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 78,
          entitlementName: ENTITLEMENTS.AP_WRITE_QC_MNGCSEREVCMNTS_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 79,
          entitlementName: ENTITLEMENTS.AP_WRITE_QC_SMPLSELRUL_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 80,
          entitlementName: ENTITLEMENTS.AP_WRITE_QC_DATACONTROL_TAB,
          entitlementDescription: null
        }
      ]
    }
  ],
  worker: [
    {
      userRoleId: 1002,
      startDate: '2018-05-04T19:13:57.860Z',
      endDate: null,
      createdDate: '2018-05-04T19:13:57.860Z',
      createdUser: null,
      lastUpdateDate: null,
      lastUpdateUser: null,
      uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
      role: {
        roleId: 2,
        roleName: 'Worker',
        roleDescription: 'This is the description for Worker',
        displayName: 'Worker',
        lastUpdateUser: null,
        tenantId: config.tenant.id,
        status: 'Active',
        startDate: '2018-05-03T15:19:41.320Z',
        endDate: null,
        createdDate: null,
        lastUpdateDate: null
      },
      entitlements: [
        {
          entitlementId: 1,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_USERS,
          entitlementDescription: null
        },
        {
          entitlementId: 3,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ROLES,
          entitlementDescription: null
        },
        {
          entitlementId: 5,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ENTITLEMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 7,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ORGANIZATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 9,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_INVITATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 11,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_EXPORT,
          entitlementDescription: null
        },
        {
          entitlementId: 12,
          entitlementName:
            ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESELECTCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 13,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESKIPCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 14,
          entitlementName: ENTITLEMENTS.AP_READ_BI_CCA_SUPVIEW_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 15,
          entitlementName: ENTITLEMENTS.AP_READ_BI_CCA_INVCSRCH_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 16,
          entitlementName: ENTITLEMENTS.AP_READ_BI_MANISS_TAB,
          entitlementDescription: null
        }
      ]
    }
  ],
  other: [
    {
      userRoleId: 1003,
      startDate: '2018-05-04T19:13:57.860Z',
      endDate: null,
      createdDate: '2018-05-04T19:13:57.860Z',
      createdUser: null,
      lastUpdateDate: null,
      lastUpdateUser: null,
      uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
      role: {
        roleId: 3,
        roleName: 'Other',
        roleDescription: 'This is the description for Other',
        displayName: 'Other',
        lastUpdateUser: null,
        tenantId: config.tenant.id,
        status: 'Active',
        startDate: '2018-05-03T15:19:41.320Z',
        endDate: null,
        createdDate: null,
        lastUpdateDate: null
      },
      entitlements: []
    }
  ],
  superVisor: [
    {
      userRoleId: 2267,
      startDate: '2020-11-13T00:00:00.000Z',
      endDate: null,
      createdDate: '2020-11-13T17:46:33.717Z',
      trainingDate: null,
      dataAgreementDate: null,
      createdUser: '538937a4-0635-4d0c-9277-be1d03bb9d96',
      lastUpdateDate: '2021-06-28T17:59:56.363Z',
      lastUpdateUser: '50129b61-3217-4d62-9a56-44dec9863677',
      uuid: '50129b61-3217-4d62-9a56-44dec9863677',
      role: {
        roleId: 165,
        roleName: 'AP_FA_Supervisor',
        roleDescription: 'Family Assistance Supervisor',
        createdUser: 'SYSTEM',
        lastUpdateUser: 'SYSTEM',
        tenantId: 2,
        status: 'Active',
        numUsers: null,
        displayName: 'Family Assistance Supervisor',
        inactiveAfterNumber: null,
        inactiveAfterUnit: null,
        startDate: '1900-01-01T00:00:00.000Z',
        endDate: null,
        createdDate: '2020-11-04T10:07:34.370Z',
        lastUpdateDate: '2021-05-28T13:10:24.153Z',
        multilingualDescription: {
          en: 'Family Assistance Supervisor'
        },
        multilingualDisplayName: {
          en: 'Family Assistance Supervisor'
        },
        isOrgRequired: false,
        isTrainingNeeded: false,
        isDataAgreementNeeded: false
      },
      entitlements: [
        {
          entitlementId: 148,
          entitlementName: 'AP_WRITE_CLIENTDET_LIVARG_ACC',
          entitlementDescription:
            'Entitlement - Access Living Arrangement Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:33.043Z',
          lastUpdateDate: '2021-03-08T17:53:40.207Z'
        },
        {
          entitlementId: 149,
          entitlementName: 'AP_WRITE_CLIENTDET_LIVARG_ACC',
          entitlementDescription:
            'Entitlement - Access Living Arrangement Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:33.073Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 152,
          entitlementName: 'AP_WRITE_ADDLINFO_SCHLENRL_ACC',
          entitlementDescription:
            'Entitlement - Access School Enrollment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:33.260Z',
          lastUpdateDate: '2021-03-08T17:53:40.253Z'
        },
        {
          entitlementId: 153,
          entitlementName: 'AP_WRITE_ADDLINFO_SCHLENRL_ACC',
          entitlementDescription:
            'Entitlement - Access School Enrollment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:33.310Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 177,
          entitlementName: 'AP_READ_BI_ISSINQ_TAB',
          entitlementDescription:
            'Allows the role to view the Issuance Inquiry sceen',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBINSF',
            languageCode: 'en',
            key: 'ENBINS94326',
            value: 'IE Agency Portal - Benefits Issuance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:38.127Z',
          lastUpdateDate: '2021-03-03T07:25:11.877Z'
        },
        {
          entitlementId: 178,
          entitlementName: 'AP_WRITE_BI_MANISS_TAB',
          entitlementDescription:
            'Allows the role to view the Manual Issuance sceen',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBINSF',
            languageCode: 'en',
            key: 'ENBINS94326',
            value: 'IE Agency Portal - Benefits Issuance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:38.393Z',
          lastUpdateDate: '2021-03-03T07:25:11.877Z'
        },
        {
          entitlementId: 179,
          entitlementName: 'AP_WRITE_BI_CNCLISS_TAB',
          entitlementDescription:
            'Allows the role to write the Cancel Initial/Manual Issuance Request',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBINSF',
            languageCode: 'en',
            key: 'ENBINS94326',
            value: 'IE Agency Portal - Benefits Issuance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:38.673Z',
          lastUpdateDate: '2021-03-03T07:25:11.893Z'
        },
        {
          entitlementId: 180,
          entitlementName: 'AP_WRITE_BI_PYMTCORR_TAB',
          entitlementDescription:
            'Allows the role to write the Payment Correction',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBINSF',
            languageCode: 'en',
            key: 'ENBINS94326',
            value: 'IE Agency Portal - Benefits Issuance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:38.953Z',
          lastUpdateDate: '2021-03-03T07:25:11.893Z'
        },
        {
          entitlementId: 181,
          entitlementName: 'AP_WRITE_BI_MNGVOCHR_TAB',
          entitlementDescription:
            'Allows the role to write the Manage Vouchers sceen',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBINSF',
            languageCode: 'en',
            key: 'ENBINS94326',
            value: 'IE Agency Portal - Benefits Issuance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:39.237Z',
          lastUpdateDate: '2021-03-01T09:47:25.667Z'
        },
        {
          entitlementId: 182,
          entitlementName: 'AP_READ_BI_PYMTINF_TAB',
          entitlementDescription:
            'Allows the role to view the Payment Information sceen',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBINSF',
            languageCode: 'en',
            key: 'ENBINS94326',
            value: 'IE Agency Portal - Benefits Issuance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:39.517Z',
          lastUpdateDate: '2021-03-03T07:25:11.893Z'
        },
        {
          entitlementId: 183,
          entitlementName: 'AP_WRITE_BI_PYMTINF_TAB',
          entitlementDescription:
            'Allows the role to write the Payment Information screen',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBINSF',
            languageCode: 'en',
            key: 'ENBINS94326',
            value: 'IE Agency Portal - Benefits Issuance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:39.800Z',
          lastUpdateDate: '2021-03-03T07:25:11.910Z'
        },
        {
          entitlementId: 184,
          entitlementName: 'AP_READ_BR_REFFSRCH_TAB',
          entitlementDescription: 'Allows the role to view the Referral Search',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBRNSF',
            languageCode: 'en',
            key: 'ENBRNS94327',
            value: 'IE Agency Portal - Benefits Recovery - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:40.080Z',
          lastUpdateDate: '2021-03-03T07:25:11.957Z'
        },
        {
          entitlementId: 185,
          entitlementName: 'AP_WRITE_BR_REFFSRCH_TAB',
          entitlementDescription:
            'Allows the role to write the Referral Search sceen',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBRNSF',
            languageCode: 'en',
            key: 'ENBRNS94327',
            value: 'IE Agency Portal - Benefits Recovery - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:40.377Z',
          lastUpdateDate: '2021-03-03T07:25:11.957Z'
        },
        {
          entitlementId: 186,
          entitlementName: 'AP_READ_BR_REFF_TAB',
          entitlementDescription:
            'Allows the role to view the Benefit Recovery Referral sceen',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBRNSF',
            languageCode: 'en',
            key: 'ENBRNS94327',
            value: 'IE Agency Portal - Benefits Recovery - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:40.643Z',
          lastUpdateDate: '2021-03-03T07:25:11.970Z'
        },
        {
          entitlementId: 187,
          entitlementName: 'AP_WRITE_BR_REFF_TAB',
          entitlementDescription:
            'Allows the role to write the Benefit Recovery Referral screen',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBRNSF',
            languageCode: 'en',
            key: 'ENBRNS94327',
            value: 'IE Agency Portal - Benefits Recovery - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:40.923Z',
          lastUpdateDate: '2021-03-03T07:25:11.970Z'
        },
        {
          entitlementId: 210,
          entitlementName: 'AP_READ_BI_SUPVIEW_TAB',
          entitlementDescription: 'Allows the role to view the Supervisor View',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBINSF',
            languageCode: 'en',
            key: 'ENBINS94326',
            value: 'IE Agency Portal - Benefits Issuance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:47.380Z',
          lastUpdateDate: '2021-03-03T07:25:11.923Z'
        },
        {
          entitlementId: 211,
          entitlementName: 'AP_WRITE_BI_SUPVIEW_TAB',
          entitlementDescription:
            'Allows the role to write the Supervisor View',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPBINSF',
            languageCode: 'en',
            key: 'ENBINS94326',
            value: 'IE Agency Portal - Benefits Issuance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-05T07:19:47.660Z',
          lastUpdateDate: '2021-03-03T07:25:11.923Z'
        },
        {
          entitlementId: 231,
          entitlementName: 'AP_WRITE_CLIENTDET_CTZNSHP_ACC',
          entitlementDescription: 'Entitlement - Access Citizenship Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-03T11:39:06.867Z',
          lastUpdateDate: '2021-03-08T17:53:40.190Z'
        },
        {
          entitlementId: 232,
          entitlementName: 'AP_WRITE_CM_CLIENTDET_CTZNSHP_FLD',
          entitlementDescription:
            'Entitlement for FA Worker to display and process Citizenship/Immigration info in Case',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'APNSF',
            languageCode: 'en',
            key: 'ENAPNS64129',
            value: 'IE Agency Portal - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-03T11:39:06.880Z',
          lastUpdateDate: '2021-01-29T16:52:35.397Z'
        },
        {
          entitlementId: 235,
          entitlementName: 'AP_WRITE_CLIENTDET_PERDAT_IDVERF_FLD',
          entitlementDescription:
            'Entitlement - Access Personal Data Identification Verf field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-03T11:39:07.727Z',
          lastUpdateDate: '2021-03-08T17:53:40.190Z'
        },
        {
          entitlementId: 236,
          entitlementName: 'AP_WRITE_CLIENTDET_PERDAT_IDVERF_FLD',
          entitlementDescription:
            'Entitlement for FA Worker to display Child Care Identification Verification field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'APNSF',
            languageCode: 'en',
            key: 'ENAPNS64129',
            value: 'IE Agency Portal - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-03T11:39:07.740Z',
          lastUpdateDate: '2021-01-29T16:52:35.397Z'
        },
        {
          entitlementId: 244,
          entitlementName: 'AP_READ_ED_ELIGHSTRY_CASEHSTRY_ACC',
          entitlementDescription:
            'Allows the role to view the Eligibility History',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPELNSF',
            languageCode: 'en',
            key: 'ENELNS94323',
            value: 'IE Agency Portal - Eligibility - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-04T12:46:45.020Z',
          lastUpdateDate: '2021-05-04T10:11:24.267Z'
        },
        {
          entitlementId: 242,
          entitlementName: 'AP_WRITE_ED_ELIGINFO_CONFIRMELIG_ACC',
          entitlementDescription:
            'Allows the role to confirm the Eligibility',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPELNSF',
            languageCode: 'en',
            key: 'ENELNS94323',
            value: 'IE Agency Portal - Eligibility - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-04T12:46:44.863Z',
          lastUpdateDate: '2021-05-04T10:11:24.250Z'
        },
        {
          entitlementId: 258,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MFIVE_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 5 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:49.947Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 257,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MFOUR_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 4 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:49.917Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 254,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MONE_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 1 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:49.790Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 259,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MSIX_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 6 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:49.993Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 256,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MTHREE_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 3 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:49.870Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 255,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MTWO_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 2 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:49.840Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 260,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_RNTLINCM_FLD',
          entitlementDescription:
            'Entitlement - Access self emp rental income field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:50.027Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 253,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_VARINCM_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:49.743Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 262,
          entitlementName: 'AP_WRITE_CLIENTDET_ADR_ADRVERF_FLD',
          entitlementDescription:
            'Entitlement - Access Address Verficiation Field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:50.713Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 272,
          entitlementName: 'AP_WRITE_AI_INCM_EMP_HRWRKD_FLD',
          entitlementDescription:
            'Family Assistance worker to read write on Hours worked',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'APNSF',
            languageCode: 'en',
            key: 'ENAPNS64129',
            value: 'IE Agency Portal - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:52.807Z',
          lastUpdateDate: '2021-03-26T17:31:35.900Z'
        },
        {
          entitlementId: 282,
          entitlementName: 'AP_WRITE_INCM_EMP_EMPVERF_FLD',
          entitlementDescription: 'Entitlement - Access employment verf field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:54.027Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 291,
          entitlementName: 'AP_WRITE_INCM_EMP_ERNVERF_FLD',
          entitlementDescription:
            'Entitlement - Access employment earned income verf field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:54.683Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 283,
          entitlementName: 'AP_WRITE_CM_INCM_EMP_HRWRKD_FLD',
          entitlementDescription:
            'Family Assistance worker to read write on Hours worked',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'APNSF',
            languageCode: 'en',
            key: 'ENAPNS64129',
            value: 'IE Agency Portal - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:54.073Z',
          lastUpdateDate: '2021-01-29T16:52:35.303Z'
        },
        {
          entitlementId: 289,
          entitlementName: 'AP_WRITE_INCM_EMP_MFIVE_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 5 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:54.603Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 288,
          entitlementName: 'AP_WRITE_INCM_EMP_MFOUR_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 4 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:54.557Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 285,
          entitlementName: 'AP_WRITE_INCM_EMP_MONE_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 1 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:54.260Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 290,
          entitlementName: 'AP_WRITE_INCM_EMP_MSIX_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 6 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:54.637Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 287,
          entitlementName: 'AP_WRITE_INCM_EMP_MTHREE_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 3 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:54.510Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 286,
          entitlementName: 'AP_WRITE_INCM_EMP_MTWO_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 2 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:54.370Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 284,
          entitlementName: 'AP_WRITE_INCM_EMP_VARINCM_FLD',
          entitlementDescription:
            'Entitlement - Access employment variable income field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-18T17:09:54.213Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 298,
          entitlementName: 'AP_WRITE_INCM_UNEARN_INCMTYP_FLD',
          entitlementDescription:
            'Entitlement - Access self emp  income type field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-21T14:16:04.857Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 300,
          entitlementName: 'AP_WRITE_INCM_UNEARN_OUTSTUCI_FLD',
          entitlementDescription:
            'Entitlement - Access self emp out of state UCI field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-21T14:16:04.870Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 299,
          entitlementName: 'AP_WRITE_INCM_UNEARN_SSNCLMNBR_FLD',
          entitlementDescription:
            'Entitlement - Access self emp  SSN claim nbr field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-21T14:16:04.870Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 301,
          entitlementName: 'AP_WRITE_INCM_UNEARN_UNEARNVERF_FLD',
          entitlementDescription:
            'Entitlement - Access self emp unearned income verf field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-21T14:16:04.887Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 305,
          entitlementName: 'AP_WRITE_INCM_EMP_ONSTRIKE_FLD',
          entitlementDescription: 'Entitlement - Access on strike field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-23T16:41:06.027Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 307,
          entitlementName: 'AP_WRITE_INCM_EMP_VOLREDHOUR_FLD',
          entitlementDescription:
            'Entitlement - Access voluntarily reducted hours field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-24T10:20:44.500Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 325,
          entitlementName: 'AP_WRITE_AI_EXPENSE_GTPST_ACC',
          entitlementDescription:
            'Entitlement - Access Expense Questions Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-01-28T01:55:33.420Z',
          lastUpdateDate: '2021-03-08T17:53:40.330Z'
        },
        {
          entitlementId: 322,
          entitlementName: 'AP_WRITE_AI_HLTH_GTPST_ACC',
          entitlementDescription:
            'Entitlement - Access health Questions Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-01-28T01:55:33.403Z',
          lastUpdateDate: '2021-03-08T17:53:40.220Z'
        },
        {
          entitlementId: 323,
          entitlementName: 'AP_WRITE_INCM_GTPST_ACC',
          entitlementDescription:
            'Entitlement - Access Income Questions Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-01-28T01:55:33.403Z',
          lastUpdateDate: '2021-03-08T17:53:40.313Z'
        },
        {
          entitlementId: 324,
          entitlementName: 'AP_WRITE_AI_ASSET_GTPST_ACC',
          entitlementDescription:
            'Entitlement - Access Asset Questions Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-01-28T01:55:33.403Z',
          lastUpdateDate: '2021-03-08T17:53:40.313Z'
        },
        {
          entitlementId: 169,
          entitlementName: 'AP_WRITE_CM_CASEINFO_TAB',
          entitlementDescription: 'Entitlement - Access Case information Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:40.247Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 170,
          entitlementName: 'AP_WRITE_CM_SPCLPROG_TAB',
          entitlementDescription: 'Entitlement - Access Special Programs Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:40.277Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 171,
          entitlementName: 'AP_WRITE_CM_CLIENTDET_TAB',
          entitlementDescription: 'Entitlement - Access Client Detail Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:40.310Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 172,
          entitlementName: 'AP_WRITE_HLTH_TAB',
          entitlementDescription: 'Entitlement - Access Health Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:40.340Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 173,
          entitlementName: 'AP_WRITE_CM_ADDLINFO_TAB',
          entitlementDescription:
            'Entitlement - Access Additional Information Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:40.370Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 174,
          entitlementName: 'AP_WRITE_ASSET_TAB',
          entitlementDescription: 'Entitlement - Access Asset Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:40.403Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 175,
          entitlementName: 'AP_WRITE_CM_INCM_TAB',
          entitlementDescription: 'Entitlement - Access Income Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:40.433Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 176,
          entitlementName: 'AP_WRITE_EXPENSE_TAB',
          entitlementDescription: 'Entitlement - Access Expense Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-04T10:07:40.467Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 344,
          entitlementName: 'AP_WRITE_CM_CASEINFO_ELIGSUM_ACC',
          entitlementDescription:
            'Entitlement - Access Child Care Eligibility Summary Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.790Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 577,
          entitlementName: 'AP_WRITE_AI_PRGREQ_REL_ACC',
          entitlementDescription: 'Entitlement - Access Relationship accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:48.993Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 343,
          entitlementName: 'AP_WRITE_CASEINFO_CASDET_ACC',
          entitlementDescription:
            'Entitlement - Access Eligibility Summary Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.790Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 222,
          entitlementName: 'AP_WRITE_ADDLINFO_INDVFLG_ACC',
          entitlementDescription:
            'Entitlement - Access Individual Flags Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-30T01:30:35.840Z',
          lastUpdateDate: '2021-03-08T17:53:40.237Z'
        },
        {
          entitlementId: 224,
          entitlementName: 'AP_WRITE_ADDLINFO_INDVFLG_ACC',
          entitlementDescription:
            'Entitlement - Access Individual Flags Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-11-30T01:30:36.420Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 575,
          entitlementName: 'AP_WRITE_AI_APPDET_TAB',
          entitlementDescription: 'Entitlement - Access Application Detail Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:48.993Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 576,
          entitlementName: 'AP_WRITE_AI_PROGREQ_TAB',
          entitlementDescription: 'Entitlement - Access Program Request Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:48.993Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 578,
          entitlementName: 'AP_WRITE_PRGREQ_PROGS_ACC',
          entitlementDescription:
            'Entitlement - Access program Request FA Programs',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.010Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 579,
          entitlementName: 'AP_WRITE_AI_CLIENTDET_TAB',
          entitlementDescription: 'Entitlement - Access Client Detail Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.010Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 580,
          entitlementName: 'AP_WRITE_CLIENTDET_SPCLACC_ACC',
          entitlementDescription:
            'Entitlement - Access Special Accomodation Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.023Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 581,
          entitlementName: 'AP_WRITE_CLIENTDET_PERDAT_ACC',
          entitlementDescription:
            'Entitlement - Access Personal Data Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.023Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 582,
          entitlementName: 'AP_WRITE_CLIENTDET_ALIAS_ACC',
          entitlementDescription: 'Entitlement - Access Alias Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.040Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 309,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_HOURSWORKD_FLD',
          entitlementDescription:
            'Entitlement - Access self emp hours worked per week field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-24T10:20:50.810Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 346,
          entitlementName: 'AP_WRITE_CASEINFO_HLDMEM_ACC',
          entitlementDescription:
            'Entitlement - Access Household information Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.807Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 347,
          entitlementName: 'AP_WRITE_CASEINFO_HLDINFO_ACC',
          entitlementDescription:
            'Entitlement - Access Alternate Contact Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.807Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 348,
          entitlementName: 'AP_WRITE_CASEINFO_ALTCNT_ACC',
          entitlementDescription:
            'Entitlement - Access EBT Card Holder Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.820Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 349,
          entitlementName: 'AP_WRITE_CASEINFO_EBT_ACC',
          entitlementDescription: 'Entitlement - Access Redetermination Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.820Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 350,
          entitlementName: 'AP_WRITE_CM_REDET_TAB',
          entitlementDescription:
            'Entitlement - Access Burial Assistance Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.820Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 351,
          entitlementName: 'AP_WRITE_SPCLPROG_BA_ACC',
          entitlementDescription:
            'Entitlement - Access Emergency Assistance Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.837Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 352,
          entitlementName: 'AP_WRITE_SPCLPROG_EA_ACC',
          entitlementDescription:
            'Entitlement - Access Fitness of Employment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.837Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 353,
          entitlementName: 'AP_WRITE_SPCLPROG_EMPFIT_ACC',
          entitlementDescription:
            'Entitlement - Access Criminal Information Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.837Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 354,
          entitlementName: 'AP_WRITE_CM_SPCLPROG_CRMINFO_ACC',
          entitlementDescription:
            'Entitlement - Access TANF Work Requirement Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.853Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 355,
          entitlementName: 'AP_WRITE_SPCLPROG_TANFREQ_ACC',
          entitlementDescription: 'Entitlement - Access LIEAP Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.853Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 356,
          entitlementName: 'AP_WRITE_SPCLPROG_LIEAP_ACC',
          entitlementDescription: 'Entitlement - Access eLIEAP Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.853Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 357,
          entitlementName: 'AP_WRITE_SPCLPROG_ELIEAP_ACC',
          entitlementDescription: 'Entitlement - Access DSNAP Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.867Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 358,
          entitlementName: 'AP_WRITE_SPCLPROG_DSNAP_ACC',
          entitlementDescription:
            'Entitlement - Access Applicant Confirmation Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.867Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 359,
          entitlementName: 'AP_WRITE_SPCLPROG_APPCONF_ACC',
          entitlementDescription:
            'Entitlement - Access Special Accomodation Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.867Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 360,
          entitlementName: 'AP_WRITE_CLIENTDET_SPCLACC_ACC',
          entitlementDescription:
            'Entitlement - Access Personal Data Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.867Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 361,
          entitlementName: 'AP_WRITE_CLIENTDET_PERDAT_ACC',
          entitlementDescription: 'Entitlement - Access Alias Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.883Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 362,
          entitlementName: 'AP_WRITE_CLIENTDET_ALIAS_ACC',
          entitlementDescription: 'Entitlement - Access Demographics Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.883Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 363,
          entitlementName: 'AP_WRITE_CLIENTDET_DMGRPIC_ACC',
          entitlementDescription: 'Entitlement - Access Citizenship Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.883Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 364,
          entitlementName: 'AP_WRITE_CLIENTDET_CTZNSHP_ACC',
          entitlementDescription:
            'Entitlement - Access Child Care Citizenship Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.900Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 366,
          entitlementName: 'AP_WRITE_CLIENTDET_ADR_ACC',
          entitlementDescription:
            'Entitlement - Access Preferred Contact Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.900Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 367,
          entitlementName: 'AP_WRITE_CLIENTDET_PRFCNT_ACC',
          entitlementDescription:
            'Entitlement - Access Presumptive Eligibility Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.913Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 368,
          entitlementName: 'AP_WRITE_CLIENTDET_PE_ACC',
          entitlementDescription:
            'Entitlement - Access health Questions Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.913Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 369,
          entitlementName: 'AP_WRITE_CM_HLTH_GTPST_ACC',
          entitlementDescription: 'Entitlement - Access Pregnancy Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.913Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 370,
          entitlementName: 'AP_WRITE_CM_HLTH_PRGNCY_ACC',
          entitlementDescription: 'Entitlement - Access Newborn Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.913Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 371,
          entitlementName: 'AP_WRITE_CM_HLTH_NWBRN_ACC',
          entitlementDescription:
            'Entitlement - Access citizenship identity Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.930Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 372,
          entitlementName: 'AP_WRITE_CM_HLTH_CTZNIDTY_ACC',
          entitlementDescription: 'Entitlement - Access Disabilty Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.930Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 373,
          entitlementName: 'AP_WRITE_CM_HLTH_DSABLTY_ACC',
          entitlementDescription: 'Entitlement - Access MRT Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.930Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 374,
          entitlementName: 'AP_WRITE_CM_HLTH_MRT_ACC',
          entitlementDescription: 'Entitlement - Access LTC Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.947Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 375,
          entitlementName: 'AP_WRITE_CM_HLTH_LTC_ACC',
          entitlementDescription:
            'Entitlement - Access Prior current SSI Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.947Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 376,
          entitlementName: 'AP_WRITE_CM_HLTH_SSI_ACC',
          entitlementDescription: 'Entitlement - Access Medicare Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.947Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 377,
          entitlementName: 'AP_WRITE_CM_HLTH_MEDICR_ACC',
          entitlementDescription:
            'Entitlement - Access Health Insurance Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.960Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 378,
          entitlementName: 'AP_WRITE_CM_HLTH_HLTHINSR_ACC',
          entitlementDescription:
            'Entitlement - Access Outside HH Information Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.960Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 379,
          entitlementName: 'AP_WRITE_CM_HLTH_OUTSIDEHH_ACC',
          entitlementDescription:
            'Entitlement - Access Tax Information Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.960Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 380,
          entitlementName: 'AP_WRITE_CM_HLTH_TAXINFO_ACC',
          entitlementDescription:
            'Entitlement - Access Electronic Verification Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.960Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 381,
          entitlementName: 'AP_WRITE_CM_HLTH_ELCVERF_ACC',
          entitlementDescription:
            'Entitlement - Access Benefits from another state Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.977Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 382,
          entitlementName: 'AP_WRITE_ADDLINFO_BNFRCV_ACC',
          entitlementDescription:
            'Entitlement - Access SNAP ABAWD Tracking Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.977Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 383,
          entitlementName: 'AP_WRITE_ADDLINFO_ABAWDTRK_ACC',
          entitlementDescription:
            'Entitlement - Access TANF Tracking Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.977Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 384,
          entitlementName: 'AP_WRITE_ADDLINFO_TANFTRK_ACC',
          entitlementDescription:
            'Entitlement - Access LIEAP Performance Measures Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.993Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 385,
          entitlementName: 'AP_WRITE_ADDLINFO_LIEAPMSR_ACC',
          entitlementDescription:
            'Entitlement - Access Child Care policy exception supervisor approval fields',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:56.993Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 387,
          entitlementName: 'AP_WRITE_CM_ASSET_GTPST_ACC',
          entitlementDescription:
            'Entitlement - Access Bank and Credit union Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.010Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 388,
          entitlementName: 'AP_WRITE_CM_ASSET_BNKACCT_ACC',
          entitlementDescription:
            'Entitlement - Access Stock and Bonds Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.010Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 389,
          entitlementName: 'AP_WRITE_CM_ASSET_STKBND_ACC',
          entitlementDescription:
            'Entitlement - Access Retirement Account Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.010Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 390,
          entitlementName: 'AP_WRITE_CM_ASSET_RTRMTACCT_ACC',
          entitlementDescription: 'Entitlement - Access Trust Fund Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.010Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 391,
          entitlementName: 'AP_WRITE_CM_ASSET_TRSTFND_ACC',
          entitlementDescription: 'Entitlement - Access Lump sum Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.023Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 392,
          entitlementName: 'AP_WRITE_CM_ASSET_LMPSUM_ACC',
          entitlementDescription: 'Entitlement - Access Vehicle Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.023Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 393,
          entitlementName: 'AP_WRITE_CM_ASSET_VHCL_ACC',
          entitlementDescription:
            'Entitlement - Access Real Property Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.023Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 394,
          entitlementName: 'AP_WRITE_CM_ASSET_REALPROP_ACC',
          entitlementDescription:
            'Entitlement - Access Personal Property Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.040Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 395,
          entitlementName: 'AP_WRITE_CM_ASSET_PERSPROP_ACC',
          entitlementDescription:
            'Entitlement - Access Life Insurance Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.040Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 396,
          entitlementName: 'AP_WRITE_CM_ASSET_LFINSR_ACC',
          entitlementDescription: 'Entitlement - Access Burial Asset Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.040Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 397,
          entitlementName: 'AP_WRITE_CM_ASSET_BURIAL_ACC',
          entitlementDescription: 'Entitlement - Access other asset  Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.057Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 398,
          entitlementName: 'AP_WRITE_CM_ASSET_OTHR_ACC',
          entitlementDescription:
            'Entitlement - Access asset transfer Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.057Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 399,
          entitlementName: 'AP_WRITE_CM_ASSET_ASSTTFR_ACC',
          entitlementDescription:
            'Entitlement - Access asset assessment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.057Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 400,
          entitlementName: 'AP_WRITE_CM_ASSET_ASSTASSMT_ACC',
          entitlementDescription:
            'Entitlement - Access Income Questions Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.057Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 401,
          entitlementName: 'AP_WRITE_INCM_GTPST_ACC',
          entitlementDescription: 'Entitlement - Access Employment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.070Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 402,
          entitlementName: 'AP_WRITE_INCM_EMP_ACC',
          entitlementDescription:
            'Entitlement - Access Loss of Employment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.070Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 403,
          entitlementName: 'AP_WRITE_INCM_LSEMP_ACC',
          entitlementDescription:
            'Entitlement - Access Self Employment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.070Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 404,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_ACC',
          entitlementDescription:
            'Entitlement - Access Unearned income Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.087Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 405,
          entitlementName: 'AP_WRITE_INCM_UNEARN_ACC',
          entitlementDescription:
            'Entitlement - Access Room and Board income Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.087Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 406,
          entitlementName: 'AP_WRITE_INCM_RB_ACC',
          entitlementDescription:
            'Entitlement - Access Employment Assistance Program Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.087Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 407,
          entitlementName: 'AP_WRITE_INCM_EAP_ACC',
          entitlementDescription:
            'Entitlement - Access MAJI Adjustment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.103Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 408,
          entitlementName: 'AP_WRITE_INCM_MAGIADJ_ACC',
          entitlementDescription:
            'Entitlement - Access Work Registration Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.103Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 409,
          entitlementName: 'AP_WRITE_INCM_WORKREG_ACC',
          entitlementDescription:
            'Entitlement - Access Expense Questions Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.103Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 410,
          entitlementName: 'AP_WRITE_CM_EXPENSE_GTPST_ACC',
          entitlementDescription: 'Entitlement - Access Shelter Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.103Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 411,
          entitlementName: 'AP_WRITE_CM_EXPENSE_SHLTR_ACC',
          entitlementDescription: 'Entitlement - Access Utility Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.117Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 412,
          entitlementName: 'AP_WRITE_CM_EXPENSE_UTLTY_ACC',
          entitlementDescription:
            'Entitlement - Access Child Support Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.117Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 413,
          entitlementName: 'AP_WRITE_CM_EXPENSE_CHLDSUPT_ACC',
          entitlementDescription:
            'Entitlement - Access Dependent care Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.117Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 414,
          entitlementName: 'AP_WRITE_CM_EXPENSE_DPTCR_ACC',
          entitlementDescription:
            'Entitlement - Access Medical Expense Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.133Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 415,
          entitlementName: 'AP_WRITE_CM_EXPENSE_MED_ACC',
          entitlementDescription:
            'Entitlement - Access Work - Impaired expense Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.133Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 416,
          entitlementName: 'AP_WRITE_CM_EXPENSE_BLDIMP_ACC',
          entitlementDescription: 'Entitlement - Access Absent Parent Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.133Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 417,
          entitlementName: 'AP_WRITE_ABSTPRNT_TAB',
          entitlementDescription:
            'Entitlement - Access Absent Parent summary accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.150Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 418,
          entitlementName: 'AP_WRITE_CM_ABSTPRNT_ABSTPRNTSUM_ACC',
          entitlementDescription:
            'Entitlement - Access Absent Parent information accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.150Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 419,
          entitlementName: 'AP_WRITE_CM_ABSTPRNT_ABSTPRNTINFO_ACC',
          entitlementDescription:
            'Entitlement - Access Absent Parent IVD accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.163Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 420,
          entitlementName: 'AP_WRITE_CM_ABSTPRNT_IVD_ACC',
          entitlementDescription:
            'Entitlement - Access  school Schedule accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.163Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 427,
          entitlementName: 'AP_WRITE_INFRCT_TAB',
          entitlementDescription:
            'Entitlement - Access intentional program violation accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.197Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 428,
          entitlementName: 'AP_WRITE_CM_INFRCT_IPV_ACC',
          entitlementDescription:
            'Entitlement - Access work requirement accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.210Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 429,
          entitlementName: 'AP_WRITE_CM_INFRCT_WRKREQ_ACC',
          entitlementDescription:
            'Entitlement - Access TANF sanction accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.210Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 430,
          entitlementName: 'AP_WRITE_CM_INFRCT_TANF_ACC',
          entitlementDescription:
            'Entitlement - Access program disqualification accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.210Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 431,
          entitlementName: 'AP_WRITE_CM_INFRCT_PRGDIS_ACC',
          entitlementDescription:
            'Entitlement - Access lumpsum sancation accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.227Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 432,
          entitlementName: 'AP_WRITE_CM_INFRCT_LMPSM_ACC',
          entitlementDescription:
            'Entitlement - Access Asset Transfer sanction accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.227Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 433,
          entitlementName: 'AP_WRITE_CM_INFRCT_ASTTRF_ACC',
          entitlementDescription:
            'Entitlement - Access Quality Control Sanction accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.227Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 434,
          entitlementName: 'AP_WRITE_CM_INFRCT_QC_ACC',
          entitlementDescription: 'Entitlement - Access Referral Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.227Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 435,
          entitlementName: 'AP_WRITE_CM_REFRAL_TAB',
          entitlementDescription: 'Entitlement - Access Snap E and T accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.243Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 436,
          entitlementName: 'AP_WRITE_REFRAL_SNAPET_ACC',
          entitlementDescription:
            'Entitlement - Access TANF referral accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.243Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 437,
          entitlementName: 'AP_WRITE_REFRAL_TANF_ACC',
          entitlementDescription:
            'Entitlement - Access child care referral accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.243Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 439,
          entitlementName: 'AP_WRITE_CM_VERF_TAB',
          entitlementDescription: 'Entitlement - Read Case Details Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.260Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 583,
          entitlementName: 'AP_WRITE_CLIENTDET_DMGRPIC_ACC',
          entitlementDescription: 'Entitlement - Access Demographics Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.040Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 584,
          entitlementName: 'AP_WRITE_CLIENTDET_ADR_ACC',
          entitlementDescription: 'Entitlement - Access Address Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.040Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 586,
          entitlementName: 'AP_WRITE_CLIENTDET_ADR_ADRVERF_FLD',
          entitlementDescription:
            'Entitlement - Access Address Verficiation Field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.057Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 587,
          entitlementName: 'AP_WRITE_CLIENTDET_PRFCNT_ACC',
          entitlementDescription:
            'Entitlement - Access Preferred Contact Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.057Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 588,
          entitlementName: 'AP_WRITE_CLIENTDET_PE_ACC',
          entitlementDescription:
            'Entitlement - Access Presumptive Eligibility Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.070Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 589,
          entitlementName: 'AP_WRITE_HLTH_TAB',
          entitlementDescription: 'Entitlement - Access Health Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.070Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 590,
          entitlementName: 'AP_WRITE_AI_HLTH_PRGNCY_ACC',
          entitlementDescription: 'Entitlement - Access Pregnancy Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.087Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 591,
          entitlementName: 'AP_WRITE_AI_HLTH_NWBRN_ACC',
          entitlementDescription: 'Entitlement - Access Newborn Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.087Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 592,
          entitlementName: 'AP_WRITE_AI_HLTH_CTZNIDTY_ACC',
          entitlementDescription:
            'Entitlement - Access citizenship identity Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.103Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 593,
          entitlementName: 'AP_WRITE_AI_HLTH_DSABLTY_ACC',
          entitlementDescription: 'Entitlement - Access Disabilty Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.103Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 594,
          entitlementName: 'AP_WRITE_AI_HLTH_MRT_ACC',
          entitlementDescription: 'Entitlement - Access MRT Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.103Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 595,
          entitlementName: 'AP_WRITE_AI_HLTH_LTC_ACC',
          entitlementDescription: 'Entitlement - Access LTC Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.117Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 596,
          entitlementName: 'AP_WRITE_AI_HLTH_SSI_ACC',
          entitlementDescription:
            'Entitlement - Access Prior current SSI Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.117Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 597,
          entitlementName: 'AP_WRITE_AI_HLTH_MEDICR_ACC',
          entitlementDescription: 'Entitlement - Access Medicare Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.117Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 598,
          entitlementName: 'AP_WRITE_AI_HLTH_HLTHINSR_ACC',
          entitlementDescription:
            'Entitlement - Access Health Insurance Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.133Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 599,
          entitlementName: 'AP_WRITE_AI_HLTH_OUTSIDEHH_ACC',
          entitlementDescription:
            'Entitlement - Access Outside HH Information Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.133Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 600,
          entitlementName: 'AP_WRITE_AI_HLTH_TAXINFO_ACC',
          entitlementDescription:
            'Entitlement - Access Tax Information Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.133Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 601,
          entitlementName: 'AP_WRITE_AI_HLTH_ELCVERF_ACC',
          entitlementDescription:
            'Entitlement - Access Electronic Verification Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.150Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 602,
          entitlementName: 'AP_WRITE_AI_HLTH_BCCDIAG_ACC',
          entitlementDescription:
            'Entitlement - Access BCC Diagnosis Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.150Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 603,
          entitlementName: 'AP_WRITE_AI_ADDLINFO_ACC',
          entitlementDescription:
            'Entitlement - Access Additional Information Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.150Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 604,
          entitlementName: 'AP_WRITE_ADDLINFO_BNFRCV_ACC',
          entitlementDescription:
            'Entitlement - Access Benefits from another state Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.163Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 605,
          entitlementName: 'AP_WRITE_ADDLINFO_ABAWDTRK_ACC',
          entitlementDescription:
            'Entitlement - Access SNAP ABAWD Tracking Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.163Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 606,
          entitlementName: 'AP_WRITE_ADDLINFO_TANFTRK_ACC',
          entitlementDescription:
            'Entitlement - Access TANF Tracking Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.163Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 607,
          entitlementName: 'AP_WRITE_ADDLINFO_LIEAPMSR_ACC',
          entitlementDescription:
            'Entitlement - Access LIEAP Performance Measures Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.180Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 609,
          entitlementName: 'AP_WRITE_ASSET_TAB',
          entitlementDescription: 'Entitlement - Access Asset Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.197Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 610,
          entitlementName: 'AP_WRITE_AI_ASSET_BNKACCT_ACC',
          entitlementDescription:
            'Entitlement - Access Bank and Credit union Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.197Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 611,
          entitlementName: 'AP_WRITE_AI_ASSET_STKBND_ACC',
          entitlementDescription:
            'Entitlement - Access Stock and Bonds Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.197Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 612,
          entitlementName: 'AP_WRITE_AI_ASSET_RTRMTACCT_ACC',
          entitlementDescription:
            'Entitlement - Access Retirement Account Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.210Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 613,
          entitlementName: 'AP_WRITE_AI_ASSET_TRSTFND_ACC',
          entitlementDescription: 'Entitlement - Access Trust Fund Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.210Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 614,
          entitlementName: 'AP_WRITE_AI_ASSET_LMPSUM_ACC',
          entitlementDescription: 'Entitlement - Access Lump sum Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.210Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 615,
          entitlementName: 'AP_WRITE_AI_ASSET_VHCL_ACC',
          entitlementDescription: 'Entitlement - Access Vehicle Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.227Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 616,
          entitlementName: 'AP_WRITE_AI_ASSET_REALPROP_ACC',
          entitlementDescription:
            'Entitlement - Access Real Property Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.227Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 617,
          entitlementName: 'AP_WRITE_AI_ASSET_PERSPROP_ACC',
          entitlementDescription:
            'Entitlement - Access Personal Property Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.227Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 618,
          entitlementName: 'AP_WRITE_AI_ASSET_LFINSR_ACC',
          entitlementDescription:
            'Entitlement - Access Life Insurance Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.243Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 619,
          entitlementName: 'AP_WRITE_AI_ASSET_BURIAL_ACC',
          entitlementDescription: 'Entitlement - Access Burial Asset Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.243Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 620,
          entitlementName: 'AP_WRITE_AI_ASSET_OTHR_ACC',
          entitlementDescription: 'Entitlement - Access other asset  Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.260Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 621,
          entitlementName: 'AP_WRITE_AI_ASSET_ASSTTFR_ACC',
          entitlementDescription:
            'Entitlement - Access asset transfer Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.260Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 622,
          entitlementName: 'AP_WRITE_AI_ASSET_ASSTASSMT_ACC',
          entitlementDescription:
            'Entitlement - Access asset assessment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.260Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 623,
          entitlementName: 'AP_WRITE_AI_INCM_TAB',
          entitlementDescription: 'Entitlement - Access Income Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.273Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 624,
          entitlementName: 'AP_WRITE_INCM_EMP_ACC',
          entitlementDescription: 'Entitlement - Access Employment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.273Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 626,
          entitlementName: 'AP_WRITE_INCM_EMP_ERNVERF_FLD',
          entitlementDescription:
            'Entitlement - Access employment earned income verf field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.290Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 627,
          entitlementName: 'AP_WRITE_INCM_EMP_EMPVERF_FLD',
          entitlementDescription: 'Entitlement - Access employment verf field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.290Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 628,
          entitlementName: 'AP_WRITE_INCM_EMP_MONE_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 1 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.290Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 629,
          entitlementName: 'AP_WRITE_INCM_EMP_MTWO_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 2 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.307Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 630,
          entitlementName: 'AP_WRITE_INCM_EMP_MTHREE_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 3 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.307Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 631,
          entitlementName: 'AP_WRITE_INCM_EMP_MFOUR_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 4 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.307Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 632,
          entitlementName: 'AP_WRITE_INCM_EMP_MFIVE_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 5 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.320Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 633,
          entitlementName: 'AP_WRITE_INCM_EMP_MSIX_FLD',
          entitlementDescription:
            'Entitlement - Access emp variable income Month 6 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.320Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 634,
          entitlementName: 'AP_WRITE_INCM_EMP_VARINCM_FLD',
          entitlementDescription:
            'Entitlement - Access employment variable income field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.337Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 635,
          entitlementName: 'AP_WRITE_INCM_EMP_VOLREDHOUR_FLD',
          entitlementDescription:
            'Entitlement - Access voluntarily reducted hours field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.337Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 636,
          entitlementName: 'AP_WRITE_INCM_EMP_ONSTRIKE_FLD',
          entitlementDescription: 'Entitlement - Access on strike field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.337Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 637,
          entitlementName: 'AP_WRITE_INCM_LSEMP_ACC',
          entitlementDescription:
            'Entitlement - Access Loss of Employment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.353Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 638,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_ACC',
          entitlementDescription:
            'Entitlement - Access Self Employment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.353Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 639,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_VARINCM_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.353Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 640,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MONE_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 1 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.367Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 641,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MTWO_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 2 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.367Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 642,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MTHREE_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 3 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.383Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 643,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MFOUR_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 4 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.383Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 644,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MFIVE_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 5 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.383Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 645,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_MSIX_FLD',
          entitlementDescription:
            'Entitlement - Access self emp variable income Month 6 field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.383Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 646,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_RNTLINCM_FLD',
          entitlementDescription:
            'Entitlement - Access self emp rental income field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.400Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 647,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_HOURSWORKD_FLD',
          entitlementDescription:
            'Entitlement - Access self emp hours worked per week field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.400Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 648,
          entitlementName: 'AP_WRITE_INCM_UNEARN_ACC',
          entitlementDescription:
            'Entitlement - Access Unearned income Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.413Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 650,
          entitlementName: 'AP_WRITE_INCM_UNEARN_UNEARNVERF_FLD',
          entitlementDescription:
            'Entitlement - Access self emp unearned income verf field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.413Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 652,
          entitlementName: 'AP_WRITE_INCM_UNEARN_INCMTYP_FLD',
          entitlementDescription:
            'Entitlement - Access self emp  income type field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.430Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 653,
          entitlementName: 'AP_WRITE_INCM_UNEARN_OUTSTUCI_FLD',
          entitlementDescription:
            'Entitlement - Access self emp out of state UCI field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.430Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 654,
          entitlementName: 'AP_WRITE_INCM_UNEARN_SSNCLMNBR_FLD',
          entitlementDescription:
            'Entitlement - Access self emp  SSN claim nbr field',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.430Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 655,
          entitlementName: 'AP_WRITE_INCM_RB_ACC',
          entitlementDescription:
            'Entitlement - Access Room and Board income Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.447Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 656,
          entitlementName: 'AP_WRITE_INCM_EAP_ACC',
          entitlementDescription:
            'Entitlement - Access Employment Assistance Program Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.447Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 657,
          entitlementName: 'AP_WRITE_INCM_MAGIADJ_ACC',
          entitlementDescription:
            'Entitlement - Access MAJI Adjustment Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.447Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 658,
          entitlementName: 'AP_WRITE_INCM_WORKREG_ACC',
          entitlementDescription:
            'Entitlement - Access Work Registration Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.460Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 659,
          entitlementName: 'AP_WRITE_EXPENSE_TAB',
          entitlementDescription: 'Entitlement - Access Expense Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.460Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 660,
          entitlementName: 'AP_WRITE_AI_EXPENSE_SHLTR_ACC',
          entitlementDescription: 'Entitlement - Access Shelter Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.460Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 661,
          entitlementName: 'AP_WRITE_AI_EXPENSE_UTLTY_ACC',
          entitlementDescription: 'Entitlement - Access Utility Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.477Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 662,
          entitlementName: 'AP_WRITE_AI_EXPENSE_CHLDSUPT_ACC',
          entitlementDescription:
            'Entitlement - Access Child Support Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.477Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 663,
          entitlementName: 'AP_WRITE_AI_EXPENSE_DPTCR_ACC',
          entitlementDescription:
            'Entitlement - Access Dependent care Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.477Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 664,
          entitlementName: 'AP_WRITE_AI_EXPENSE_MED_ACC',
          entitlementDescription:
            'Entitlement - Access Medical Expense Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.493Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 665,
          entitlementName: 'AP_WRITE_AI_EXPENSE_BLDIMP_ACC',
          entitlementDescription:
            'Entitlement - Access Work - Impaired expense Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.493Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 672,
          entitlementName: 'AP_WRITE_INFRCT_TAB',
          entitlementDescription: 'Entitlement - Access Infraction Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.540Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 673,
          entitlementName: 'AP_WRITE_AI_INFRCT_IPV_ACC',
          entitlementDescription:
            'Entitlement - Access intentional program violation accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.540Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 674,
          entitlementName: 'AP_WRITE_AI_INFRCT_WRKREQ_ACC',
          entitlementDescription:
            'Entitlement - Access work requirement accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.557Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 675,
          entitlementName: 'AP_WRITE_AI_INFRCT_PRGDIS_ACC',
          entitlementDescription:
            'Entitlement - Access Program disqualification accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.557Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 676,
          entitlementName: 'AP_WRITE_AI_RVWSMT_TAB',
          entitlementDescription: 'Entitlement - Access Review and submit Tab',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.557Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 677,
          entitlementName: 'AP_WRITE_RVWSMT_EMPFIT_ACC',
          entitlementDescription:
            'Entitlement - Access  Employment fitness accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.570Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 678,
          entitlementName: 'AP_WRITE_AI_RVWSMT_CRMINFO_ACC',
          entitlementDescription:
            'Entitlement - Access  criminal information accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.570Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 679,
          entitlementName: 'AP_WRITE_RVWSMT_TANFREQ_ACC',
          entitlementDescription:
            'Entitlement - Access TANF Work Requirement Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.570Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 680,
          entitlementName: 'AP_WRITE_RVWSMT_ALTCNT_ACC',
          entitlementDescription:
            'Entitlement - Access Alternate Contact Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.587Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 681,
          entitlementName: 'AP_WRITE_RVWSMT_EBT_ACC',
          entitlementDescription:
            'Entitlement - Access EBT Card Holder Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.587Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 682,
          entitlementName: 'AP_WRITE_RVWSMT_APPCONF_ACC',
          entitlementDescription:
            'Entitlement - Access Applicant Confirmation Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-16T09:16:49.603Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 829,
          entitlementName: 'AP_WRITE_CM_HLTH_BCCDIAG_ACC',
          entitlementDescription: 'Access BCC Diagnosis in Case Maintenance',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-26T14:25:58.453Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 830,
          entitlementName: 'AP_WRITE_CM_HLTH_MWINPAY_ACC',
          entitlementDescription: 'Acccess M-WIN Payments in Case Maintenance',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-26T14:25:58.453Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 896,
          entitlementName: 'AP_WRITE_CASEINFO_CASDET_SUPCNFCSE_FLD',
          entitlementDescription: 'Access to Confidential Case button',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: null,
          createdDate: '2021-03-22T15:02:52.090Z',
          lastUpdateDate: '2021-03-22T15:02:52.090Z'
        },
        {
          entitlementId: 243,
          entitlementName: 'AP_READ_ED_ELIGINFO_TAB',
          entitlementDescription:
            'Allows the role to view the Eligibility Information',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPELNSF',
            languageCode: 'en',
            key: 'ENELNS94323',
            value: 'IE Agency Portal - Eligibility - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-04T12:46:44.957Z',
          lastUpdateDate: '2021-05-04T10:11:24.233Z'
        },
        {
          entitlementId: 336,
          entitlementName: 'AP_WRITE_ED_ELIGINFO_OVERRIDE_ACC',
          entitlementDescription:
            'Allows the user to override the Eligibility Results',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPELNSF',
            languageCode: 'en',
            key: 'ENELNS94323',
            value: 'IE Agency Portal - Eligibility - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-10T18:03:17.973Z',
          lastUpdateDate: '2021-05-04T10:11:24.250Z'
        },
        {
          entitlementId: 81,
          entitlementName: 'AP_WRITE_CL_WORKASSIGNMENTRULESANDTRANSFERS',
          entitlementDescription:
            "Allows the role to receive workload from assignment rules and transfers that target the user's organization",
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'ARM',
            languageCode: 'en',
            key: 'ENCAMC07267',
            value: 'Admin Portal - Assignment Rules Management',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2019-12-10T13:25:07.743Z',
          lastUpdateDate: '2021-04-06T12:55:43.627Z'
        },
        {
          entitlementId: 950,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_INCMVERF_FLD',
          entitlementDescription:
            'FA agent self employment income verification',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: null,
          createdDate: '2021-05-24T17:31:07.250Z',
          lastUpdateDate: '2021-05-24T17:31:07.250Z'
        },
        {
          entitlementId: 951,
          entitlementName: 'AP_WRITE_INCM_SLFEMP_INCMVERF_FLD',
          entitlementDescription:
            'FA agent self employment income verification',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: null,
          createdDate: '2021-05-24T17:31:07.250Z',
          lastUpdateDate: '2021-05-24T17:31:07.250Z'
        },
        {
          entitlementId: 952,
          entitlementName: 'AP_WRITE_AI_INCM_SLFEMP_EXPVERF_FLD',
          entitlementDescription:
            'FA agent self employment expense verification',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: null,
          createdDate: '2021-05-24T17:31:07.250Z',
          lastUpdateDate: '2021-05-24T17:31:07.250Z'
        },
        {
          entitlementId: 953,
          entitlementName: 'AP_WRITE_CM_INCM_SLFEMP_EXPVERF_FLD',
          entitlementDescription:
            'FA agent self employment expense verification',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: null,
          createdDate: '2021-05-24T17:31:07.250Z',
          lastUpdateDate: '2021-05-24T17:31:07.250Z'
        },
        {
          entitlementId: 959,
          entitlementName: 'AP_WRITE_ADDLINFO_SNAPET_ACC',
          entitlementDescription: 'Entitlement - Access SNAP E & T',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-06-09T09:29:23.893Z',
          lastUpdateDate: '2021-06-09T09:29:23.893Z'
        },
        {
          entitlementId: 960,
          entitlementName: 'AP_READ_ADDLINFO_SNAPET_ACC',
          entitlementDescription: 'Entitlement - Read Access SNAP E & T',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-06-09T09:29:23.893Z',
          lastUpdateDate: '2021-06-09T09:29:23.893Z'
        },
        {
          entitlementId: 961,
          entitlementName: 'AP_WRITE_ADDLINFO_SNAPET_ACC',
          entitlementDescription: 'Entitlement - Access SNAP E & T',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-06-09T09:29:23.910Z',
          lastUpdateDate: '2021-06-09T09:29:23.910Z'
        },
        {
          entitlementId: 962,
          entitlementName: 'AP_READ_ADDLINFO_SNAPET_ACC',
          entitlementDescription: 'Entitlement - Read Access SNAP E & T',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-06-09T09:29:23.910Z',
          lastUpdateDate: '2021-06-09T09:29:23.910Z'
        },
        {
          entitlementId: 466,
          entitlementName: 'AP_WRITE_CLIENTDET_LIVSIT_ACC',
          entitlementDescription: 'Entitlement - Read Address Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.383Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 964,
          entitlementName: 'AP_WRITE_CLIENTDET_BURAST_ACC',
          entitlementDescription: 'App intake Burial Assistance write',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-06-16T12:46:35.820Z',
          lastUpdateDate: '2021-06-16T12:46:35.820Z'
        },
        {
          entitlementId: 965,
          entitlementName: 'AP_WRITE_CLIENTDET_BURAST_ACC',
          entitlementDescription: 'Case Maintenance Burial Assistance write',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-06-16T12:46:35.820Z',
          lastUpdateDate: '2021-06-16T12:46:35.820Z'
        },
        {
          entitlementId: 966,
          entitlementName: 'AP_READ_CLIENTDET_BURAST_ACC',
          entitlementDescription: 'App intake Burial Assistance read',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-06-16T12:46:35.833Z',
          lastUpdateDate: '2021-06-16T12:46:35.833Z'
        },
        {
          entitlementId: 967,
          entitlementName: 'AP_READ_CLIENTDET_BURAST_ACC',
          entitlementDescription: 'Case Maintenance Burial Assistance read',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-06-16T12:46:35.833Z',
          lastUpdateDate: '2021-06-16T12:46:35.833Z'
        },
        {
          entitlementId: 968,
          entitlementName: 'AP_WRITE_CM_CASEINFO_FEDEXG_ACC',
          entitlementDescription:
            'Case Maintenance Case Info Federal Exchange read',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-06-18T09:32:28.163Z',
          lastUpdateDate: '2021-06-18T09:32:28.163Z'
        },
        {
          entitlementId: 971,
          entitlementName: 'AP_WRITE_FALINK_LNK',
          entitlementDescription:
            'Application Intake Family Assistance hyper-link',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPAINSF',
            languageCode: 'en',
            key: 'ENAINS94324',
            value: 'IE Agency Portal - Application Intake - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-06-24T10:38:08.910Z',
          lastUpdateDate: '2021-06-24T10:38:08.910Z'
        },
        {
          entitlementId: 973,
          entitlementName: 'AP_WRITE_FALINK_LNK',
          entitlementDescription:
            'Case Maintenance Family Assistance hyper-link',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-06-24T10:38:08.927Z',
          lastUpdateDate: '2021-06-24T10:38:08.927Z'
        },
        {
          entitlementId: 442,
          entitlementName: 'AP_READ_CASEINFO_CCELIGSUM_ACC',
          entitlementDescription:
            'Entitlement - Read Household members Accordion',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPCMNSF',
            languageCode: 'en',
            key: 'ENCMNS94325',
            value: 'IE Agency Portal - Case Maintenance - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2021-02-15T14:26:57.273Z',
          lastUpdateDate: '2021-02-26T14:25:58.627Z'
        },
        {
          entitlementId: 443,
          entitlementName: 'AP_WRITE_ED_ELIGINFO_OVERRIDE_SUPERVISOR',
          entitlementDescription:
            'Allows the role to approve the Eligibility override',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPELNSF',
            languageCode: 'en',
            key: 'ENELNS94323',
            value: 'IE Agency Portal - Eligibility - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-04T12:46:44.863Z',
          lastUpdateDate: '2021-05-04T10:11:24.250Z'
        },
        {
          entitlementId: 444,
          entitlementName: 'AP_WRITE_ED_ELIGINFO_WITHDRAWAL_SUPERVISOR',
          entitlementDescription:
            'Allows the role to approve the withdrawal of Eligibility',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPELNSF',
            languageCode: 'en',
            key: 'ENELNS94323',
            value: 'IE Agency Portal - Eligibility - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-04T12:46:44.863Z',
          lastUpdateDate: '2021-05-04T10:11:24.250Z'
        },
        {
          entitlementId: 445,
          entitlementName: 'AP_WRITE_ED_CCA_ELIGINFO_CLOSURE_SUPERVISOR',
          entitlementDescription:
            'Allows the role to approve the closure of child care Eligibility',
          createdUser: 'SYSTEM',
          lastUpdateUser: 'SYSTEM',
          tenantId: null,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'IEAPELNSF',
            languageCode: 'en',
            key: 'ENELNS94323',
            value: 'IE Agency Portal - Eligibility - Non-Salesforce',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          sfSync: 'N',
          createdDate: '2020-12-04T12:46:44.863Z',
          lastUpdateDate: '2021-05-04T10:11:24.250Z'
        },
      ],
      organization: {
        orgId: 3092,
        orgNm: 'MN Supervisor Unit FA',
        extrOrgId: null,
        createUser: 'db3c87d4-cb66-44a4-900c-beb161a5b135',
        createDate: '2021-04-27T12:06:42.830Z',
        lastUpdateUser: 'db3c87d4-cb66-44a4-900c-beb161a5b135',
        lastUpdateDate: '2021-05-17T13:06:25.393Z',
        tenantId: 2,
        startDate: '2021-04-27T12:06:42.830Z',
        endDate: null,
        overview: null,
        webAddress: null,
        servicesOffered: null,
        useParentInfo: 'N',
        parentOrgId: 3135,
        orgType: {
          category: 'ORGANIZATION_TYPE',
          subCategory: 'FA',
          languageCode: 'en',
          key: 'ORFASU92194',
          value: 'Supervisor Unit FA',
          rulesEngineCode: '',
          sortOrder: 9999
        },
        countyId: [23017],
        benefitProgramGroupId: 2,
        multilingualDisplayName: {
          en: 'MN Supervisor Unit FA'
        }
      },
      userId: null,
      dismissInactiveNotifyFlag: 'N',
      adjustMethod: {
        category: 'USER_ROLE_ADJUSTMENT',
        subCategory: 'USER_ROLE_ADJUSTMENT_METHOD',
        languageCode: 'en',
        key: 'URURAM07111',
        value: 'Manual',
        rulesEngineCode: null,
        sortOrder: 9999
      }
    }
  ],
  timeStudyWorker: [    
    {
      userRoleId: 879,
      startDate: '2018-05-04T19:13:57.860Z',
      endDate: null,
      createdDate: '2018-05-04T19:13:57.860Z',
      createdUser: null,
      lastUpdateDate: null,
      lastUpdateUser: null,
      uuid: '1a7dc033-77ed-46c6-a791-f17957a4079d', // tempdevesh
      role: {
        roleId: 879,
        roleName: "TIME_STDY_WORKER",
        status: "Active",
        userRoleId: 4614,
        uuid: "1a7dc033-77ed-46c6-a791-f17957a4079d"
      },
      organization: {
        countyId: [23002, 23017], // 23002 for Anoka county, 23017 for Cottonwood
        orgId: 3092,
        orgNm: 'MN Supervisor Unit FA',
        orgType: {
          category: 'ORGANIZATION_TYPE',
          subCategory: 'FA',
          languageCode: 'en',
          key: 'ORFASU92194',
          value: 'Supervisor Unit FA',
          rulesEngineCode: '',
          sortOrder: 9999
        }
      },
      entitlements: [
        {
          entitlementId: 1,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_USERS,
          entitlementDescription: null
        },
        {
          entitlementId: 2,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_USERS,
          entitlementDescription: null
        },
        {
          entitlementId: 3,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ROLES,
          entitlementDescription: null
        },
        {
          entitlementId: 4,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ROLES,
          entitlementDescription: null
        },
        {
          entitlementId: 5,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ENTITLEMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 6,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ENTITLEMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 7,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ORGANIZATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 8,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ORGANIZATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 9,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_INVITATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 10,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_INVITATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 11,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_EXPORT,
          entitlementDescription: null
        },
        {
          entitlementId: 12,
          entitlementName:
            ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESELECTCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 13,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESKIPCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 14,
          entitlementName: CATEGORIES.ADMIN_PORTAL_ACCESS_MANAGEMENT,
          entitlementDescription: null
        },
        {
          entitlementId: 15,
          entitlementName: ENTITLEMENTS.EDIT_CONFIDENTIAL_FLAG,
          entitlementDescription: null
        },
        {
          entitlementId: 16,
          entitlementName: ENTITLEMENTS.ADMIN_READ_CL_WORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 17,
          entitlementName:
            ENTITLEMENTS.ADMIN_WRITE_CL_SUPERVISEWORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 18,
          entitlementName:
            ENTITLEMENTS.AP_WRITE_CL_WORKASSIGNMENTRULESANDTRANSFERS,
          entitlementDescription: null
        },
        {
          entitlementId: 19,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_WORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 20,
          entitlementName:
            ENTITLEMENTS.ADMIN_WRITE_CL_COUNTYWORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 21,
          entitlementName: ENTITLEMENTS.AP_SF_VIEW_CL_ORGASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 22,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_OUT_OF_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 22,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_IN_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 23,
          entitlementName: ENTITLEMENTS.AP_READ_ALL_ORGS,
          entitlementDescription: null
        },
        {
          entitlementId: 24,
          entitlementName: ENTITLEMENTS.AP_EDIT_MY_ORGS,
          entitlementDescription: null
        },
        {
          entitlementId: 14,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_INQ,
          entitlementDescription: null
        },
        {
          entitlementId: 15,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_CREATE,
          entitlementDescription: null
        },
        {
          entitlementId: 16,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_APPRV,
          entitlementDescription: null
        },
        {
          entitlementId: 25,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_IN_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 26,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_OUT_OF_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 27,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_APPRV,
          entitlementDescription: null
        },
        {
          entitlementId: 28,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_CREATE,
          entitlementDescription: null
        },
        {
          entitlementId: 29,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_INQ,
          entitlementDescription: null
        },
        {
          entitlementId: 30,
          entitlementName: ENTITLEMENTS.AP_SF_VIEW_CL_TRANSFERS,
          entitlementDescription: null
        },
        {
          entitlementId: 31,
          entitlementName: ENTITLEMENTS.AP_WRITE_CL_OUTOFCOUNTYMASSTRANSFER,
          entitlementDescription: null
        },
        {
          entitlementId: 31,
          entitlementName: ENTITLEMENTS.AP_CHILD_CARE_ADDITIONAL_INFO_WRITE,
          entitlementDescription: null
        },
        {
          entitlementId: 32,
          entitlementName: ENTITLEMENTS.AP_CHILD_CARE_ADDITIONAL_INFO_SUPERVISE,
          entitlementDescription: null
        },
        {
          entitlementId: 33,
          entitlementName: ENTITLEMENTS.AP_READ_BI_ISSINQ_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 34,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_MANISS_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 35,
          entitlementName: ENTITLEMENTS.AP_MANAGE_VOUCHERS_RW,
          entitlementDescription: null
        },
        {
          entitlementId: 36,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_PYMTCORR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 37,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_PYMTCORR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 38,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_PYMTINF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 39,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_REFF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 40,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_INVFNDG_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 41,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_PROSINF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 42,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_CLMDTL_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 43,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_REFFSRCH_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 44,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_RCVROVR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 45,
          entitlementName: ENTITLEMENTS.AP_READ_BR_CLMPYMT_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 46,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_PYMTAGR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 47,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_REVPYMT_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 48,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_TOPINF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 49,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_TOPTRCK_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 50,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_CLMSRCH_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 51,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_RCVROVR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 52,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_CNCLISS_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 53,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_SUPVIEW_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 54,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_ELIGINFO_CONFIRMELIG_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 55,
          entitlementName: ENTITLEMENTS.AP_READ_ED_ELIGHSTRY_CASEHSTRY_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 56,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_ELIGINFO_OVERRIDE_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 57,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_CCA_ELIGINFO_CONFIRMELIG_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 58,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_CCA_ELIGINFO_CLOSURE_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 59,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_CCA_WRK_FLD,
          entitlementDescription: null
        },
        {
          entitlementId: 60,
          entitlementName: ENTITLEMENTS.AP_READ_ED_ELIGHSTRY_INDIVIDUALHSTRY_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 61,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_ELIGINFO_ASSETDISPOSITION_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 62,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_ELIGINFO_INITIATEELIG_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 63,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_ELIGINFO_WITHDRAWAL_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 63,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_ELIGINFO_VOLUNTARYCLOSURE_ACC,
          entitlementDescription: null
        }
      ]
    }
  
],
  timeStudySupervisor: [    
    {
      userRoleId: 879,
      startDate: '2018-05-04T19:13:57.860Z',
      endDate: null,
      createdDate: '2018-05-04T19:13:57.860Z',
      createdUser: null,
      lastUpdateDate: null,
      lastUpdateUser: null,
      uuid: '1a7dc033-77ed-46c6-a791-f17957a4079d', // tempdevesh
      role: {
        roleId: 879,
        roleName: "TIME_STDY_SUPERVISOR",
        status: "Active",
        userRoleId: 4614,
        uuid: "1a7dc033-77ed-46c6-a791-f17957a4079d"
      },
      organization: {
        countyId: [23002, 23017], // 23002 for Anoka county, 23017 for Cottonwood
        orgId: 3092,
        orgNm: 'MN Supervisor Unit FA',
        orgType: {
          category: 'ORGANIZATION_TYPE',
          subCategory: 'FA',
          languageCode: 'en',
          key: 'ORFASU92194',
          value: 'Supervisor Unit FA',
          rulesEngineCode: '',
          sortOrder: 9999
        }
      },
      entitlements: [
        {
          entitlementId: 1,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_USERS,
          entitlementDescription: null
        },
        {
          entitlementId: 2,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_USERS,
          entitlementDescription: null
        },
        {
          entitlementId: 3,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ROLES,
          entitlementDescription: null
        },
        {
          entitlementId: 4,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ROLES,
          entitlementDescription: null
        },
        {
          entitlementId: 5,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ENTITLEMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 6,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ENTITLEMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 7,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ORGANIZATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 8,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ORGANIZATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 9,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_INVITATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 10,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_INVITATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 11,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_EXPORT,
          entitlementDescription: null
        },
        {
          entitlementId: 12,
          entitlementName:
            ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESELECTCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 13,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESKIPCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 14,
          entitlementName: CATEGORIES.ADMIN_PORTAL_ACCESS_MANAGEMENT,
          entitlementDescription: null
        },
        {
          entitlementId: 15,
          entitlementName: ENTITLEMENTS.EDIT_CONFIDENTIAL_FLAG,
          entitlementDescription: null
        },
        {
          entitlementId: 16,
          entitlementName: ENTITLEMENTS.ADMIN_READ_CL_WORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 17,
          entitlementName:
            ENTITLEMENTS.ADMIN_WRITE_CL_SUPERVISEWORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 18,
          entitlementName:
            ENTITLEMENTS.AP_WRITE_CL_WORKASSIGNMENTRULESANDTRANSFERS,
          entitlementDescription: null
        },
        {
          entitlementId: 19,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_WORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 20,
          entitlementName:
            ENTITLEMENTS.ADMIN_WRITE_CL_COUNTYWORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 21,
          entitlementName: ENTITLEMENTS.AP_SF_VIEW_CL_ORGASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 22,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_OUT_OF_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 22,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_IN_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 23,
          entitlementName: ENTITLEMENTS.AP_READ_ALL_ORGS,
          entitlementDescription: null
        },
        {
          entitlementId: 24,
          entitlementName: ENTITLEMENTS.AP_EDIT_MY_ORGS,
          entitlementDescription: null
        },
        {
          entitlementId: 14,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_INQ,
          entitlementDescription: null
        },
        {
          entitlementId: 15,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_CREATE,
          entitlementDescription: null
        },
        {
          entitlementId: 16,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_APPRV,
          entitlementDescription: null
        },
        {
          entitlementId: 25,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_IN_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 26,
          entitlementName: ENTITLEMENTS.AP_TRANSFER_OUT_OF_COUNTY,
          entitlementDescription: null
        },
        {
          entitlementId: 27,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_APPRV,
          entitlementDescription: null
        },
        {
          entitlementId: 28,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_CREATE,
          entitlementDescription: null
        },
        {
          entitlementId: 29,
          entitlementName: ENTITLEMENTS.AP_BNFT_ISS_INQ,
          entitlementDescription: null
        },
        {
          entitlementId: 30,
          entitlementName: ENTITLEMENTS.AP_SF_VIEW_CL_TRANSFERS,
          entitlementDescription: null
        },
        {
          entitlementId: 31,
          entitlementName: ENTITLEMENTS.AP_WRITE_CL_OUTOFCOUNTYMASSTRANSFER,
          entitlementDescription: null
        },
        {
          entitlementId: 31,
          entitlementName: ENTITLEMENTS.AP_CHILD_CARE_ADDITIONAL_INFO_WRITE,
          entitlementDescription: null
        },
        {
          entitlementId: 32,
          entitlementName: ENTITLEMENTS.AP_CHILD_CARE_ADDITIONAL_INFO_SUPERVISE,
          entitlementDescription: null
        },
        {
          entitlementId: 33,
          entitlementName: ENTITLEMENTS.AP_READ_BI_ISSINQ_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 34,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_MANISS_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 35,
          entitlementName: ENTITLEMENTS.AP_MANAGE_VOUCHERS_RW,
          entitlementDescription: null
        },
        {
          entitlementId: 36,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_PYMTCORR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 37,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_PYMTCORR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 38,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_PYMTINF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 39,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_REFF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 40,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_INVFNDG_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 41,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_PROSINF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 42,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_CLMDTL_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 43,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_REFFSRCH_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 44,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_RCVROVR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 45,
          entitlementName: ENTITLEMENTS.AP_READ_BR_CLMPYMT_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 46,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_PYMTAGR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 47,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_REVPYMT_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 48,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_TOPINF_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 49,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_TOPTRCK_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 50,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_CLMSRCH_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 51,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_RCVROVR_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 52,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_CNCLISS_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 53,
          entitlementName: ENTITLEMENTS.AP_WRITE_BI_SUPVIEW_TAB,
          entitlementDescription: null
        },
        {
          entitlementId: 54,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_ELIGINFO_CONFIRMELIG_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 56,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_ELIGINFO_OVERRIDE_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 57,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_CCA_ELIGINFO_CONFIRMELIG_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 58,
          entitlementName: ENTITLEMENTS.AP_WRITE_ED_CCA_ELIGINFO_CLOSURE_ACC,
          entitlementDescription: null
        },
        {
          entitlementId: 59,
          entitlementName: ENTITLEMENTS.AP_WRITE_BR_CCA_WRK_FLD,
          entitlementDescription: null
        }
      ]
    }

  ],
  CCSupervisor: [{
    "role": {
        "roleName": "CC Eligibility_Supervisor",
        "displayName": "CC Eligibility_Supervisor",
        "multilingualDisplayName": {
            "en": "CC Eligibility_Supervisor"
        },
        "roleCategoryCode": {
            "category": "ROLE_CATEGORY_TYPE",
            "subCategory": "ROLE_CATEGORY_CD",
            "languageCode": "en",
            "key": "ROROCC34543",
            "value": "Child Care",
            "rulesEngineCode": "",
            "sortOrder": 1
        }
    },
    "entitlements": [
        {
            "entitlementName": "ADMIN_WRITE_CL_WORKASSIGNMENTRULES"
        },
        {
            "entitlementName": "AP_READ_BR_CLMPYMT_TAB"
        },
        {
            "entitlementName": "AP_WRITE_BI_CCA_SUPVIEW_TAB"
        },
        {
            "entitlementName": "AP_WRITE_ED_CCA_ELIGINFO_CLOSURE_ACC"
        },
        {
            "entitlementName": "PPI_SF_READ_PVE_SERVICE_AGR_SEC"
        },
        {
            "entitlementName": "AP_WRITE_CASEINFO_CASDET_SUPCNFCSE_FLD"
        },
        {
            "entitlementName": "AP_READ_CASEINFO_HLDINFO_ACC"
        },
        {
            "entitlementName": "AP_READ_CLIENTDET_ALIAS_ACC"
        },
        {
            "entitlementName": "AP_READ_RVWSMT_APPCONF_ACC"
        },
        {
            "entitlementName": "AP_READ_BI_CCA_PROVPYMTHIS_TAB"
        },
        {
            "entitlementName": "AP_WRITE_CLIENTDET_ADR_CCADRVERF_FLD"
        },
        {
            "entitlementName": "AP_WRITE_CASEINFO_HLDMEM_ACC"
        },
        {
            "entitlementName": "AP_WRITE_ADDLINFO_EDU_ACC"
        },
        {
            "entitlementName": "AP_WRITE_INCM_SLFEMP_CCEXPVERF_FLD"
        },
        {
            "entitlementName": "AP_WRITE_CL_INCOUNTYCASETRANSFER"
        },
        {
            "entitlementName": "AP_WRITE_REFRAL_CC_ACC"
        },
        {
            "entitlementName": "AP_READ_CASEM_CASENOTE_TAB"
        },
        {
            "entitlementName": "AP_WRITE_BR_INVFNDG_TAB"
        },
        {
            "entitlementName": "AP_WRITE_BR_PYMTAGR_TAB"
        },
        {
            "entitlementName": "AP_READ_CLIENTDET_ADR_CCADRVERF_FLD"
        },
        {
            "entitlementName": "AP_READ_ED_ELIGHSTRY_INDIVIDUALHSTRY_ACC"
        },
        {
            "entitlementName": "AP_READ_INCM_EMP_ACC"
        },
        {
            "entitlementName": "AP_ORG_DR_R"
        },
        {
            "entitlementName": "AP_READ_CASEINFO_ELIGSUM_ACC"
        },
        {
            "entitlementName": "EDIT_CONFIDENTIAL_FLAG"
        },
        {
            "entitlementName": "AP_WRITE_ED_CCA_ELIGINFO_CONFIRMELIG_ACC"
        },
        {
            "entitlementName": "AP_WRITE_ADDLINFO_CHLDCRXPT_ACC"
        },
        {
            "entitlementName": "AP_EXPORT_CL_CASEAUDIT"
        },
        {
            "entitlementName": "AP_WRITE_CLIENTDET_LIVSIT_ACC"
        },
        {
            "entitlementName": "AP_READ_CLIENTDET_PERDAT_ACC"
        },
        {
            "entitlementName": "PPI_SF_READ_PVE_INVOICE_SEC"
        },
        {
            "entitlementName": "AP_WRITE_RVWSMT_TAB"
        },
        {
            "entitlementName": "AP_WRITE_INCM_UNEARN_CCINCMTYP_FLD"
        },
        {
            "entitlementName": "AP_READ_BR_CLMDTL_TAB"
        },
        {
            "entitlementName": "AP_READ_BR_REFF_TAB"
        },
        {
            "entitlementName": "AP_READ_INCM_UNEARN_ACC"
        },
        {
            "entitlementName": "AP_READ_CASEINFO_CCELIGSUM_ACC"
        },
        {
            "entitlementName": "AP_WRITE_ED_CCA_ELIGINFO_WITHDRAWAL_ACC"
        },
        {
            "entitlementName": "AP_WRITE_RVWSMT_APPCONF_ACC"
        },
        {
            "entitlementName": "AP_WRITE_CLIENTDET_PRFCNT_ACC"
        },
        {
            "entitlementName": "PPI_SF_READ_PVE_INVOICE_TAB"
        },
        {
            "entitlementName": "AP_READ_INCM_EMP_CCINCMVERF_FLD"
        },
        {
            "entitlementName": "AP_WRITE_CASEINFO_CASDET_STICKY"
        },
        {
            "entitlementName": "AP_WRITE_VERIFICATIONS_CCA_ACC"
        },
        {
            "entitlementName": "PPI_SF_WRITE_PVE_CASE_TAB"
        },
        {
            "entitlementName": "AP_WRITE_BR_CLMDTL_TAB"
        },
        {
            "entitlementName": "PPI_SF_READ_PVE_PROVIDER_SFM"
        },
        {
            "entitlementName": "AP_WRITE_CL_WORKASSIGNMENTRULESANDTRANSFERS"
        },
        {
            "entitlementName": "AP_WRITE_PINSMASH_REQ_TAB"
        },
        {
            "entitlementName": "AP_WRITE_CLIENTDET_ALIAS_ACC"
        },
        {
            "entitlementName": "AP_READ_CASEM_FAIRHEARINGS_TAB"
        },
        {
            "entitlementName": "AP_WRITE_BR_REFF_TAB"
        },
        {
            "entitlementName": "AP_READ_CLIENTDET_ADR_ACC"
        },
        {
            "entitlementName": "AP_READ_CLIENTDET_PRFCNT_ACC"
        },
        {
            "entitlementName": "AP_READ_App_Details"
        },
        {
            "entitlementName": "AP_WRITE_ED_CCA_ELIGINFO_CLOSURE_SUPERVISOR"
        },
        {
            "entitlementName": "PPI_SF_READ_PVE_STRIKE_SEC"
        },
        {
            "entitlementName": "AP_ORGASSIGNMENTRULES_MENU"
        },
        {
            "entitlementName": "AP_READ_BR_CLMSRCH_TAB"
        },
        {
            "entitlementName": "AP_READ_BI_CCA_STDRTE_TAB"
        },
        {
            "entitlementName": "AP_READ_ADDLINFO_CCPOLEXC_ECEAPRV_FLD"
        },
        {
            "entitlementName": "AP_READ_CASEINFO_CASDET_ACC"
        },
        {
            "entitlementName": "AP_READ_BR_REFFSRCH_TAB"
        },
        {
            "entitlementName": "AP_READ_PRGREQ_REL_ACC"
        },
        {
            "entitlementName": "AP_WRITE_CL_OUTOFCOUNTYCASETRANSFER"
        },
        {
            "entitlementName": "AP_READ_SCH_SCHLSCH_TAB"
        },
        {
            "entitlementName": "AP_DASHBOARD_SUPERVISOR_RW"
        },
        {
            "entitlementName": "AP_WRITE_CLIENTDET_CCCTZNSHP_ACC"
        },
        {
            "entitlementName": "AP_READ_INCM_SLFEMP_CCINCMVERF_FLD"
        },
        {
            "entitlementName": "PPI_SF_WRITE_PVE_SERVICE_AGR_SEC"
        },
        {
            "entitlementName": "AP_WRITE_CLIENTDET_PERDAT_ACC"
        },
        {
            "entitlementName": "AP_READ_PINSMASH_SEARCH_TAB"
        },
        {
            "entitlementName": "AP_READ_CLIENTDET_CCCTZNSHP_ACC"
        },
        {
            "entitlementName": "AP_WRITE_INCM_SLFEMP_ACC"
        },
        {
            "entitlementName": "AP_WRITE_CLIENTDET_ADR_ACC"
        },
        {
            "entitlementName": "AP_READ_REDET_REVIEW_ACC"
        },
        {
            "entitlementName": "AP_ORG_DR_RW"
        },
        {
            "entitlementName": "AP_WRITE_ADDLINFO_CHLDCR_ACC"
        },
        {
            "entitlementName": "AP_READ_CASEINFO_CASDET_SUPCNFCSE_FLD"
        },
        {
            "entitlementName": "AP_READ_ADDLINFO_CCPOLEXC_SPRAPRV_FLD"
        },
        {
            "entitlementName": "AP_READ_RVWSMT_ALTCNT_ACC"
        },
        {
            "entitlementName": "AP_READ_ED_CCA_ELIGINFO_CONFIRMELIG_ACC"
        },
        {
            "entitlementName": "AP_WRITE_CASEM_CASENOTE_TAB"
        },
        {
            "entitlementName": "AP_WRITE_BR_CLMSRCH_TAB"
        },
        {
            "entitlementName": "AP_WRITE_App_Details"
        },
        {
            "entitlementName": "AP_WRITE_BR_CLMPYMT_TAB"
        },
        {
            "entitlementName": "AP_READ_INCM_SLFEMP_CCEXPVERF_FLD"
        },
        {
            "entitlementName": "AP_WRITE_PRGREQ_REL_ACC"
        },
        {
            "entitlementName": "AP_READ_BI_CCA_SUPVIEW_TAB"
        },
        {
            "entitlementName": "AP_WRITE_INCM_EMP_CCINCMVERF_FLD"
        },
        {
            "entitlementName": "AP_WRITE_CL_BULKINCOUNTYCASETRANSFER"
        },
        {
            "entitlementName": "PPI_SF_READ_PVE_PAYMENT_TAB"
        },
        {
            "entitlementName": "AP_WRITE_ADDLINFO_CCPOLEXC_SPRAPRV_FLD"
        },
        {
            "entitlementName": "AP_WRITE_SCH_SCHLSCH_TAB"
        },
        {
            "entitlementName": "AP_WRITE_CL_ACTIVITIES"
        },
        {
            "entitlementName": "AP_READ_ADDLINFO_CHLDCR_ACC"
        },
        {
            "entitlementName": "AP_WRITE_CASEINFO_HLDINFO_ACC"
        },
        {
            "entitlementName": "AP_WRITE_CLIENTDET_DMGRPIC_ACC"
        },
        {
            "entitlementName": "AP_WRITE_INCM_UNEARN_ACC"
        },
        {
            "entitlementName": "AP_READ_BR_REVPYMT_TAB"
        },
        {
            "entitlementName": "AP_READ_CLIENTDET_DMGRPIC_ACC"
        },
        {
            "entitlementName": "AP_READ_CASEM_CASECONTACT_TAB"
        },
        {
            "entitlementName": "AP_APPLICATION_MENU"
        },
        {
            "entitlementName": "AP_WRITE_CLIENTDET_SPCLACC_ACC"
        },
        {
            "entitlementName": "AP_READ_ED_ELIGHSTRY_CASEHSTRY_ACC"
        },
        {
            "entitlementName": "PPI_SF_WRITE_PVE_STRIKE_SEC"
        },
        {
            "entitlementName": "AP_READ_ADDLINFO_EDU_ACC"
        },
        {
            "entitlementName": "AP_WRITE_ADDLINFO_INDVFLG_ACC"
        },
        {
            "entitlementName": "AP_READ_ADDLINFO_CHLDCRXPT_ACC"
        },
        {
            "entitlementName": "AP_READ_REFRAL_CC_ACC"
        },
        {
            "entitlementName": "AP_READ_CLIENTDET_SPCLACC_ACC"
        },
        {
            "entitlementName": "AP_READ_BI_ISSINQ_TAB"
        },
        {
            "entitlementName": "AP_WRITE_CASEINFO_ALTCNT_ACC"
        },
        {
            "entitlementName": "AP_WRITE_INCM_EMP_ACC"
        },
        {
            "entitlementName": "AP_WRITE_BR_REVPYMT_TAB"
        },
        {
            "entitlementName": "AP_WRITE_CASEM_FAIRHEARINGS_TAB"
        },
        {
            "entitlementName": "PPI_SF_EDIT_PVE_SERVICE_AGR_SEC"
        },
        {
            "entitlementName": "AP_WRITE_CCLINK_LNK"
        },
        {
            "entitlementName": "AP_WRITE_PRGREQ_CHILDCARE_ACC"
        },
        {
            "entitlementName": "AP_READ_ADDLINFO_INDVFLG_ACC"
        },
        {
            "entitlementName": "AP_READ_CASEINFO_HLDMEM_ACC"
        },
        {
            "entitlementName": "AP_WRITE_ED_CCA_ELIGINFO_WITHDRAWAL_SUPERVISOR"
        },
        {
            "entitlementName": "AP_WRITE_CASEINFO_CASDET_ACC"
        },
        {
            "entitlementName": "AP_READ_CASEOWNERH_ACC"
        },
        {
            "entitlementName": "AP_ACTIVITIES_MENU"
        },
        {
            "entitlementName": "AP_READ_CLIENTDET_PERDAT_CCIDVERF_FLD"
        },
        {
            "entitlementName": "AP_WRITE_INCM_SLFEMP_CCINCMVERF_FLD"
        },
        {
            "entitlementName": "AP_WRITE_CASEINFO_CCELIGSUM_ACC"
        },
        {
            "entitlementName": "AP_WRITE_REDET_REVIEW_ACC"
        },
        {
            "entitlementName": "AP_READ_INCM_SLFEMP_ACC"
        },
        {
            "entitlementName": "AP_WRITE_CASEM_CASECONTACT_TAB"
        },
        {
            "entitlementName": "AP_READ_PRGREQ_CHILDCARE_ACC"
        },
        {
            "entitlementName": "AP_READ_CLIENTDET_LIVSIT_ACC"
        },
        {
            "entitlementName": "AP_READ_PINSMASH_REQ_TAB"
        },
        {
            "entitlementName": "AP_WRITE_CLIENTDET_PERDAT_CCIDVERF_FLD"
        },
        {
            "entitlementName": "AP_READ_BR_INVFNDG_TAB"
        },
        {
            "entitlementName": "AP_READ_INCM_UNEARN_CCINCMTYP_FLD"
        },
        {
            "entitlementName": "AP_READ_BR_PYMTAGR_TAB"
        }
    ],
    "organization": {
        "orgId": 3130,
        "orgNm": "CCKanawhaSupUnit1",
        "parentOrgId": 3129,
        "orgType": {
            "category": "ORGANIZATION_TYPE",
            "subCategory": "CCA",
            "languageCode": "en",
            "key": "ORCCSU92204",
            "value": "Supervisor Unit CCA",
            "rulesEngineCode": "",
            "sortOrder": 31
        },
        "countyId": [
            48020
        ],
        "benefitProgramGroupId": 1,
        "multilingualDisplayName": {
            "en": "CCKanawhaSupUnit1"
        }
    }}]
}
const fakeInactiveRoles = {
  administrator: [
    {
      userRoleId: 103520,
      startDate: '2019-08-12T14:26:24.190Z',
      endDate: '2019-08-12T21:05:59.740Z',
      createdDate: '2019-08-12T14:26:24.190Z',
      createdUser: null,
      lastUpdateDate: '2019-08-12T21:05:59.740Z',
      lastUpdateUser: null,
      uuid: '174489e4-ec7e-4145-8027-381e1279b5d0',
      role: {
        roleId: 8541,
        roleName: 'UserStoryRole',
        roleDescription: null,
        createdUser: 'IEAutomation2',
        lastUpdateUser: null,
        tenantId: 2,
        status: 'InActive',
        numUsers: null,
        displayName: 'User story test',
        inactiveAfterNumber: 1,
        inactiveAfterUnit: {
          category: 'ROLE_INACTIVITY',
          subCategory: 'ROLE_INACTIVITY_UNIT',
          languageCode: 'en',
          key: 'RIRUDD12904',
          value: 'Days',
          rulesEngineCode: null,
          sortOrder: 9999
        },
        startDate: '2019-08-12T05:00:00.000Z',
        endDate: '2019-08-11T05:00:00.000Z',
        createdDate: '2019-08-12T14:12:50.120Z',
        lastUpdateDate: '2019-08-12T17:13:57.707Z',
        multilingualDescription: {
          en: null
        },
        multilingualDisplayName: {
          en: 'User story test'
        },
        isOrgRequired: true
      },
      entitlements: [
        {
          entitlementId: 2752,
          entitlementName: 'McutFAqc',
          entitlementDescription: 'McutFAqc',
          createdUser: '7b14762a-d7cc-4fcb-8313-3e094e0a1814',
          lastUpdateUser: 'SYSTEM',
          tenantId: 2,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'PRY',
            languageCode: 'en',
            key: 'ENCAPR07263',
            value: 'Primary',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          createdDate: '2019-07-17T11:32:22.030Z',
          lastUpdateDate: '2019-07-29T18:52:10.880Z'
        },
        // For Worker Messages
        {
          entitlementId: 24,
          entitlementName: ENTITLEMENTS.ADMIN_READ_CL_WORKER_MSG_CONFIGURATION,
          entitlementDescription: null
        },
        {
          entitlementId: 25,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_WORKER_MSG_CONFIGURATION,
          entitlementDescription: null
        },
        {
          entitlementId: 26,
          entitlementName: ENTITLEMENTS.ADMIN_READ_CL_WORKERMSGASSIGNMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 27,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_WORKERMSGASSIGNMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 28,
          entitlementName: ENTITLEMENTS.AP_WRITE_CL_OUTOFCOUNTYMASSTRANSFER,
          entitlementDescription: null
        },
        {
          entitlementId: 29,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_WORKERMSGASSIGNMENTS,
          entitlementDescription: null
        }
      ],
      organization: {
        orgId: 1002,
        orgNm: 'MN Test Org',
        extrOrgId: null,
        createUser: 'SYSTEM',
        createDate: '2018-05-28T10:38:43.000Z',
        lastUpdateUser: 'SYSTEM',
        lastUpdateDate: '2019-07-31T17:22:14.923Z',
        tenantId: 2,
        startDate: '2018-09-26T14:38:23.000Z',
        endDate: null,
        overview: null,
        webAddress: null,
        servicesOffered: null,
        useParentInfo: null,
        parentOrgId: null,
        multilingualDisplayName: {
          en: 'MN Test Org',
          es: 'TRANSLATE: MN Test Org'
        }
      },
      userId: null,
      dismissInactiveNotifyFlag: 'N',
      adjustMethod: {
        category: 'USER_ROLE_ADJUSTMENT',
        subCategory: 'USER_ROLE_ADJUSTMENT_METHOD',
        languageCode: 'en',
        key: 'URURAM07112',
        value: 'System',
        rulesEngineCode: null,
        sortOrder: 9999
      }
    },
    {
      userRoleId: 103521,
      startDate: '2019-08-12T14:26:24.190Z',
      endDate: '2019-08-12T21:05:59.740Z',
      createdDate: '2019-08-12T14:26:24.190Z',
      createdUser: null,
      lastUpdateDate: '2019-08-12T21:05:59.740Z',
      lastUpdateUser: null,
      uuid: '174489e4-ec7e-4145-8027-381e1279b5d0',
      role: {
        roleId: 8541,
        roleName: 'UserStoryRole',
        roleDescription: null,
        createdUser: 'IEAutomation2',
        lastUpdateUser: null,
        tenantId: 2,
        status: 'InActive',
        numUsers: null,
        displayName: 'User story test',
        inactiveAfterNumber: 1,
        inactiveAfterUnit: {
          category: 'ROLE_INACTIVITY',
          subCategory: 'ROLE_INACTIVITY_UNIT',
          languageCode: 'en',
          key: 'RIRUDD12904',
          value: 'Days',
          rulesEngineCode: null,
          sortOrder: 9999
        },
        startDate: '2019-08-12T05:00:00.000Z',
        endDate: '2019-08-11T05:00:00.000Z',
        createdDate: '2019-08-12T14:12:50.120Z',
        lastUpdateDate: '2019-08-12T17:13:57.707Z',
        multilingualDescription: {
          en: null
        },
        multilingualDisplayName: {
          en: 'User story test'
        },
        isOrgRequired: true
      },
      entitlements: [
        {
          entitlementId: 2752,
          entitlementName: 'McutFAqc',
          entitlementDescription: 'McutFAqc',
          createdUser: '7b14762a-d7cc-4fcb-8313-3e094e0a1814',
          lastUpdateUser: 'SYSTEM',
          tenantId: 2,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'PRY',
            languageCode: 'en',
            key: 'ENCAPR07263',
            value: 'Primary',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          createdDate: '2019-07-17T11:32:22.030Z',
          lastUpdateDate: '2019-07-29T18:52:10.880Z'
        }
      ],
      organization: {
        orgId: 1002,
        orgNm: 'MN Test Org',
        extrOrgId: null,
        createUser: 'SYSTEM',
        createDate: '2018-05-28T10:38:43.000Z',
        lastUpdateUser: 'SYSTEM',
        lastUpdateDate: '2019-07-31T17:22:14.923Z',
        tenantId: 2,
        startDate: '2018-09-26T14:38:23.000Z',
        endDate: null,
        overview: null,
        webAddress: null,
        servicesOffered: null,
        useParentInfo: null,
        parentOrgId: null,
        multilingualDisplayName: {
          en: 'MN Test Org',
          es: 'TRANSLATE: MN Test Org'
        }
      },
      userId: null,
      dismissInactiveNotifyFlag: 'N',
      adjustMethod: {
        category: 'USER_ROLE_ADJUSTMENT',
        subCategory: 'USER_ROLE_ADJUSTMENT_METHOD',
        languageCode: 'en',
        key: 'URURAM07112',
        value: 'System',
        rulesEngineCode: null,
        sortOrder: 9999
      }
    }
  ]
}

export function storeTANFAssistanceDetails(details) {
  return {
    type: 'TANF_ASSISTANCE_DETAILS',
    payload : details
  }
}

export function setAppCreationDate(applicationCreationDate) {
  return {
    type: 'SET_APPLICATION_CREATION_DATE',
    applicationCreationDate
  }
}