import { get } from 'lodash'
export const sanitizeInput = (inputStr) => {
    let sanitizedStr = inputStr;
    let previousStr;
    do {
        previousStr = sanitizedStr;
        sanitizedStr = sanitizedStr.replace(/<script\b[^>]*>([\s\S]*?)<\/script\s*[^>]*>/gi, '');
    } while (sanitizedStr !== previousStr);
    const encodedStr = sanitizedStr.replace(/[<>"]/g, '');
    return encodedStr;
}


export const extractRequiredAuthDetails = (auth) => {
    let authUserFullName = (get(auth, 'userAccount.firstName') ?  get(auth, 'userAccount.firstName') : "" ) + (get(auth, 'userAccount.lastName') ? " " + get(auth, 'userAccount.lastName') : "" )
    const uuid = get(auth, 'userAccount.uuid') 
        return {
        uuid,
        userId: uuid,
        userFullName:  authUserFullName,
        userName: get(auth, 'userAccount.userId')
      }
}