import * as React from 'react'
import { config } from '../../../../config'
import { MultitenantLoadable, withFormComponents } from '@optum-wvie/dynamic-ui-shared/src/utils'
import { ErrorBoundary } from '../Errors'
const tenantDirectoryName = config.tenant.code.toLowerCase()

const ClaimsContainer = MultitenantLoadable(
  () => import(/* webpackChunkName: "ClaimsContainer" */ './ClaimsContainer').catch((error) => console.log(error)),
  () => import(/* webpackChunkName: "ClaimsContainer" */ './ClaimsContainer').catch((error) => console.log(error))

)

const ClaimsPresentation = withFormComponents(MultitenantLoadable(
  () =>
    import(/* webpackChunkName: "ClaimsPresentation" */ './ClaimsPresentation').catch((error) => console.log(error)),
  () =>
    import(/* webpackChunkName: "ClaimsPresentation" */ './ClaimsPresentation').catch((error) => console.log(error))
    ),
    () => import('./formComponents').catch((error) => console.log(error)),
    () => import('./formComponents').catch((error) => console.log(error))
)

export default function Claims(props) {
  return (
    <ErrorBoundary name="Claims">
      <ClaimsContainer
        {...props}
        presentation={presentationProps => (
          <ClaimsPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}
