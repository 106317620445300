import * as React from 'react'
import { config } from '../../../../config'
import { MultitenantLoadable, withFormComponents } from '@optum-wvie/dynamic-ui-shared/src/utils'
import { ErrorBoundary } from '../Errors'
const tenantDirectoryName = config.tenant.code.toLowerCase()

const TopInformationsContainer = MultitenantLoadable(
  () => import(/* webpackChunkName: "TopInformationsContainer" */ './TopInformationsContainer'),
  () =>
    import(
      /* webpackChunkName: "[request]" */ '../../' +
        tenantDirectoryName +
        '/TopInformations/TopInformationsContainer'
    )
)

const TopInformationsPresentation = withFormComponents(MultitenantLoadable(
  () =>
    import(/* webpackChunkName: "TopInformationsPresentation" */ './TopInformationsPresentation'),
  () =>
    import(
      /* webpackChunkName: "[request]" */ '../../' +
        tenantDirectoryName +
        '/TopInformations/TopInformationsPresentation'
    )
    ),
    () => import('./formComponents'),
  () =>
    import(
      '../../' +
        tenantDirectoryName +
        '/TopInformations/formComponents'
    )
)

export default function TopInformations(props) {
  return (
    <ErrorBoundary name="TopInformations">
      <TopInformationsContainer
        {...props}
        presentation={presentationProps => (
          <TopInformationsPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}
