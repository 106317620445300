import * as _ from 'lodash';
import * as queryString from 'query-string';
import SessionStorage from '@optum-wvie/dynamic-ui-framework/src/sessionStorage';

//////////////////////////////////////////////////////////////////////////////////////
//START TENANT CONFIG SECTION
//Update this section for every new tenant. The functions must be synchronous.
import {
  isImplemented as isImpl_WV,
  getConfig as getConfig_WV,
  determineEnvironment as getEnv_WV,
} from './config_WV';

const TENANTS = {
  WV: {
    isImpl: isImpl_WV,
    getEnv: getEnv_WV,
    getConfig: getConfig_WV,
  },
};

//END TENANT CONFIG SECTION
//////////////////////////////////////////////////////////////////////////////////////

let environment: string = null;
let tenantCode: string = null;

_.forEach(TENANTS, (value, key) => {
  if (value.isImpl()) {
    //We are in an implementation build.
    tenantCode = key;
    environment = value.getEnv();
    return false;
  }
});

if (!environment) {
  environment = determineBaseEnvironment();
}

if (!tenantCode) {
  tenantCode = determineBaseTenant(environment);
  SessionStorage.setItem(`IE-${environment}-CLP-TENANT`, tenantCode);
}

const getTenantConfig = TENANTS[tenantCode].getConfig;

export let config = initConfig();

function initConfig() {
  //Retrieve config for all enviroments.
  let baseConfig: any = {
    environment,
    languages: [
      {
        locale: 'en', //TODO: dialect?
        name: 'English',
      },
      {
        locale: 'es',
        name: 'Español',
      },
    ],
    removeCCAndCSLOB: false,
    ignoreUARNetworkId: 'Z999999',
    appProcessingPath: '/api/ie/applicationProcessing/v2',
    domainPath: '/api/ie/domain/v2',
    formPath: '/api/ie/forms/{tenantCode}/v3.0',
    defaultExpiryMs: 15 * 60000,
    countdownTimerMs: 5 * 60000,
    portalName: 'clp',
    tenant_c: 'wv',
    tenant_id: 1,
    openId_clientId: 'CLPWV',
    openId_grantType: 'authorization_code',
    authorizationPath: '/api/ie/usrmgmt/v2/authorize',
    openId_clientSecret:
      '78fc015190d353edf398b9d898e1d5fc3cfcfeef90463cfeb3aac6604a3e807c67de2bc707be79b369292f9c07c71c8c',

    //@BIBR endpoints 
    forms_benefitRecoverySimulation:
      '{formGatewayBaseUrl}{formPath}/ap/BenefitRecoverySimulation',
      fetchBenefitRecClaims:
      '{gatewayBaseUrl}{financePath}/{tenantId}/claims/{claimNumber}',
      benf_rec_Simulation_compositeData:
      '{gatewayBaseUrl}{financePath}/{tenantId}/benfitrecoveryreferral/simulation/caseSearch',
    eligibilityApiForSimulation:
      '{gatewayBaseUrl}{eligibilityPathV3}/{tenantId}/simulateCaseEligibility/case/{caseId}',
      clientDetails:
      '{gatewayBaseUrl}{benefitAppPath}/cases/{caseId}/client-details',
      confirmEligibiliyEndPoint:
      '{gatewayBaseUrl}{eligibilityPathV3}/{tenantId}/preacceptance/{caseId}',
      getCompositeResponse:
      '{gatewayBaseUrl}{compositePath}/{tenantId}/case/{caseId}',
      simulationHistory:
      '{gatewayBaseUrl}{financePath}/{tenantId}/benfitrecoveryreferral/simulation/simulationHistory/search',
    saveSimulationHistory:
      '{gatewayBaseUrl}{financePath}/{tenantId}/benfitrecoveryreferral/simulation/simulationHistory/',
      searchClientInfo:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/clients/personal-info/search?includeClientDetails=false',
      getCCAClientDetails:
      '{gatewayBaseUrl}{financePath}/{tenantId}/benfitrecoveryreferral/{caseId}/clientDetails',
      claimStatushistoryUrl:
      '{gatewayBaseUrl}{financePath}/{tenantId}/claims/{claimNumber}/claimstatushistory',
      forms_benefitRecoveryClaimDetails:
      '{formGatewayBaseUrl}{formPath}/ap/BenefitRecoveryClaimDetails',
      forms_benefitRecoveryClaimDetailsSchema:'{formGatewayBaseUrl}{formPath}/ap/BenefitRecoveryClaimDetailsSchema',
      forms_invoicehistoryModalForm:
      '{formGatewayBaseUrl}{formPath}/ap/InvoiceStatusHistoryPopupSchema',
      fetchWorkerAndCountyRelations: //'{gatewayBaseUrl}{userManagementPath}/{tenantCode}/orgs/workers/role/county',
      "{gatewayBaseUrl}/api/ie/benefitrecovery/1/case/{caseId}/getWorkersList",
      
      overridePaymentSearch:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/benfitrecoveryreferral/recoveryoverride/search/{claimNumber}',
      // "http://10.133.8.158:8095/api/ie/benefitrecovery/1/benfitrecoveryreferral/recoveryoverride/search/{claimNumber}",
      overridePaymentSubmit:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/benfitrecoveryreferral/recoveryoverride',
      // "http://10.133.74.114:8095/api/ie/benefitrecovery/1/benfitrecoveryreferral/recoveryoverride",

      forms_BenefitRecoveryOverrideInfo:
      '{formGatewayBaseUrl}{formPath}/ap/BenefitRecoveryOverrideInfo_FA',
      forms_BenefitRecoveryOverrideInfoSchema:'{formGatewayBaseUrl}{formPath}/ap/BenefitRecoveryOverrideInfoSchema',
      forms_claimSearch: '{formGatewayBaseUrl}{formPath}/ap/ClaimSearchSchema',
      forms_viewCommentPopup: '{formGatewayBaseUrl}{formPath}/ap/CCPViewComment',
      searchPaymentAgreement:
      '{gatewayBaseUrl}{financePath}/{tenantId}/payments/repayAgreement/benefitPrograms/{benfProgCode}?clientId={clientId}',
      submitPaymentAgreement:
      '{gatewayBaseUrl}{financePath}/{tenantId}/payments/saveRepayAggrement',
      searchPaymentAgreementProvider:
      '{gatewayBaseUrl}{financePath}/{tenantId}/payments/repayAgreement/benefitPrograms/{benfProgCode}?providerId={providerId}',
      viewUploadedDocPaymentAgreementEndpoint:
      '{gatewayBaseUrl}{financePath}/{tenantId}/payments/retrieveDocument/{docId}',
      uploadDocPaymentAgreementEndpoint:
      '{gatewayBaseUrl}{financePath}/{tenantId}/payments/uploadDocument',
      forms_paymentAgreement: '{formGatewayBaseUrl}{formPath}/ap/PaymentAgreement',
      claimsActivePrograms:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/claims/programs/clients/{clientId}',
      // 'http://10.133.74.114:8095/api/ie/benefitrecovery/1/claims/programs/clients/{clientId}',
      getBenefitCatalog:
    '{gatewayBaseUrl}{referenceDatatPath}/{tenantCode}/benefit-programs/catalog',
    forms_postClaimPayments: '{formGatewayBaseUrl}{formPath}/ap/claimPayments_FA',
      fetchPostClaimPaymentsProvider:
      '{gatewayBaseUrl}{financePath}/{tenantId}/claims/claimPayments?providerId={providerId}&programCode={benefitProgram}',
      fetchPostClaimPayments:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/claims/claimPayments?clientId={clientId}&programCode=',
      // "http://10.133.74.114:8095/api/ie/benefitrecovery/1/claims/claimPayments?clientId={clientId}&programCode=",
      submitPostClaimPayments:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/claims/claimPayments',
      // 'http://10.43.49.220:8095/api/ie/benefitrecovery/1/claims/claimPayments',
      searchclaimdetails:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/claims/search/{claimNumber}',
      searchReversePayments:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/claims/reversePayments?claimNumber={claimNumber}&paymentFor={paymentFor}',
      // "http://10.133.74.114:8095/api/ie/benefitrecovery/1/claims/reversePayments?claimNumber={claimNumber}&paymentFor={paymentFor}",
      submitReversePayments:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/claims/paymentreversal',
      // "http://10.133.74.114:8095/api/ie/benefitrecovery/1/claims/paymentreversal",
      forms_reversePayments: '{formGatewayBaseUrl}{formPath}/ap/ReversePayments_FA',
      claimDetailsSearch:
      '{gatewayBaseUrl}{financePath}/{tenantId}/claims/claimdetails/search',
      getActiveProgramsForCase:
      // '{gatewayBaseUrl}{eligibilityPathV3}/{tenantId}/benefitprograms/{caseId}',
      "{gatewayBaseUrl}/api/ie/benefitrecovery/1/case/{caseId}/caseEligibilityInfo",

      eligibilityRunResults:
      '{gatewayBaseUrl}{eligibilityPathV3}/{tenantId}/loadAssistanceGroupDetails/{caseId}/{agSeqNum}',
      forms_eligibilityRunResults:
      '{formGatewayBaseUrl}{formPath}/ap/EligibilityRunResults',
      getHealthTabAPI:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/cases/{caseId}/health-tab',
      overPaymentSubmit:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/payments/overpayments',
      // "http://10.43.49.220:8095/api/ie/benefitrecovery/1/payments/overpayments",
      fetchOverPayments:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/payments/{claimNumber}/overpayments',
      // "http://10.43.49.220:8095/api/ie/benefitrecovery/1/payments/{claimNumber}/overpayments",
      forms_overIssuanceDetails: '{formGatewayBaseUrl}{formPath}/ap/OverIssuanceDetails_FA',
      forms_providerLookupWidget: '{formsBaseUrl}/ap/ProviderLookupWidget',
      forms_workerLookupWidget: '{formsBaseUrl}/ap/WorkerLookupWidget',
      forms_benefitLookupWidget: '{formGatewayBaseUrl}{formPath}/ap/BenefitLookupWidget',
      forms_clientLookupWidget:
      '{formGatewayBaseUrl}{formPath}/ap/ClientLookupWidget',


      // BR API 
      referralSearch:
      // '{gatewayBaseUrl}{financePath}/{tenantId}/benfitrecoveryreferral/search',
      "{gatewayBaseUrl}/api/ie/benefitrecovery/1/benfitrecoveryreferral/search",


      searchInvestigation:
      // '{gatewayBaseUrl}{financePath}/{tenantId}/referrals/{referralnumber}',
      "{gatewayBaseUrl}/api/ie/benefitrecovery/1/referrals/{referralnumber}",
      submitInvestigation:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/referrals/investigation',
      submitProsecution:
      // '{gatewayBaseUrl}{financePath}/{tenantId}/referrals/prosecution',
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/referrals/prosecution',
      getWorkersFromGovId:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/getGovIdWorkerList?govId=',

      forms_prosecutionInformation:
      '{formGatewayBaseUrl}{formPath}/ap/ProsecutionInformationSchema_FA',
      forms_investigationFindings:
      '{formGatewayBaseUrl}{formPath}/ap/InvestigationFindingsSchema_FA',
      submitBenefitRecoveryReferralProvider:
      '{gatewayBaseUrl}{financePath}/{tenantId}/benfitrecoveryreferral/provider',
      forms_BenefitRecoveryReferralSchema:
      '{formGatewayBaseUrl}{formPath}/ap/BenefitRecoveryReferral_BR',
      createNewReferral:
      // '{gatewayBaseUrl}{financePath}/{tenantId}/benfitrecoveryreferral',
      "{gatewayBaseUrl}/api/ie/benefitrecovery/1/benfitrecoveryreferral",
      
      // searchExternalClientDetails:
      // '{gatewayBaseUrl}{appProcessingPath}/{tenantCode}/case/0/clients/details?externalCaseId={externalCaseId}',

      searchExternalClientDetails:
      // '{gatewayBaseUrl}/api/ie/benefitrecovery/1/case/{externalCaseId}/getCaseInfo',
      "{gatewayBaseUrl}/api/ie/benefitrecovery/1/case/{externalCaseId}/getCaseInfo",
      getReferralInformationEndpoint:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/1/referrals/{referralNumber}',
      claimSearchProviderResults:
      '{gatewayBaseUrl}{financePath}/{tenantId}/referrals/provider/search',
      forms_ReferralSearchSchema:
      '{formGatewayBaseUrl}{formPath}/ap/ReferralSearchSchema_FA',
      forms_providerSearchForm: '{formGatewayBaseUrl}{formPath}/ap/ProviderSearch',
      financePath: '/api/ie/finance/v1.0',
      authorizationUserinfoEndpoint:
      '{gatewayBaseUrl}{authorizationPath}/{tenantCode}/userinfo-v2',




      //Top information
      search_TopTracking:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/{tenantId}/top/tracking/search',
      forms_TopTracking: '{formGatewayBaseUrl}{formPath}/ap/TopTracking',
      fetchClaimNumber:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/{tenantId}/top/claims/{clientId}',
      searchTopInformation:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/{tenantId}/top/{clientId}/claims?claimNumber={claimNumber}',
      submitTopInformation:
      '{gatewayBaseUrl}/api/ie/benefitrecovery/{tenantId}/top/{clientId}/claims',
      generateBINoticeEndpoint:
      '{gatewayBaseUrl}{financePath}/{tenantId}/top/createNotice',
      forms_topInfoSchema: '{formGatewayBaseUrl}{formPath}/ap/TopInformation',




    //CLP Forms Endpoints
    forms_clientScheduler: '/clp/ClientScheduler',
    forms_ConfigurationTab: '/clp/ConfigurationTab',
    forms_scheduleAppointment: '/clp/ScheduleAppointment',
    forms_ClientSearchLookup: '/clp/ClientSearchLookup',
    forms_ViewMaintainAppointment: '/clp/ViewMaintainAppointment',
    forms_CreateSchedule: '/clp/CreateSchedule',
    forms_Calendar: '/clp/Calendar',
    forms_MyConfiguration: '/clp/MyConfiguration',
    forms_PrintForms: '/clp/printForms',
    forms_MdmSearchForms: '/clp/MdmSearch',
    forms_quickLinksForms: '/clp/QuickLinks',
    forms_HeaderSerarch: '/clp/HeaderSearch',
    forms_InboxWidgetForm: '/clp/InboxWidget',

    //Error Message Json
    validationErrorMsgs_RecLogErrors: '/clp/ReceptionLogErrors',
    validationErrorMsgs_MdmSearch: '/clp/MdmSearchErrors',
    validationErrorMsgs_ScheduleApptErrors: '/clp/ScheduleAppointmentErrors',
    validationErrorMsgs_ConfigErrors: '/clp/SchedulingConfigurationErrors',
    validationErrorMsgs_CreateSchedulErrors: '/clp/CreateScheduleErrors',
    validationErrorMsgs_ViewMaintain: '/clp/ViewMaintainErrors',
    validationErrorMsgs_LookupModlErrors: '/clp/LookupModalErrors',
    validationCLPCS_ErrorMsgs: '/clp/ClientSchedularErrors',

    //WV specific Java endpoints
    GetDropDownValuesForUUID:
      '/WVIE/clientscheduling/v1.0/getCSCodeReferUIData',
    getCSWorkerProfileData:
      '/WVIE/clientscheduling/v1.0/getCSWorkerProfileData',
    statecounty: '/WVIE/stcnty/v1.0/statecounty?state={state}',
    viewReceptionLogData: '/WVIE/clientscheduling/v1.0/viewReceptionLogData',
    viewAndSearchRecptionLogData:
      '/WVIE/clientscheduling/v1.0/filterViewReceptionLog',
    saveAddEntryDetails: '/WVIE/clientscheduling/v1.0/saveAddEntryDetails',
    getCountyConfig: '/WVIE/clientscheduling/v1.0/getCountyConfig',
    saveOfficeLevelCountyConfig:
      '/WVIE/clientscheduling/v1.0/saveOfficeLevelCountyConfig',
    getUnitLevelConfig: '/WVIE/clientscheduling/v1.0/getUnitLevelConfig',
    saveUnitLevelConfiguration:
      '/WVIE/clientscheduling/v1.0/saveUnitLevelConfiguration',
    getWorkerLevelConfig: '/WVIE/clientscheduling/v1.0/getWorkerLevelConfig',
    saveWorkerLevelConfiguration:
      '/WVIE/clientscheduling/v1.0/saveWorkerLevelConfiguration',
    saveClientAppointments:
      '/WVIE/clientscheduling/v1.0/saveClientAppointments',
    retrieveReceptionLogEntryData:
      '/WVIE/clientscheduling/v1.0/retrieveReceptionLogEntryData',
    getReceptionEntryHistoryDetails:
      '/WVIE/clientscheduling/v1.0/getReceptionEntryHistoryDetails',
    updateStatusForReceptionEntryDetails:
      '/WVIE/clientscheduling/v1.0/updateStatusForReceptionEntryDetails',
    searchForClientInformation: '/wv/tenantWrapper/getclientInformation',
    getWorkerSchdApptTimeSlot:
      '/WVIE/clientscheduling/v1.0/getWorkerSchdApptTimeSlot',
    searchClientAppointments:
      '/WVIE/clientscheduling/v1.0/searchClientAppointments',
    retrieveClientSchdAppointmentDetails:
      '/WVIE/clientscheduling/v1.0/retrieveClientSchdAppointmentDetails',
    saveMultiReviewAppointments:
      '/WVIE/clientscheduling/v1.0/saveMultiReviewAppointments',
    updateStatusForMultipleAppointments:
      '/WVIE/clientscheduling/v1.0/updateStatusForMultipleAppointments',
    monthlyScheduleSubmit: '/WVIE/clientscheduling/v1.0/createMonthlySchedule',
    unitScheduleSubmit: '/WVIE/clientscheduling/v1.0/createUnitSchedule',
    retrieveUnitScheduleVersion:
      '/WVIE/clientscheduling/v1.0/getUnitScheduleVersionList',
    retrieveMonthlySchedRecurDetails:
      '/WVIE/clientscheduling/v1.0/retrieveMonthlySchedRecurDetails ',
    retrieveUnitSchedRecurDetails:
      '/WVIE/clientscheduling/v1.0/retrieveUnitSchedRecurDetails',
    retrieveMyCalEvents:
      '/WVIE/clientscheduling/v1.0/retrieveSchedCalendarEvents',
    deleteSchedRecurrenceAppointments:
      '/WVIE/clientscheduling/v1.0/deleteSchedRecurrenceAppointments',
    retrieveUnitSchedCalEvents:
      '/WVIE/clientscheduling/v1.0/retrieveUnitSchedCalendarEvents',
    updateMonthlySchedRecurrence:
      '/WVIE/clientscheduling/v1.0/updateMonthlySchedRecurrence',
    createWorkerEnforceUnitSched:
      '/WVIE/clientscheduling/v1.0/createWorkerEnforceUnitSched',
    getEnforceUnitSchedData:
      '/WVIE/clientscheduling/v1.0/getEnforceUnitSchedData',
    deleteWorkerEnforceUnitSched:
      '/WVIE/clientscheduling/v1.0/deleteWorkerEnforceUnitSched',
    updateUnitSchedRecurrence:
      '/WVIE/clientscheduling/v1.0/updateUnitSchedRecurrence',
    getAlertNotifications: '/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
    updateAlertNotifications:
      '/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
    searchNextAvailableWorker:
      '/WVIE/clientscheduling/v1.0/searchNextAvailWorkerOrTimeAppt',
    pathSearchLookup: '/WVIE/clientscheduling/v1.0/searchPathAppInfo',
    getWidgetList: '/WVIE/CLPWidgets/v1.0/getCLPWidgetsData',
    updateWidgetList: '/WVIE/CLPWidgets/v1.0/updateCLPWidgetsData',
    getMonthlyCalEvents:
      '/WVIE/clientscheduling/v1.0/retrieveMonthlyCalendarEvents',
    getWorkerlevelAlertConfig:
      '/WVIE/clientscheduling/v1.0/getCLPWorkerLevelAlertConfig',
    getPATHPrintFormIDList: '/WVIE/CLPForms/v1.0/getPATHPrintFormIDList',
    retrievePATHPrintFormData: '/WVIE/CLPForms/v1.0/retrievePATHPrintFormData',
    getErrorCodes: '/api/ie/referencedata/v2/{tenantCode}/errors/codes',
    getQuickLinks: '/WVIE/CLPForms/v1.0/getQuickLinkURLList',
    getInboxData: '/WVIE/CLPWidgets/v1.0/getPATHInboxWidgetData',

    // WV specific Mule endpoints
    getStateCountyList:
      '/optum/services/wv/clientscheduling/getStateCountyList',
    personSearchMatch: '/optum/services/mdm/individualMatch',
    personSearch: '/optum/services/mdm/individual',
    personMasterRecord: '/optum/services/mdm/individualMasterRecord',
    providerSearchMatch: '/optum/services/mdm/provEmpLocMatch',
    providerSearch: '/optum/services/mdm/provEmpLocSearch',
    providerMasterRecord: '/optum/services/mdm/provEmpLocMasterRecord',
    sortingColumnAPI: '/optum/services/mdm/sorting',

    // Office-365 (Outlook)Specific Endpoints
    getAccessTokenFromRefresh:
      'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/token',
    getAccessAndRefresh:
      'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/token',
    logOutOffice365:
      'https://login.microsoftonline.com/wv.gov/oauth2/logout?post_logout_redirect_uri=',
    getEvents: 'https://graph.microsoft.com/v1.0/me/calendarview?$top=1000&',
    getOutlookConflictList:
      'https://graph.microsoft.com/v1.0/me/calendar/getSchedule',

    // Admin Portal Brodacast messages endpoints
    getBroadCastAlerts: '/api/ie/domain/v2/wv/broadcasts/messages',
    dismissBroadCastAlerts: '/api/ie/domain/v2/wv/broadcasts/{alertId}/messages/dismiss',

    // CS specific endpoints
    csUserUpdate: '/oscar-service/api/cs/v1/agent/unique/',
    docMgmtInsert: '/oscar-service/api/cs/v1/documentMgmt/insert',
    getCountyWorkers: '/oscar-service/api/cs/v1/getCountyWorkers/',
    getCaseBasedServiceSites: '/oscar-service/api/cs/v1/getCaseServiceSiteAddress/{CASE_NUMBER}',

    // MDM redirect URL on SSO Steward link
    mdmStewardUrl:
      'http://rapmdmd1:8080/e360/com.informatica.tools.mdm.web.auth/login',

    // User Access forms load Endpoints
    userAccess: '{userAccessFormBaseUrl}/shared/UserAccess',
    userAccessRequest:
      '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests',
    userAccessRequests:
      '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests',
    roles: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/roles',
    orgs: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/orgs',
    allOrgs: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/all-orgs',
    accessRequest:
      '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests/{accessRequestId}',
    searchCounties: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantId}/counties',
    userAccessRequestsSearch:
      '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests/search',

    // User Management Endpoints
    userInfo: '/api/ie/usrmgmt/v2/authorize/wv/userinfo-v2',
    singleSignOn: '/api/ie/usrmgmt/v2/authorize/{tenantCode}/sso',
    revokeToken: '/api/ie/usrmgmt/v2/authorize/{tenantCode}/token/revoke',
    isUserLoggedIn: '/api/ie/usrmgmt/v2/{tenantId}/isuserloggedin',

    // Interactive Forms Endpoints
    getCincomXml: '/wvpath/forms/v1/getRequestPayload',
    getSingleAptnoticeForm: '/wvpath/forms/v1/correspondence/7151',
    getDocument: '/wvpath/forms/v1/getDocument',

    //IncientForm Changes
    forms_IncidentForm: '/clp/IncidentForm',
    getUserAccountDetails:
      '{gatewayBaseUrl}{appProcessingPath}/secure/getUserAccountDetails/{tenantCode}',

    // getUserAccountDetails:
    // '{gatewayBaseUrl}/api/ie/applicationProcessing/v3/{tenantCode}/user/account/details',
    gatewayBaseUrlSsWv: '/services/wv/self-service-portal/incident/create',
    form_timeStudyEntry:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudySheetEntry',
    form_timeStudyEntryRODCA:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudySheetEntryRODCA',
    form_timeStudyDashboardSupervisor:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyDashSupervisor',
    form_timeStudyDashboardWorker:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyDashWorker',
    form_timeStudyWorkerHistory:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyWorkerHistory',
    form_timeStudySupervisorHistory:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudySupervisorHistory',
    form_timeStudyReports: '{formGatewayBaseUrl}{formPath}/ap/TimeStudyReports',
    form_timeStudyCategoryReasonCodes:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyRODCAHelper',
    form_timeStudyCategoryStatusCodes:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyHelper',
    form_timeStudySearchIntakeModal:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudySearchIntakeModal',
    form_timeStudyAdmin:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyAdmin',
    fetchScheduledTimeStudy:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/timesheet/searchTimeSchedule',
    saveScheduledTimeStudy:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/timesheet/createSchedule',
    form_timeStudyAddModal:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyAdd',
    timeStudyTsGet:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/timesheet/{controlId}',
    timeStudyTsCreate:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/timesheet/create',
    timeStudyDashboard:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/dashboard/{orgId}',
    timeStudyClientSearchCount:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/applications/client-search/count',
    timeStudyClientSearch:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/applications/client-search',
    timeStudyEntryRemove:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/timesheet/delete/detailId/{detailId}/controlId/{controlId}/seqNbr/{seqNbr}',
    timeStudyCaseSearch:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/cases/search',
    timeStudyIntakeOrClientSearch:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/intakeOrClient/search',
    timeStudyProviderSearch:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/provider/search',
    timeStudyFetchHistory:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/history',
    searchUsers: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/users/search',
    accountLookup: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests/accessrequest/useraccountlookupdetail/{uuid}',
    checkNetworkId: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests/networkIdExists',
    blueZoneLink: 'blueZone:',
    printForms_ChildSupport:'/wvpath/forms/v1/print/{formId}',
    emailNotification: '/services/esb/mule/framework/v2.0/notification/email',
    getPLCRoles:
    '{gatewayBaseUrl}{domainPath}/{tenantCode}/getPLCRole',
    claimSearch:'/api/ie/benefitrecovery/1/claim/search',
    clientLookup:
    '/api/ie/benefitrecovery/1/client/details?clientId={clientId}&firstName={firstName}&lastName={lastName}',
    claimDetailsSearch_ReferalNumber:'/api/ie/benefitrecovery/1/claim/{claimNumber}',
    submitBenefitRecClaims:
      '/api/ie/benefitrecovery/1/claim/submit',
    paymentAgreement_getBenefitProgram:'/api/ie/benefitrecovery/1/indvProgCodes/{clientId}',
    search_paymentAgreement:'/api/ie/benefitrecovery/1/indvRepayAgreement/{clientId}/{benfProgCode}',
    submit_paymentAgreement:'/api/ie/benefitrecovery/1/saveIndvRepayAgreement'

  };

  //Retrieve environment-level config.
  switch (environment) {
    case 'LOCAL':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        // matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        // matomoSiteId: null,
        // matomoCustomDimensionGovIdUsername: null,
        // matomoCustomDimensionCorrelationId: null,
        cincomUrl: 'https://apved54358.dmzmgmt.uhc.com:9380/WV_Dev02_CEWeb/api',
        // gatewayWvUrl: 'https://wvie-api-gw2.optum.com',
        gatewayIeUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        //gatewayIeUrl: 'https://ie-apigw-tst.optum.com',
        //gatewayIeUrl: "http://localhost:8080",
        gatewayMDMWvUrl: 'https://wvie-api-gw-test.optum.com',
        //Edit this variable to target localhost fetches.
        // formsBaseUrl:
        // 'https://wvie-api-gw-test2.optum.com/api/ie/forms/wv/{version}',
        // formsBaseUrl: "https://wvie-apigw-test-dmz.optum.com/api/ie/forms/wv/{version}",
        // // formGatewayBaseUrl:
        // // 'https://wvie-api-gw-test2.optum.com',
        // formGatewayBaseUrl: // "https://agency-portal-fe-wvie-test-k8s-ctc-dmz-nonprod.optum.com",//
        formGatewayBaseUrl: 'http://localhost:8092', //'https://wvie-apigw-test-dmz.optum.com',
        formsBaseUrl:'http://localhost:8092/api/ie/forms/{tenantCode}/{version}',// 'https://wvie-apigw-test-dmz.optum.com/api/ie/forms/{tenantCode}/{version}',
        // formsBaseUrl:
        //   'https://wvie-api-gw-uat2.optum.com/api/ie/forms/wv/{version}',
        // formGatewayBaseUrl:'https://wvie-api-gw-uat2.optum.com',
        csGateway: 'https://test2-nonprod-oscar-k8s-ctc-dmz-nonprod.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw-test.optum.com',
        // openId_loginUrl:
        //   'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        // openId_redirectUrl: 'http://localhost:9000/ie-portal-webapp/react/home',
        ofc36dRedirectUrl: 'https://wvie-clp-dev.optum.com/home',
        authorizationTokenEndpoint:
          'https://hcck8s-ie-apigw-dev.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-dev.optum.com/home',
        refreshTokenEndpoint:
          'https://hcck8s-ie-apigw-tst.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        clientPortalUrl: 'https://wvie-client-cs-test.optum.com/',
        // CS profile
        openId_localhostAccount: {
          uuid: "'8fa7baeb-1c69-4da5-9dc8-a57b97f295a7'",
          userId: "akunduCCA",
          firstName: "arikam",
          lastName: "Navigation",
          dob: "1984-11-12",
          email: "clpnavigation@getnada.com",
          defaultUserRoleId: null
        },

        environment: 'LOCAL',
        isProd: false,
        DebugMode: true,
        gatewayBaseUrl: 
        'https://hcck8s-ie-apigw-tst.optum.com',
        //'https://ie-api-gw-test.optum.com',
        // formGatewayBaseUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        formsQCBaseUrl:
          'https://hcck8s-ie-apigw-tst.optum.com/api/ie/qc-forms/{tenantCode}/2.0',
        //'http://localhost:7070/api/ie/forms/{tenantCode}/2.0',
        // formsBaseUrl:
        //   'https://hcck8s-ie-apigw-tst.optum.com/api/ie/forms/{tenantCode}/{version}',
        //'http://localhost:7070/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAP&response_type=code&redirect_uri=https%3A%2F%2Flocalhost%3A8082%2Fhome&scope=openid+profile+email&state={state}&nonce=',
        useGovId: false,
        cincomGatewayUrl: 'https://wvie-cincom-sit1.optum.com:8380/WV_Tst02_CEWeb/api',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: null,
        matomoCustomDimensionCorrelationId: null,
        isAuditingServiceEnabled: true,
        //"gatewayBaseUrl": "https://wv-ie-api-gw-tst.optum.com" //TODO
        gatewayWvUrl: 'https://wvie-api-gw-dev.optum.com',
        // formGatewayBaseUrl:'https://wvie-apigw-test-dmz.optum.com',
        // formsBaseUrl:
        //   'https://wvie-apigw-test-dmz.optum.com/api/ie/forms/{tenantCode}/{version}',
        // 'http://localhost:7070/api/ie/forms/{tenantCode}/{version}',
        //  'https://agency-portal-fe-wvie-test.ocp-ctc-dmz-nonprod.optum.com/api/ie/forms/{tenantCode}/{version}',

        //Non KONG URLS: To Be Deleted after KONG gateway Implementation //TODO
        //wv appintake URL
        wvAppIntakeTenantURL: 'https://wrapper-service-wvie-test-k8s-ctc-dmz-nonprod.optum.com',
        wvAppIntakeProcessingPath: '/api/wvie/wrapper/v1.0',
        wvMDMGateWayURL:'https://wvie-api-gw-test.optum.com',
        //MuleEndpoint: "https://wv-apigw-dev1.optum.com",
        MuleEndpoint: 'https://wvie-api-gw-sit1.optum.com',

        //MuleEndpoint: 'https://wvie-api-gw-test.optum.com',
        wvAddressDoctorMule: "https://wvie-api-gw-sit1.optum.com",
        MuleEndpoint_Test:'https://workerprograms-wvie-test-k8s-ctc-dmz-nonprod.optum.com',
        // Financial service URL
        financialServiceURL: "https://wvie-financial-service-wvie-test-k8s-ctc-dmz-nonprod.optum.com",
        financialServiceProcessingPath: "/WVIE/api/financial/v1",
        // benefitIssuanceServiceURL: "https://benefit-issuance-wvie-dev.ocp-ctc-dmz-nonprod.optum.com", //old url
        benefitIssuanceServiceURL: "https://benefit-issuance-wvie-test-k8s-ctc-dmz-nonprod.optum.com", //new url
        logoutRedirectUrl: null,
        HELP_LMS_URL: 'https://learn.optum.com/access/jwt?jwt={jwt}',
        HELP_LMS_API: 'mwu44jyo9sn27pkfehbicceath6fd15s',
        HELP_LMS_SKU: 'wvpath-train'

        // {
        //   dob: "1992-08-01",
        //   email: "naresh.cherukuri@optum.com",
        //   firstName: "Adam",
        //   lastName: "Bose",
        //   userId: "CWEmp5",
        //   uuid: "c20c0c67-cebb-4acb-8c24-07376a8dd280",
        //   defaultUserRoleId: null
        // }
        // IE Profile
        // openId_localhostAccount: {
        //   dob: '1980-08-01',
        //   email: 'renuka.gorre@optum.com',
        //   firstName: 'Kamal',
        //   lastName: 'Test',
        //   userId: 'kamal-testing-clp',
        //   uuid: 'de44be39-ff09-47b9-85fc-def9ba1e3dae',
        //   defaultUserRoleId: null,
        // },
      };
      break;
    case 'NON-OFETEST':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 43,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        //gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
        gatewayIeUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        gatewayMDMWvUrl: 'https://wvie-api-gw-test.optum.com',
        csGateway: 'https://test2-nonprod-oscar-k8s-ctc-dmz-nonprod.optum.com',
        clientSchedulingURL: 'https://wvie-apigw-test-dmz.optum.com',
        formsBaseUrl:
          'https://wvie-apigw-test-dmz.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          "https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=CLPWV&response_type=code&redirect_uri=https%3A%2F%2Ffabr-test.optum.com%2Fhome&scope=openid+profile+email&state={state}&nonce=",
        ofc36dRedirectUrl: 'https://wvie-clp-test.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-test.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clpcs-test.optum.com/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://hcck8s-ie-apigw-tst.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://hcck8s-ie-apigw-tst.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://apved54358.dmzmgmt.uhc.com:9380/WV_Dev02_CEWeb/api',
        apTargetPortalName: 'wvnsfap',
        rdpFlexiLink: 'https://flexi.wvpath.org/RDWeb',
        clientPortalUrl: 'https://wvie-client-cs-test.optum.com/',
        gatewayBaseUrl: 
        'https://hcck8s-ie-apigw-tst.optum.com',
        formGatewayBaseUrl: 'https://wvie-apigw-test-dmz.optum.com'
      };
      break;
      case 'OFESIT':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: 'https://ie-api-gw-sit.optum.com',
        portalName: 'wvfabr',
        matomoSiteId: 43,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        //gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
        gatewayIeUrl: 'https://ie-api-gw-sit.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw-sit2.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          "https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=WVFA0082725&response_type=code&redirect_uri=https%3A%2F%2Fwvie-fabr-sit.optum.com%2Fhome&scope=openid+profile+email&state={state}&nonce=",
        authorizationTokenEndpoint:
          'https://ie-api-gw-sit.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw-sit.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        apTargetPortalName: 'wvfabr',
        clientPortalUrl: 'https://wvie-client-cs-test.optum.com/',
        gatewayBaseUrl: 
        'https://ie-api-gw-sit.optum.com',
        formGatewayBaseUrl: 'https://wvie-api-gw-sit2.optum.com'
      };
      break;

    default:
      baseConfig = {
        ...baseConfig,
        gatewayBaseUrl: 'https://ie-api-gw-dev.optum.com',
      };
  }

  let finalConfig = null;
  if (getTenantConfig) {
    const tenantConfig = getTenantConfig(baseConfig);
    //Merge the tenant configuration after base, so it will take priority.
    finalConfig = _.merge({}, baseConfig, tenantConfig);
  } else {
    finalConfig = baseConfig;
  }

  // apply tenant.id and tenant.code to endpoints
  Object.keys(finalConfig).forEach(key => {
    if (typeof finalConfig[key] === 'string')
      finalConfig[key] = finalConfig[key]
        .replace('{tenantId}', baseConfig.tenant_id)
        .replace('{formPath}', baseConfig['formPath'])
        .replace('{tenantCode}', baseConfig.tenant_c)
        .replace('{gatewayBaseUrl}', baseConfig.gatewayIeUrl)
        .replace(
          '{userAccessFormBaseUrl}',
          baseConfig.formsBaseUrl.replace('{version}', '2.0'),
        )
        .replace('{appProcessingPath}', baseConfig.appProcessingPath)
        .replace('{domainPath}', baseConfig['domainPath'])
        .replace('{formGatewayBaseUrl}', baseConfig['formGatewayBaseUrl'])
        .replace('{authorizationPath}', baseConfig['authorizationPath'])
        .replace('{financePath}', finalConfig['financePath'])

  });
  return finalConfig;
}

export function determineBaseEnvironment() {
  // More secure URL check:
  const urlHost = new URL(window.location.href).host;
  switch (urlHost) {
    case "localhost":
      return "LOCAL";

    case "fabr-test.optum.com": 
      return "NON-OFETEST";
    case "wvie-fabr-sit.optum.com": 
      return "OFESIT";

    default:  
      return "LOCAL";
  }
}

function determineBaseTenant(environment) {
  //We simulating tenant in the core product. Try to determine tenant in the following order, taking the first resolved.
  //1. URL prefix
  //2. URL "tenant" query parameter
  //3. Last known tenant for this environment, saved to local storage.

  const { url, query } = queryString.parseUrl(window.location.href);

  //Determine the tenant based on the URL prefix
  const prefixMatch = url
    .toLowerCase()
    .match('^(?:(?:http|https)://)ie-([a-zA-Z][a-zA-Z])-cp');

  if (prefixMatch && prefixMatch[1]) {
    return prefixMatch[1].toUpperCase();
  }

  //Determine the tenant based on the tenant URL parameter
  if (query && query.tenant) {
    return query.tenant.toUpperCase();
  }

  //Determine the tenant based on what was last saved to local storage.
  let sessionStorageTenant = SessionStorage.getItem(`IE-${environment}-CLP-TENANT`);

  if (sessionStorageTenant && sessionStorageTenant.length > 0) {
    return sessionStorageTenant.toUpperCase();
  }

  //Default to WV if somehow we have not determined the tenant.
  return 'WV';
}
