import * as React from 'react'
import {
  IEServiceError,
  NetworkConnectionError,
  InternalServerError,
  getOffsetTop
} from '@optum-wvie/dynamic-ui-shared/src/utils'
import { get, toString } from 'lodash'
import { config } from '../../../../config'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { debug } from 'webpack'
import { jsonPathToId } from '@optum-wvie/dynamic-ui-framework/src/utils'

const Fragment = (React as any).Fragment

const PREFIX = 'IE'
const MODULE = 'AP'

export const CATEGORIES = {
  //Project wide categories:
  FUNCTIONAL_LEVEL: '00A', //TODO: What functionality?
  INVALID_INPUT_PARAMETERS: '00G',
  INTERNAL_SERVER_ERROR: '00H',
  NETWORK_CONNECTION_ERROR: '00I',
  OTHER: '00Z',

  //All known exception types in JavaScript:
  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Error_types
  JS_EVAL_ERROR: 'JS1',
  JS_INTERNAL_ERROR: 'JS2',
  JS_RANGE_ERROR: 'JS3',
  JS_REFERENCE_ERROR: 'JS4',
  JS_SYNTAX_ERROR: 'JS5',
  JS_TYPE_ERROR: 'JS6',
  JS_URI_ERROR: 'JS7'
}

function getCategoryByErrorType(error) {
  if (error instanceof NetworkConnectionError) {
    return CATEGORIES.NETWORK_CONNECTION_ERROR
  } else if (error instanceof InternalServerError) {
    return CATEGORIES.INTERNAL_SERVER_ERROR
  } else if (error instanceof EvalError) {
    return CATEGORIES.JS_EVAL_ERROR
  } else if (error instanceof RangeError) {
    return CATEGORIES.JS_RANGE_ERROR
  } else if (error instanceof ReferenceError) {
    return CATEGORIES.JS_REFERENCE_ERROR
  } else if (error instanceof SyntaxError) {
    return CATEGORIES.JS_SYNTAX_ERROR
  } else if (error instanceof TypeError) {
    return CATEGORIES.JS_TYPE_ERROR
  } else if (error instanceof URIError) {
    return CATEGORIES.JS_URI_ERROR
  } else {
    return CATEGORIES.OTHER
  }
}

export const CODES = {
  ACCESS_MANAGEMENT_SEARCH_USERS: 'AM4',
  APP_INTAKE_UNKNOWN: 'API0',
  APP_INTAKE_FETCH_FORM: 'API1',
  APP_INTAKE_LOAD_DATA: 'AP2',
  APP_INTAKE_LOAD_REVIEW: 'AP3',
  APP_INTAKE_SAVE: 'AP4',

  APPLICATION_DETAILS_UNKNOWN: 'AD0',
  APPLICATION_DETAILS_FETCH_FORMS: 'AD1',
  APPLICATION_DETAILS_CALL_SEARCH: 'AD2',
  APPLICATION_DETAILS_CALL_VERIFY: 'AD3',
  APPLICATION_DETAILS_DELETE_ASSOCIATE: 'AD4',
  APPLICATION_DETAILS_CALL_ASSOCIATE: 'AD5',
  APPLICATION_DETAILS_CALL_CLIENT_RELATIONS: 'AD6',

  HEALTH_TAB_FETCH_FORMS: 'H01',
  HEALTH_TAB_FETCH_CALL: 'H02',
  HEALTH_TAB_TAX_RELATION_SAVE: 'H03',
  HEALTH_TAB_OUTSIDE_HH_MEMBERS_SAVE: 'H04',
  HEALTH_TAB_CONSONENT_INFO_SAVE: 'H05',

  SCHEDULES_TAB_FETCH_FORMS: 'SCH01',
  SCHEDULES_TAB_FETCH_API: 'SCH02',

  ASSET_VERIFICATION_SEARCH_UNKNOWN: 'AV0',
  ASSET_VERIFICATION_SEARCH_FETCH_FORMS: 'AV1',
  ASSET_VERIFICATION_SEARCH_SAVE: 'AV2',

  AUDIT_CASE_SEARCH_TABLE_UNKNOWN: 'AT0',

  VERIFICATION_TAB_FETCH_FORMS: 'VT0',
  VERIFICATION_TAB_GET_APPLICATION: 'VT1',
  VERIFICATION_TAB_PRINT_VERIFICATIONS: 'VT2',
  VERIFICATION_TAB_MAIL_VERIFICATIONS: 'VT3',

  BENEFIT_ISSUANCE_DETAILS_UNKNOWN: 'BI0',
  BENEFIT_ISSUANCE_DETAILS_GET_MANUAL_ISSUANCE: 'BI1',
  BENEFIT_ISSUANCE_DETAILS_UPDATE_MANUAL_ISSUANCE: 'BI2',
  BENEFIT_ISSUANCE_DETAILS_VALIDATE_ISSUANCE: 'BI3',
  BENEFIT_ISSUANCE_DETAILS_MAXIMUM_ALLOTMENT: 'BI4',

  AGENCY_PORTAL_HOME_UNKNOWN: 'CT0',

  CASE_EVENTS_UNKNOWN: 'CE0',
  CASE_EVENTS_FETCH_FORMS: 'CE1',
  CASE_EVENTS_SEARCH_EVENTS: 'CE2',

  CASE_INFORMATION_UNKNOWN: 'CI0',
  CASE_INFORMATION_FETCH_FORMS: 'CI1',
  CASE_INFORMATION_CLIENT_HISTORY: 'CI2',
  CASE_INFORMATION_SAVE_ELECTRONIC_DATA: 'CI3',
  CASE_INFORMATION_GET_ELECTRONIC_DATA: 'CI4',
  CASE_INFORMATION_RETRIEVE_DATA: 'CI5',
  CASE_INFORMATION_UPDATE_CONFIDENTIAL_FLAG: 'CI6',
  CASE_INFORMATION_INVERSE_RELATIONS: 'CI7',

  CASE_LEVEL_AP_UNKNOWN: 'CA0',
  CASE_LEVEL_AP_FETCH_FORMS: 'CA1',
  CASE_LEVEL_AP_GET_APPLIED_PROGRAMS: 'CA2',
  CASE_LEVEL_AP_GET_RENEWALS: 'CA3',
  CASE_LEVEL_AP_GET_APPLICATION: 'CA4',
  CASE_LEVEL_AP_FETCH_CLIENT_VIOLATION: 'CA5',
  CASE_LEVEL_AP_SAVE: 'CA6',

  CLAIM_DETAILS_UNKNOWN: 'CD0',
  CLAIM_DETAILS_GET_CLAIM: 'CD1',
  CLAIM_DETAILS_UPDATE_CLAIMS: 'CD2',

  CLAIMS_UNKNOWN: 'CL0',
  CLAIMS_FETCH_FORMS: 'CL1',

  CLAIMS_MANAGEMENT_UNKNOWN: 'CM0',
  CLAIMS_MANAGEMENT_SEARCH_CLAIMS: 'CM1',

  CLIENT_LEVEL_AP_UNKNOWN: 'CLA0',
  CLIENT_LEVEL_AP_LOAD: 'CLA1',
  CLIENT_LEVEL_AP_LOAD_FORM: 'CLA2',
  CLIENT_LEVEL_AP_SAVE: 'CLA3',

  CLIENTS_COMPONENT_UNKNOWN: 'CC0',
  CLIENTS_COMPONENT_FETCH_FORMS: 'CC1',

  DOCUMENTS_UNKNOWN: 'D0',
  DOCUMENTS_FETCH_FORMS: 'D1',
  DOCUMENTS_GET_FILTER_DATA: 'D2',
  DOCUMENTS_GET_CASE: 'D3',
  DOCUMENTS_UPLOAD: 'D4',
  DOCUMENTS_GENERATE: 'D5',
  DOCUMENTS_REASSIGN: 'D6',

  EDRS_VERIFICATION_INQUIRY_UNKNOWN: 'EDRS0',
  EDRS_VERIFICATION_INQUIRY_LOAD_FORM: 'EDRS1',
  EDRS_VERIFICATION_INQUIRY_LOAD_DATA: 'EDRS2',
  EDRS_VERIFICATION_INQUIRY_DISQUALIFICATION: 'EDRS3',
  EDRS_VERIFICATION_INQUIRY_PERSIST_RECORD: 'EDRS4',

  EDRS_VIOLATION_UNKNOWN: 'EDRV0',
  EDRS_VIOLATION_FORM: 'EDRSV1',
  EDRS_VIOLATION_LOAD_DATA: 'EDRSV2',
  EDRS_VIOLATION_SUBMIT: 'EDRSV3',
  EDRS_VIOLATION_DETAILS: 'EDRSV4',

  ELIGIBILITY_INFORMATION_UNKNOWN: 'EI0',
  ELIGIBILITY_INFORMATION_FETCH_FORM: 'EI1',
  ELIGIBILITY_INFORMATION_GET_APPLICATION: 'EI2',
  ELIGIBILITY_INFORMATION_GET_DATA: 'EI3',
  ELIGIBILITY_INFORMATION_ACCEPT_RESULTS: 'EI4',
  ELIGIBILITY_INFORMATION_RUN_ELIGIBILITY: 'EI5',
  ELIGIBILITY_INFORMATION_CASCADE: 'EI6',
  ELIGIBILITY_INFORMATION_ELIGIBILITY_OVERRIDE: 'EI7',
  ELIGIBILITY_INFORMATION_WITHDRAWAL: 'EI8',
  ELIGIBILITY_INFORMATION_VOLUNTARY_CLOSURE: 'EI7',
  
  ELIGIBILITY_RESULTS_UNKNOWN: 'ER0',
  ELIGIBILITY_RESULTS_FETCH_FORMS: 'ER1',
  ELIGIBILITY_RESULTS_GET_APPLICATION: 'ER2',
  ELIGIBILITY_RESULTS_ACCEPT_RESULTS: 'ER3',
  ELIGIBILITY_RESULTS_FETCH_MAP: 'ER4',

  ELIGIBILITY_RUN_RESULTS_UNKNOWN: 'ERR0',
  ELIGIBILITY_RUN_RESULTS_FETCH_FORM: 'ERR1',
  ELIGIBILITY_RUN_RESULTS_GET_APPLICATION: 'ERR2',

  ELIGIBILITY_HISTORY_UNKNOWN: 'EHS0',
  ELIGIBILITY_HISTORY_FETCH_FORMS: 'EHS1',
  ELIGIBILITY_HISTORY_GET_APPLICATION: 'EHS2',
  ELIGIBILITY_HISTORY_GET_CASE_DETAILS: 'EHS3',
  ELIGIBILITY_HISTORY_GET_PROGRAMS: 'EHS4',

  ENROLLMENT_UNKNOWN: 'E0',
  ENROLLMENT_FETCH_FORMS: 'E1',
  ENROLLMENT_FETCH_PROVIDER_DATA: 'E2',
  ENROLLMENT_VALIDATE_APPLICATION_START_DATE: 'E3',
  ENROLLMENT_VALIDATE_STATUS: 'E4',
  ENROLLMENT_FETCH_APPLICATION_START_DATE: 'E5',
  ENROLLMENT_ELIGIBILITY_STATUS: 'E6',
  ENROLLMENT_SAVE_RECORD: 'E7',
  ENROLLMENT_SAVE_REFERALS: 'E8',
  ENROLLMENT_FETCH_BENEFIT_PROGRAM_CODE: 'E9',

  EVENT_HISTORY_UNKNOWN: 'EH0',
  EVENT_HISTORY_SEARCH: 'EH1',
  EVENT_HISTORY_LOAD_FORM: 'EH2',

  FINANCIAL_MANAGEMENT_UNKNOWN: 'FM0',
  FINANCIAL_MANAGEMENT_FETCH_FORM: 'FM1',
  FINANCIAL_MANAGEMENT_INITIALIZE_FORM: 'FM2',
  FINANCIAL_MANAGEMENT_PAYMENT_STATUS: 'FM3',

  INCOME_ENQUIRY_UNKNOWN: 'IE0',
  INCOME_ENQUIRY_FETCH_FORM: 'IE1',
  INCOME_ENQUIRY_GET_DATA: 'IE2',

  INFRACTION_UNKNOWN: 'I0',
  INFRACTION_SCHEMA_RETRIEVAL: 'I1',
  INFRACTION_APPLICATION_SUBMISSION: 'I2',

  MANUAL_ISSUANCE_UNKNOWN: 'MI0',
  MANUAL_ISSUANCE_FETCH_RESULTS: 'MI1',
  MANUAL_ISSUANCE_FETCH_CLIENT_INFO: 'MI2',
  MANUAL_ISSUANCE_GET_ACTIVE_PROGRAMS: 'MI3',
  MANUAL_ISSUANCE_FETCH_AUXILIARY_VOUCHER: 'MI4',
  ISSUANCE_INQUIRY_SEARCH_RESULTS: 'II0',

  MEDICAID_PROPERTY_ASSET_VERIFICATION_UNKNOWN: 'MPAV0',
  MEDICAID_PROPERTY_ASSET_VERIFICATION_VIEW: 'MPAV1',
  MEDICAID_PROPERTY_ASSET_VERIFICATION_LOAD_DETAILS: 'MPAV2',
  MEDICAID_PROPERTY_ASSET_VERIFICATION_INQUIRY: 'MPAV3',
  MEDICAID_PROPERTY_ASSET_VERIFICATION_UPDATE: 'MPAV4',

  MESSAGES_UNKNOWN: 'M0',
  MESSAGES_SEARCH: 'M1',
  MESSAGES_LOAD: 'M2',
  MESSAGES_READ: 'M3',
  MESSAGES_DELETE: 'M4',
  MESSAGES_CREATE: 'M5',
  MESSAGES_RESTORE: 'M6',
  MESSAGES_UPDATE: 'M7',
  MESSAGES_WORKER_CONFIG: 'M8',

  NOTICES_FORMS_UNKNOWN: 'NF0',
  NOTICES_FORMS_FETCH_FORM: 'NF1',
  NOTICES_FORMS_GENERATE: 'NF2',
  NOTICES_FORMS_CANCEL_DELIVERY: 'NF3',
  NOTICES_FORMS_RESEND: 'NF4',
  NOTICES_FORMS_PRINT: 'NF5',
  NOTICES_FORMS_SUBMIT: 'NF6',
  NOTICES_FORMS_GET_FORM_DATA: 'NF7',
  NOTICES_FORMS_GENERATE_NOTICE_DATA: 'NF8',
  NOTICES_FORMS_GENERATE_NOTICES_SUBMIT: 'NF',

  ORGANIZATION_MANAGEMENT_FORMS: 'OM0',
  ORGANIZATION_MANAGEMENT_SEARCH_ORGS: 'OM1',
  ORG_SEARCH_MY_ORGS: 'OM2',
  ORG_SEARCH_ALL_ORGS: 'OM3',

  OTHER_UNKNOWN: 'OT0',
  OTHER_GET_APPLICATION: 'OT1',

  ORG_DETAILS_RULES_ALL_ORGS: 'OD0',
  ORG_DETAILS_RULES_GET_ORG: 'OD1',
  ORG_DETAILS_RULES_ALL_COUNTIES: 'OD2',

  ORG_MANAGEMENT_FORM: 'OM0',
  ORG_MANAGEMENT_SEARCH_USERS: 'OM1',
  ORG_MANAGEMENT_SEARCH_ATTRIBUTES: 'OM2',
  ORG_MANAGEMENT_SEARCH_ASSIGNMENT_RULES: 'OM3',
  ORG_MANAGEMENT_EDIT_ORGS: 'OM4',

  OVER_PAYMENT_REFERRALS_UNKNOWN: 'OPR0',
  OVER_PAYMENT_REFERRALS_SEARCH: 'OPR1',

  PERSON_MATCH_INBOX_UNKNOWN: 'PMI0',
  PERSON_MATCH_INBOX_FETCH_FORM: 'PMI1',
  PERSON_MATCH_INBOX_GET_APPS: 'PMI2',

  PROGRAM_AFFILIATION_UNKNOWN: 'PA0',
  PROGRAM_AFFILIATION_FETCH_FORM: 'PA1',
  PROGRAM_AFFILIATION_LOAD_FORM: 'PA2',
  PROGRAM_AFFILIATION_FORM_DATA: 'PA3',

  PROGRAM_MONTHLY_DETAILS_UNKNOWN: 'PMD0',
  PROGRAM_MONTHLY_DETAILS_FETCH_FORM: 'PMD1',
  PROGRAM_MONTHLY_DETAILS_GET_APPLICATION: 'PMD2',
  PROGRAM_MONTHLY_DETAILS_ACCEPT_RESULTS: 'PMD3',
  PROGRAM_MONTHLY_DETAILS_POPULATE_ACCEPTANCE: 'PMD4',
  PROGRAM_MONTHLY_DETAILS_DELETE_DRAFT: 'PMD5',
  PROGRAM_MONTHLY_DETAILS_DELETE_OVERRIDES: 'PMD6',
  PROGRAM_MONTHLY_DETAILS_SUBMIT_OVERRIDES: 'PMD7',
 
  PROGRAM_REQUEST_UNKNOWN: 'PR0',
  PROGRAM_REQUEST_FETCH_FORM: 'PR1',
  
  QUALITY_CONTROL_GENERATE_UNKNOWN: 'QCG0',
  QUALITY_CONTROL_GENERATE_FETCH_FORMS: 'QCG1',
  QUALITY_CONTROL_GENERATE_MCR_CODES: 'QCG2',
  QUALITY_CONTROL_GENERATE_MCR_CASE: 'QCG3',
  QUALITY_CONTROL_GENERATE_MCR_EVENT: 'QCG4',
  QUALITY_CONTROL_GENERATE_SEARCH: 'QCG5',
  QUALITY_CONTROL_GENERATE_EVENT_HISTORY: 'QCG6',
  QUALITY_CONTROL_GENERATE_SAVE: 'QCG7',

  QUALITY_CONTROL_OUTBOX_UNKNOWN: 'QCO0',
  QUALITY_CONTROL_OUTBOX_FETCH_FORMS: 'QCO1',
  QUALITY_CONTROL_OUTBOX_SEARCH: 'QCO2',

  QUALITY_CONTROL_REVIEWER_UNKNOWN: 'QCR0',
  QUALITY_CONTROL_REVIEWER_FETCH_FORMS: 'QCR1',
  QUALITY_CONTROL_REVIEWER_GET_SAMPLES: 'QCR2',

  QUALITY_CONTROL_REVIEWER_SAMPLING_PLAN_UNKNOWN: 'QCRSP0',
  QUALITY_CONTROL_REVIEWER_SAMPLING_PLAN_FETCH_FORMS: 'QCRSP1',
  QUALITY_CONTROL_REVIEWER_SAMPLING_PLAN_GET_CASE: 'QCRSP2',
  QUALITY_CONTROL_REVIEWER_SAMPLING_PLAN_GET_EVENT_HISTORY: 'QCRSP3',
  QUALITY_CONTROL_REVIEWER_SAMPLING_PLAN_SAVE_DRAFT: 'QCRSP4',
  QUALITY_CONTROL_REVIEWER_SAMPLING_PLAN_SAVE_COMPLETE: 'QCRSP5',
  QUALITY_CONTROL_REVIEWER_SAMPLING_PLAN_QUEUE_COMPLETE: 'QCRSP6',

  QUALITY_CONTROL_SAVE_UNKNOWN: 'QCS0',
  QUALITY_CONTROL_SAVE_FETCH_FORMS: 'QCS1',
  QUALITY_CONTROL_SAVE_GET_SAMPLES: 'QCS2',

  QUALITY_CONTROL_SUPERVISOR_UNKNOWN: 'QCV0',

  QUALITY_CONTROL_TASK_CONFIGURATION_UNKNOWN: 'QCT0',
  QUALITY_CONTROL_TASK_CONFIGURATION_GET_CONFIG: 'QCT1',
  QUALITY_CONTROL_TASK_CONFIGURATION_SAVE_CONFIG: 'QCT2',

  RECENT_ASSET_VERIFICATION_INQUIRY_UNKNOWN: 'RA0',
  RECENT_ASSET_VERIFICATION_INQUIRY_FETCH_FORMS: 'RA1',
  RECENT_ASSET_VERIFICATION_INQUIRY_GET_INQUIRY: 'RA2',

  RENEWAL_INFORMATION_UNKNOWN: 'RI0',
  RENEWAL_INFORMATION_FETCH_FORMS: 'RI1',
  RENEWAL_INFORMATION_GET_RENEWALS: 'RI2',
  RENEWAL_INFORMATION_UPDATE_RENEWAL: 'RI3',
  RENEWAL_INFORMATION_HISTORY: 'RI4',

  REPORTS_UNKNOWN: 'RP0',
  REPORTS_FETCH_FORMS: 'RP1',
  REPORTS_GET_MCR_CODES: 'RP2',
  REPORTS_RENEWAL_SRC: 'RP3',
  REPORTS_RENEWAL_STATUS: 'RP4',
  REPORTS_SEARCH: 'RP5',

  REVIEW_AND_SUBMIT_UNKNOWN: 'RAS0',

  REVIEW_AND_SUBMIT_FETCH_FORM: 'RAS1',

  CHILDCARE_PAYMENT_PROVIDER_SEARCH: 'CPPS0',
  CHILDCARE_PAYMENT_PROVIDER_DETAILS: 'CPPD1',
  CHILDCARE_PAYMENT_PROVIDER_SUPERVISOR: 'CPPS2',

  TRANSFER_OBJECT: 'TO01',
  TRANSFER_OBJECT_FORM: 'TOF01',
  TRANSFER_OBJECT_SEARCH_USER: 'TOSU01',
  TRANSFER_OBJECT_SEARCH_ORG: 'TOSO01',
  TRANSFER_OBJECT_SEARCH_COUNTY: 'TOSC01',
  TRANSFER_BULK_GROUP_CANCEL: 'TBG01',

  MASS_TRANSFER_SEARCH_USERS: 'MT0',
  MASS_TRANSFER_SEARCH_ORGS: 'MT1',

  BENEFIT_RECOVERY_REFERRAL_GET_ACTIVE_PROGRAMS: 'BRR01',
  BENEFIT_RECOVERY_REFERRAL_GET_PRIMARY_CLIENT: 'BRR02',
  BENEFIT_RECOVERY_REFERRAL_CREATE_NEW_REFFERAL: 'BRR03',
  BENEFIT_RECOVERY_REFERRAL_FETCH_REFFERAL: 'BRR04',
  BENEFIT_RECOVERY_REFERRAL_GET_ELIGIBILITY_RESULTS: 'BRR04',
  REFERRAL_SEARCH_FAILED: 'RS0',
  CLAIM_SEARCH_FAILED: 'CS0',
  CCA_ORG_IDS: 'CO1',

  PAYMENT_CORRECTION_FETCH_RESULTS: 'PC01',
  PAYMENT_CORRECTION_SUBMIT_RESULTS: 'PC02',

  PIN_SMASH_UNKNOWN: 'PSM00',
  PIN_SMASH_SEARCH: 'PSM01',
  PIN_SMASH_LOAD_FORM: 'PSM02',

  CASE_AUDIT_FORMS: 'CA1',
  CASE_AUDIT_SEARCH: 'CA2',

  SUPERVISOR_DASHBOARD_CASE_SUMMARY: 'SD1',
  SUPERVISOR_DASHBOARD_WORKER_CASE_DETAIL: 'SD2',
  SUPERVISOR_DASHBOARD_WORKER_SEARCH: 'SD3'
}

function getDefaultCodeByPageName(name) {
  switch (name) {
    case 'AppIntake':
      return CODES.APP_INTAKE_UNKNOWN
    case 'ApplicationDetails':
      return CODES.APPLICATION_DETAILS_UNKNOWN
    case 'AssertVerificationSearch':
      return CODES.ASSET_VERIFICATION_SEARCH_UNKNOWN
    case 'AuditCaseSearchTable':
      return CODES.AUDIT_CASE_SEARCH_TABLE_UNKNOWN
    case 'BenefitIssuanceDetails':
      return CODES.BENEFIT_ISSUANCE_DETAILS_UNKNOWN
    case 'AgencyPortalHome':
      return CODES.AGENCY_PORTAL_HOME_UNKNOWN
    case 'CaseEvents':
      return CODES.CASE_EVENTS_UNKNOWN
    case 'CaseInformation':
      return CODES.CASE_INFORMATION_UNKNOWN
    case 'CaseLevelAP':
      return CODES.CASE_LEVEL_AP_UNKNOWN
    case 'ClaimDetails':
      return CODES.CLAIM_DETAILS_UNKNOWN
    case 'Claims':
      return CODES.CLAIMS_UNKNOWN
    case 'ClientsComponent':
      return CODES.CLIENTS_COMPONENT_UNKNOWN
    case 'ClaimsManagement':
      return CODES.CLAIMS_MANAGEMENT_UNKNOWN
    case 'ClientLevelAP':
      return CODES.CLIENT_LEVEL_AP_UNKNOWN
    case 'Documents':
      return CODES.DOCUMENTS_UNKNOWN
    case 'EdrsVerificationInquiry':
      return CODES.EDRS_VERIFICATION_INQUIRY_UNKNOWN
    case 'EdrsViolation':
      return CODES.EDRS_VIOLATION_UNKNOWN
    case 'EligibiityResults':
      return CODES.ELIGIBILITY_RESULTS_UNKNOWN
    case 'Enrollment':
      return CODES.ENROLLMENT_UNKNOWN
    case 'EventHistory':
      return CODES.EVENT_HISTORY_UNKNOWN
    case 'FinancialManagement':
      return CODES.FINANCIAL_MANAGEMENT_UNKNOWN
    case 'IncomeEnquiry':
      return CODES.INCOME_ENQUIRY_UNKNOWN
    case 'Infraction':
      return CODES.INFRACTION_UNKNOWN
    case 'ManualIssuance':
      return CODES.MANUAL_ISSUANCE_UNKNOWN
    case 'Messages':
      return CODES.MESSAGES_UNKNOWN
    case 'MedicaidPropertyAssetVerification':
      return CODES.MEDICAID_PROPERTY_ASSET_VERIFICATION_UNKNOWN
    case 'NoticesForms':
      return CODES.NOTICES_FORMS_UNKNOWN
    case 'Other':
      return CODES.OTHER_UNKNOWN
    case 'OverpaymentRefferrals':
      return CODES.OVER_PAYMENT_REFERRALS_UNKNOWN
    case 'PersonMatchInbox':
      return CODES.PERSON_MATCH_INBOX_UNKNOWN
    case 'ProgramAffiliation':
      return CODES.PROGRAM_AFFILIATION_UNKNOWN
    case 'EligibilityInformation':
      return CODES.ELIGIBILITY_INFORMATION_UNKNOWN
    case 'EligibilityRunResults':
      return CODES.ELIGIBILITY_RUN_RESULTS_UNKNOWN
    case 'EligibilityHistory':
      return CODES.ELIGIBILITY_HISTORY_UNKNOWN
    case 'ProgramMonthlyDetails':
      return CODES.PROGRAM_MONTHLY_DETAILS_UNKNOWN
    case 'ProgramRequest':
      return CODES.PROGRAM_REQUEST_UNKNOWN
    case 'QualityControlGenerate':
      return CODES.QUALITY_CONTROL_GENERATE_UNKNOWN
    case 'QualityControlOutbox':
      return CODES.QUALITY_CONTROL_OUTBOX_UNKNOWN
    case 'QualityControlReviewer':
      return CODES.QUALITY_CONTROL_REVIEWER_UNKNOWN
    case 'QualityControlReviewerSamplingPlan':
      return CODES.QUALITY_CONTROL_REVIEWER_SAMPLING_PLAN_UNKNOWN
    case 'QualityControlSave':
      return CODES.QUALITY_CONTROL_SAVE_UNKNOWN
    case 'QualityControlSupervisor':
      return CODES.QUALITY_CONTROL_SUPERVISOR_UNKNOWN
    case 'QualityControlTaskConfiguration':
      return CODES.QUALITY_CONTROL_TASK_CONFIGURATION_UNKNOWN
    case 'RecentAssetVerificationInquiry':
      return CODES.RECENT_ASSET_VERIFICATION_INQUIRY_GET_INQUIRY
    case 'RenewalInformation':
      return CODES.RENEWAL_INFORMATION_UNKNOWN
    case 'Reports':
      return CODES.REPORTS_UNKNOWN
    case 'ReviewAndSubmit':
      return CODES.REVIEW_AND_SUBMIT_UNKNOWN
    default:
      return '000'
  }
}

export function shouldThrow(code) {
  //This will act as our "fusebox" for now. It will return whether an error is critical
  //enough that it should be thrown to trigger the ErrorBoundary component, based on the code.
  switch (code) {
    case CODES.MESSAGES_READ:
      return false
    default:
      return true
  }
}

export function AgencyPortalException(error, code, category = null) {
  this.message = error.message
  this.error = error
  this.code = code
  if (category) {
    this.category = category
  } else {
    this.category = getCategoryByErrorType(error)
  }
  if ('captureStackTrace' in Error) {
    Error.captureStackTrace(this)
  } else {
    const newError = new (Error as any)()
    this.stack = newError.stack
  }
}
AgencyPortalException.prototype = Object.create(Error.prototype)
AgencyPortalException.prototype.name = 'AgencyPortalException'
AgencyPortalException.prototype.constructor = AgencyPortalException

class ErrorBoundaryComponent extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorObject: undefined,
      errorCode: undefined,
      correlationId: null
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(errorObject: any, errorInfo: any) {
    let errorCode = ''
    let correlationId = null
    if (errorObject instanceof IEServiceError) {
      //The error was received from a backend service API response.
      const { errorList, businessCorrelationId } = errorObject as any
      //TODO: Can there be multiple errors in errorList? Do we display all?
      errorCode = get(errorList, '[0].errorCode') || ''
      correlationId = businessCorrelationId
    } else if (errorObject instanceof AgencyPortalException) {
      //The error originated from a known point in the UI.
      const { category, code } = errorObject as any
      errorCode = `${PREFIX}-${MODULE}-${category}${code}`
    } else {
      //It is unclear where the error came from. Check for JS exceptions and specify the affected component.
      errorCode = `${PREFIX}-${MODULE}-`
      errorCode += getCategoryByErrorType(errorObject)
      errorCode += getDefaultCodeByPageName(this.props.name)
    }
    console.error(
      'ErrorBoundary caught exception! errorCode:',
      errorCode,
      'errorObject',
      errorObject,
      'errorInfo',
      errorInfo
    )
    //TODO: Call front end service logging API?
    this.setState({
      hasError: true,
      errorCode,
      errorObject,
      errorInfo,
      correlationId
    })
  }

  render() {
    const { children, name, locale } = this.props
    const {
      hasError,
      errorCode,
      errorObject,
      errorInfo,
      correlationId
    } = this.state
    if (hasError) {
      return (
        <div className="center-text">
          <h3>
            <i className="fa fa-frown-o" />
            {' ' + I18n.t('ErrorBoundary.title')}
          </h3>
          <p>
            <span style={{ fontWeight: 'bold' }}>
              {I18n.t('ErrorBoundary.page') + ': '}
            </span>
            {name}
            <br />
            <span style={{ fontWeight: 'bold' }}>
              {I18n.t('ErrorBoundary.errorCode') + ': '}
            </span>
            {errorCode}
            {!config.isProd && errorObject && (
              <Fragment>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  {I18n.t('ErrorBoundary.errorMessage') + ': '}
                </span>
                {toString(errorObject)}
              </Fragment>
            )}
            {correlationId && (
              <Fragment>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  {I18n.t('ErrorBoundary.correlationId') + ': '}
                </span>
                {correlationId}
              </Fragment>
            )}
          </p>
          <br />
          {'We are sorry for the inconvenience'}
          <br />
          {'Please contact us at wvtcc@optum.com or 1-844-414-5112'}
        </div>
      )
    }

    return children
  }
}

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale
  }
}

export const ErrorBoundary = connect(mapStateToProps)(ErrorBoundaryComponent)
