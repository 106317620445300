import { config } from '../../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-shared/src/utils'
import { AgencyPortalException, CATEGORIES, CODES, shouldThrow } from './Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const ErrorBoundary = MultitenantLoadable(
  () => import(/* webpackChunkName: "ErrorBoundary" */ './Errors'),
  () =>
    import(
      /* webpackChunkName: "[request]" */ '../../' +
        tenantDirectoryName +
        '/Errors'
    ),
  'ErrorBoundary'
)

const BaseErrorBoundary = MultitenantLoadable(
  () => import('./Errors'),
  () => Promise.resolve().then(() => null),
  'ErrorBoundary'
)

export { ErrorBoundary, BaseErrorBoundary, AgencyPortalException, CATEGORIES, CODES, shouldThrow }
