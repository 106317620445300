
import * as React from 'react';
import { config } from '../../../config';
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-shared/src/utils';
//import '!style-loader!css-loader!../../../src/customStyles.css'

const tenantDirectoryName = config.tenant.code.toLowerCase();

const LoadableLayout = MultitenantLoadable(
    () => import('./Layout'),
    () => import('../../' + tenantDirectoryName + '/Layout/Layout')
);

// const Header = MultitenantLoadable(
//     () => import('./Header'),
//     () => import('../../' + tenantDirectoryName + '/Layout/Header')
// );

// const Footer = MultitenantLoadable(
//     () => import('./Footer'),
//     () => import('../../' + tenantDirectoryName + '/Layout/Footer')
// );

export default function Layout(props) {
    return <LoadableLayout

        {...props}
        // header={(headerProps) =>}
        //     <Header {...headerProps} />
        // }

        // footer={(footerProps) =>
        //     <Footer {...footerProps} />
        // }
    />
}