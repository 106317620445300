export default function userAccess(
    state = {
      isFetching: false,
      userRoles: null
    },
    action,
  ) {
    switch (action.type) {
      case 'INITIALIZE':
        return Object.assign({}, state, {
          isFetching: false,
        });
      case 'LOGIN_SUCCESS':
        return Object.assign({}, state, {
          isFetching: true,
        });
      case 'LOGIN_REQUEST':
        return Object.assign({}, state, {
          isFetching: false,
          userRoles: null
        });
      case 'LOGOUT_SUCCESS':
        return Object.assign({}, state, {
          isFetching: false,
          userRoles: null
        });
      case 'UPDATE_ACCESS_ROLES':
        return Object.assign({}, state, {
          isFetching: false,
          userRoles: action.userRoles,
          inactiveUserRoles: action.inactiveUserRoles,
          userAccount: action.userAccount
        })
      default:
        return state;
    }
  }