import * as React from 'react'

// @TODO import below from agency portal
// import FinancialManagement from '../FinancialManagement'
// import ChildCarePayment from '../ChildCarePayment'
// import Claims from '../base/Modules/BenefitRecoverySimulation'// done
// import Claims from '../base/Modules/BenefitRecoveryClaimDetails'// done
import Claims from '../base/Modules/Claims'// done
import TopInformations from '../base/Modules/TopInformations'
import BenefitRecoveryReferrals from '../base/Modules/BenefitRecoveryReferrals'
// import AgencyPortalHome from '../AgencyPortalHome'
// import { config } from '../../../config'
import { isEmpty } from 'lodash'

const _ = { isEmpty }

export const mappings = {
  BenefitsIssuance: props => {
    const { userId } = props
    return (<h1>this is BenefitsIssuance tab content</h1>)
    // return <FinancialManagement
    //  userId={userId} 
    //  action={'benefitIssuance'}
    //  tabIndex={props.tabIndex}
    //  caseId={props.caseId}
    //  externalCaseId={props.externalCaseId}
    //  navigateToScreen={props.page} />
  },
  BenefitsRecoveryRefer: props => {
    const { redirectToClaimDetailsTab } = props
    //@TODO : activeEntitlements NEEDS TO BE SET UP PROPERLY
    return (
      <BenefitRecoveryReferrals
        action={'benefitRecoveryReferrals'}
        redirectToClaimDetailsTab={redirectToClaimDetailsTab}
        tabIndex={props.tabIndex}
        caseId={props.caseId}
        externalCaseId={props.externalCaseId}
        navigateToScreen={props.page}
        fraudFlag={props.fraudFlag}
        fraudInvFlag={props.fraudInvFlag}
      />
    )
  },
  ClaimsAndPayments: props => {
    return (
      <Claims
        action={'claims'}
        tabIndex={props.tabIndex}
        referralNumber={props.referralNumber}
        navigate_from_screen={props.navigateFromScreen}
        referralType={props.referralType}
      />
    )
  },
  TOPInformation: props => {
    return <TopInformations action={'topInformations'} />
  },
  ChildCarePayments: props => {
    const { userId } = props
    return (<h1>this is ChildCarePayments tab content</h1>)
    // return <ChildCarePayment userId={userId} action={'childCarePayment'} />
  },
  home: props => {
    const { rootMappings, mapCanvasToProps, routePath, initialUrl } = props
    return (<h1>this is ChildCarePayments tab content</h1>)
    // if (!config.isProd) {
    //   return (
    //     <AgencyPortalHome
    //       rootMappings={rootMappings}
    //       mapCanvasToProps={mapCanvasToProps}
    //       routePath={routePath}
    //       initialUrl={initialUrl}
    //     />
    //   )
    // } else {
    //   return null
    // }
  },
  
  BenefitsFinder: props => {
    return (<h1>this is BenefitsFinder tab content</h1>)
    // return <BenefitsFinder config={config} mode="AP" />
  }
}
