import { combineReducers } from 'redux';
import externalAuth from './externalAuth';
import auth from './auth';
import userAccess from './userAccess';
import { i18nReducer } from 'react-redux-i18n';

export default combineReducers({
  externalAuth,
  auth,
  userAccess,
  i18n: i18nReducer,
});
