import * as React from 'react'
import { fetchJson, fetchFullJson, UnauthorizedError, fetchJsonForDOCS, IEServiceError } from '@optum-wvie/dynamic-ui-shared/src/utils'
import { connect } from 'react-redux'
import * as actions from '../actions'
import { config } from '../config'
import ReactMatomo from 'react-piwik'
import { get, uniq, forEach, isPlainObject } from 'lodash'
const _ = { get, uniq, forEach, isPlainObject }

interface apFetcherHOCProps {
  uuid: string
  locale: string
  accessToken: string
  location: {
    search: string
    pathname: string
  }
  logoutUser: (uuid: string, accessToken: string, skipRedirect: boolean) => void
  userId: string
}

interface apFetcherHOCState {}

const isMatomoEnabled = (config.matomoUrl && config.matomoSiteId && process.env.NODE_ENV === 'production')

export function trackAPICall(url: string, request: {method?: any; headers?: any; body?: any; mode?: any;},
  businessCorrelationId: string, isSuccess: boolean, durationMillis: number) {
  // TODO: Should we only track Failure calls? Or make it toggleable?
  
  if (!isMatomoEnabled) {
    return
  }
  const urlWithoutProtocol = url.replace('http://', '')
    .replace('https://', '')
  const pathName = urlWithoutProtocol.substring(urlWithoutProtocol.indexOf('/'))
  const dimensionKey = 'dimension' + config.matomoCustomDimensionCorrelationId
  const matomoParams: any[] = [
    'trackEvent',
    'Cor ID: ' + (businessCorrelationId ? businessCorrelationId : 'N/A'),
    (isSuccess ? 'Success' : 'Failure'),
    (request && request.method ? request.method : 'GET') + ' ' + pathName
  ]  
  matomoParams.push(durationMillis)
  if (businessCorrelationId) {      
    matomoParams.push({[dimensionKey]: businessCorrelationId})
  }
  ReactMatomo.push(matomoParams);
}

export const withAPFetcher = WrappedComponent => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class extends React.Component<apFetcherHOCProps, apFetcherHOCState> {
      constructor(props) {
        super(props)
        this.state = {
          redirect: undefined
        }
      }

      apFetch = (
        endpoint: string,
        request?: {
          method?: any
          headers?: any
          body?: any
          mode?: any
        },
        retries?: number,
        timeoutMs?: number,
        callback?: Function,
        displayError?: boolean
      ) => {
        const { uuid, accessToken, locale, logoutUser, userId } = this.props
        const newHeaders = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (locale + '_US').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName, 
          uuid,
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
          userId,
          ...((request && request.headers) || {})
        }
        const newRequest = {
          ...request,
          headers: newHeaders,
          referrerPolicy: 'no-referrer-when-downgrade'
        }
        const beforeMillis = new Date().valueOf()
        
        return fetchFullJson(endpoint, newRequest, retries ? retries : 0, timeoutMs ? timeoutMs : 60000, callback, displayError).then(response => {
          const durationMillis = new Date().valueOf() - beforeMillis
          if (response && _.isPlainObject(response)) {
            if (response.businessCorrelationId) {
              trackAPICall(endpoint, request, response.businessCorrelationId, true, durationMillis)
            } else {
              trackAPICall(endpoint, request, null, true, durationMillis)
            }
            if (typeof response.data !== 'undefined') {
              return response.data
            }
          }
          return response
        }).catch(
          error => {
            const durationMillis = new Date().valueOf() - beforeMillis
            if (error instanceof UnauthorizedError) {
              // logoutUser(uuid, accessToken, false)
              trackAPICall(endpoint, request, null, false, durationMillis)
              //TODO: Trigger some kind of message to display?
            } else {
              if (error instanceof IEServiceError) {
                const { businessCorrelationId } = error as any
                trackAPICall(endpoint, request, businessCorrelationId, false, durationMillis)
              } else {
                trackAPICall(endpoint, request, null, false, durationMillis)
              }
              throw error
            }
          }
        )
      }

      render() {
        return <WrappedComponent {...this.props} apFetch={this.apFetch} />
      }
    }
  )
}


export const withAPFetcherForDOCS = WrappedComponent => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class extends React.Component<apFetcherHOCProps, apFetcherHOCState> {
      constructor(props) {
        super(props)
        this.state = {
          redirect: undefined
        }
      }

      apFetch = (
        endpoint: string,
        request?: {
          method?: any
          headers?: any
          body?: any
          mode?: any
        },
        retries?: number,
        timeoutMs?: number,
        callback ?: Function
      ) => {
        const { uuid, accessToken, locale, logoutUser } = this.props
        const newHeaders = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (locale + '_US').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName, 
          uuid,
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
          ...((request && request.headers) || {})
        }
        const newRequest = {
          ...request,
          headers: newHeaders,
          referrerPolicy: 'no-referrer-when-downgrade'
        }
        return fetchJsonForDOCS(endpoint, newRequest, retries, timeoutMs, callback).catch(
          error => {
            if (error instanceof UnauthorizedError) {
              logoutUser(uuid, accessToken, false)
              //TODO: Trigger some kind of message to display?
            } else {
              throw error
            }
          }
        )
      }

      render() {
        return <WrappedComponent {...this.props} apFetch={this.apFetch} />
      }
    }
  )
}

function mapStateToProps(state) {
  //@TODO : activeEntitlements NEEDS TO BE SET UP PROPERLY
  // let activeEntitlements = ["AP_FA_WRITE_FLD_ENTL","AP_WRITE_BR_REFF_TAB"]
  let activeEntitlements = []
  let userRolesNames = []
  if (state.userAccess && state.userAccess.userRoles) {
    _.forEach(state.userAccess.userRoles, userRole => {
      _.forEach(userRole.entitlements, entitlement => {
        activeEntitlements.push(entitlement.entitlementName)
      })
      userRolesNames.push(userRole.role.roleName)
    })
    activeEntitlements = _.uniq(activeEntitlements)
    userRolesNames = _.uniq(userRolesNames)
  }
  return {
    uuid:
      _.get(state.auth, 'userAccount.uuid') ||
      _.get(
        state,
        'canvasRequest.context.environment.parameters.UUID',
        undefined
      ),
    locale: _.get(state.i18n, 'locale'),
    accessToken: _.get(state.auth, 'accessToken'),
    activeEntitlements: activeEntitlements,
    userInfo: _.get(state.auth, 'userAccount'),
    userRolesNames: userRolesNames,
    appInfoData:state.appdata || {},
    userId: _.get(state.auth, 'userAccount.userId')
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logoutUser: (uuid, accessToken, skipRedirect) => {
      dispatch(actions.logoutUser(uuid, accessToken, skipRedirect))
    }
  }
}
