import * as React from 'react';
import { Provider, connect } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import { config } from '../../config';
import * as helper from '@optum-wvie/dynamic-ui-shared/src/utils';
import { get } from 'lodash';
// import { initializeLogger } from './clientLogger';
import * as actions from '../../actions';
import { Modal } from 'react-bootstrap';
import Layout from './Layout';
import IdleTimer from 'react-idle-timer';
import { EVENTS } from 'react-idle-timer'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-shared/components/ScreenPreLoader';
import ReactMatomo from 'react-piwik'
import { history } from '../../configureStore'
import{mappings} from './mappings'
import '../../styles/scss/agency-portal.scss';
import '../../styles/scss/CPStyles/scss/blue.scss';
import * as queryString from 'query-string'
import { withAPFetcher } from '../../utils'


const _ = { get };
declare global {
  interface Window {
    myLogger: any;
  }
}

const isMatomoEnabled = (config.matomoUrl && config.matomoSiteId && process.env.NODE_ENV === 'production')

window.myLogger = window.myLogger || {};

const Root = ({ store, routes }) => {
  return (
    <Provider store={store}>
      <ConnectedApp routes={routes} />
    </Provider>
  );
};

//Events that will refresh the user session.
const ACTIVITY_EVENTS: EVENTS[] = [
  'mousemove',
  'keydown',
  'wheel',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove'
]

class App extends React.Component<any, any> {
  private idleTimer;
  constructor(props: any) {
    super(props);
    this.idleTimer = null;

    const initialUrl = window.location.href
    let code, st
    const { url, query } = queryString.parseUrl(window.location.href)
    if (query && query.code) {
      code = query.code
    }

    if (query && query.state) {
      st = query.state
    }
    this.state = {
      code, 
      initialUrl,
      tabFocus: false,
      showCountdownModal: false,
      countdownIntervalId: null,
      remainingSeconds: null,
    };
  }
  componentDidMount() {
    const { auth, loginRefresh, logoutUser, apFetch } = this.props;
    const { userAccount, accessToken, skipNextRefresh } = auth;

    if (auth.isAuthenticated || auth.userAccount || auth.expiry) {
      if (helper.isLoggedIn(auth, config)) {
        if (isMatomoEnabled) {
          ReactMatomo.push(['setUserId', userAccount.uuid])
          ReactMatomo.push(['setCustomDimension', config.matomoCustomDimensionGovIdUsername, userAccount.userId]);
        }
        //uuid, accessToken, skipNextRefresh, fetcher, isInitial
        loginRefresh(userAccount.uuid, accessToken, skipNextRefresh,apFetch, true);
      } else {
        logoutUser(userAccount, accessToken);
      }
    }
    document.addEventListener('keydown', this.onFocus);
    document.addEventListener('click', this.onFocus);
    if (this.state.code) {
      const { url, query } = queryString.parseUrl(this.state.initialUrl)
      this.props.loginUser(this.state.code, query.state, url)
    }
  }

  onFocus = e => {
    if (e.which === 9 && !this.state.tabFocus) {
      this.setState({ tabFocus: true });
    } else if (e.which === 1 && this.state.tabFocus) {
      this.setState({ tabFocus: false });
    }
  };

  onAction = e => {
    const { auth, loginRefresh, apFetch } = this.props;
    const { showCountdownModal } = this.state;
    if (auth.isAuthenticated && !showCountdownModal) {
      const { userAccount, accessToken, skipNextRefresh } = auth;
      loginRefresh(userAccount.uuid, accessToken, skipNextRefresh,apFetch, false);
    }
  };

  onActive = e => { };

  onIdle = e => {
    const { auth, logoutUser } = this.props;
    const timeLeft = 5 * 60000
    if (timeLeft > 0) {
      const countdownIntervalId = setInterval(this.onCountdownTick, 1000);
      this.setState({
        remainingSeconds: config.countdownTimerMs / 1000,
        showCountdownModal: true,
        countdownIntervalId,
      });
    } else {
      this.props.logoutUser(
        this.props.auth.userAccount,
        this.props.auth.accessToken
      )
      this.setState({
        remainingSeconds: null,
        showCountdownModal: false,
        countdownIntervalId: null
      });
    }
  };

  onCountdownTick = () => {
    const remainingSeconds = this.state.remainingSeconds - 1;
    if (remainingSeconds <= 0) {
      this.onCountdownLogout();
    } else {
      this.setState({ remainingSeconds });
    }
  };

  onCountdownContinue = () => {
    const { auth, loginRefresh } = this.props;
    const { userAccount, accessToken, skipNextRefresh } = auth;
    clearInterval(this.state.countdownIntervalId);
    this.props.loginRefresh(
      (userAccount || {}).uuid,
      accessToken,
      skipNextRefresh,
      this.props.apFetch,
      false
    )
    this.setState({
      remainingSeconds: null,
      showCountdownModal: false,
      countdownIntervalId: null
    })
  };

  onCountdownLogout = () => {
    clearInterval(this.state.countdownIntervalId);
    this.props.logoutUser(
      this.props.auth.userAccount,
      this.props.auth.accessToken
    )
    this.setState({
      remainingSeconds: null,
      showCountdownModal: false,
      countdownIntervalId: null
    })
  };

  // componentDidMount() {
  // 	initializeLogger();
  // 	window.myLogger.info('application Loading Complete')
  // }

  handle_focus(e) {
    if (e.which == 9 && this.state.tabFocus == false) {
      this.setState({ tabFocus: true });
    } else if (e.which == 1 && this.state.tabFocus == true) {
      this.setState({ tabFocus: false });
    } else {
      null;
    }
  }

  render() {
    const { auth, routes, isFetching } = this.props;
    const { tabFocus, showCountdownModal, remainingSeconds } = this.state;
    const isLoggedIn = helper.isLoggedIn(auth, config);
    const idleTimeout =
      (auth.timeout || config.defaultExpiryMs) - config.countdownTimerMs
    return (
      <div className={tabFocus == true ? 'tabbing' : null}>
        {isLoggedIn && !showCountdownModal && (
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            events={ACTIVITY_EVENTS}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={500}
            timeout={idleTimeout}
            stopOnIdle={true}
          />
        )}
        <Router history={history}>
          <Layout mappings={mappings}>
          </Layout>
        </Router>

        {showCountdownModal && (
          <Modal
            show={true}
            onHide={this.onCountdownContinue}
            aria-labelledby="contained-modal-title"
            bsSize="sm"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title>
                <i className="fa fa-exclamation-triangle" />
                Your Session is About to Expire!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {'Your Session will Expire in ' +
                remainingSeconds +
                ' seconds. Do you want to continue the session'}
            </Modal.Body>
            <Modal.Footer>
              <button
                id="Root_countdownContinueBtn"
                type="button"
                className="btn btn-primary"
                onClick={this.onCountdownContinue}
              >
                Continue
              </button>
              <button
                id="Root_countdownLogoutBtn"
                type="button"
                className="btn btn-warning pull-right"
                onClick={this.onCountdownLogout}
              >
                Sign Out
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth,
    userAccess: state.userAccess,
    userAccount: state.auth.userAccount,
    isFetching: state.auth.isFetching || state.userAccess.isFetching,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  //environment, tenantCode, skipRedirect
  return {
    loginRefresh: (uuid, accessToken, skipNextRefresh,apFetch, isInitial) => {
      dispatch(
        actions.loginRefresh(uuid, accessToken, skipNextRefresh,apFetch, isInitial),
      );
    },
    logoutUser: () => {
      dispatch(actions.logoutUser(config.environment, config.tenant.code, false))
    },
    loginUser: (code, state, redirectUri) => {
      dispatch(actions.loginUser(code, state, redirectUri))
    },
  };
}

const ConnectedApp = withAPFetcher(connect(mapStateToProps, mapDispatchToProps)(App));

export default Root;
